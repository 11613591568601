import React from "react";

export const CharacteristicsIconComponent = ({ icon, text, color }) => {
  return (
    <div className="d-flex align-items-center justify-content-center  container-characteristics-icon me-1">
      <div
        className="container-icon-characteristics d-flex align-items-center justify-content-center"
        style={{ backgroundColor: color }}
      >
        {icon}
      </div>
      <p className="text-characteristics ms-1 me-1">{text}</p>
    </div>
  );
};
