import React from "react";
import { Modal } from "react-bootstrap";
import "../../styles/modalBaseDerivator.css";
import { MdOutlineClose } from "react-icons/md";
import { TitleHomeWithIcon } from "../../../Home/components/TitleHomeWithIcon";
import { colors } from "../../../../styles/colors";
import ChargeCar from "../../../Home/images/charge-car.svg";
import Calendar from "../../../Home/images/calendar.svg";
import SalePrice from "../../../Home/images/sale-price.svg";

const ModalBaseDerivatorComponent = ({
  show,
  setShow,
  title,
  iconOne,
  iconTwo,
  iconThree,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  handleEvent,
}) => {
  const handleClose = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="custom-modal-derivator-base"
      fullscreen={true}
    >
      <Modal.Body className="modal-derivator-base">
        <div className="d-flex align-items-center justify-content-end">
          <span className="button-close" onClick={handleClose}>
            <MdOutlineClose className="icon-close" />
          </span>
        </div>
        <div
          style={{ height: "90%" }}
          className="d-flex align-items-center justify-content-between flex-column"
        >
          <div>
            <TitleHomeWithIcon
              title={`¡Querés ${title}!`}
              fontSize={"22px"}
              colorText={colors.purple.purple}
              colorIcon={colors.purple.purple}
            />
            <p className="subtitle-mobile">
              Seguí estos <br /> tres simples pasos:
            </p>
          </div>
          <div
            className="d-flex align-items-center justify-content-around flex-column mt-5"
            style={{ height: "100%" }}
          >
            <div className="icons-mobile-container">
              <img src={iconOne} alt="auta-button" className="icons-mobile" />
              <p className="icon-mobile-description">{descriptionOne}</p>
            </div>
            <div className="icons-mobile-container">
              <img src={iconTwo} alt="auta-button" className="icons-mobile" />
              <p className="icon-mobile-description">{descriptionTwo}</p>
            </div>
            <div className="icons-mobile-container">
              <img src={iconThree} alt="auta-button" className="icons-mobile" />
              <p className="icon-mobile-description">{descriptionThree}</p>
            </div>
            <button
              className="btn-start-modal-derivator mt-4"
              onClick={handleEvent}
            >
              Empezar
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalBaseDerivatorComponent;
