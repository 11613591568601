import React, { useEffect, useState } from "react";
import { HeaderComponent } from "../../components/HeaderComponent";
import { Container } from "react-bootstrap";
import { HeaderLikesDiscardComponent } from "../../components/HeaderLikesDiscardComponent";
import { CarsCardsComponentLikeDiscard } from "../../components/CarsCardsComponentLikeDiscard";
import { CardTypeRowComponent } from "../../components/CardTypeRowComponent";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { get } from "../../utils/apiService";
import EmptyStateComponent from "../../components/EmptyStateComponent";
import SpinnerComponent from "./../../components/SpinnerComponent";
import { useFavorites } from "../../context/FavoritesContext";

const WishListPage = () => {
  let isPageWide = useMediaQuery("(min-width: 760px)");
  const { removeFavorite } = useFavorites();
  const [expanded, setExpanded] = useState(false);
  const [isEditing, setisEditing] = useState(false);
  const [checkedLikeDiscard, setCheckedLikeDiscard] = useState(
    JSON.parse(localStorage.getItem("likeDiscardSelect")) || false
  );
  const [carsLikedList, setCarsLikedList] = useState([]);
  const [carsDisLikedList, setCarsDisLikedList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    localStorage.setItem(
      "likeDiscardSelect",
      JSON.stringify(checkedLikeDiscard)
    );
    const fetchData = async () => {
      try {
        setLoading(true);
        const resultLiked = await get("/users/get_user_likes");
        setCarsLikedList(resultLiked?.likes);
        const resultDisliked = await get("/users/get_user_dislikes");
        setCarsDisLikedList(resultDisliked?.dislike);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [checkedLikeDiscard]);

  const deleteCarFromTheList = (car_id, mark) => {
    // Actualizar el estado de la lista después de eliminar la tarjeta
    if (mark === "like") {
      setCarsLikedList((prevList) =>
        prevList.filter((car) => car.car_id !== car_id)
      );
      removeFavorite();
    } else if (mark === "dislike") {
      setCarsDisLikedList((prevList) =>
        prevList.filter((car) => car.car_id !== car_id)
      );
    }
  };

  return (
    <>
      <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
      <Container className={`${expanded ? "blur-background" : ""}`}>
        <div className="container-all">
          <HeaderLikesDiscardComponent
            type={"likes"}
            isEditing={isEditing}
            setisEditing={setisEditing}
            setCheckedLikeDiscard={setCheckedLikeDiscard}
            checkedLikeDiscard={checkedLikeDiscard}
          />
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start ">
            {loading ? (
              <Container style={{ height: "500px" }}>
                <SpinnerComponent />
              </Container>
            ) : isPageWide ? (
              <>
                {checkedLikeDiscard ? (
                  carsDisLikedList.length > 0 ? (
                    carsDisLikedList?.map((carDisLiked, index) => (
                      <CarsCardsComponentLikeDiscard
                        key={index}
                        car={carDisLiked}
                        isEditing={isEditing}
                        isDiscard={checkedLikeDiscard}
                        mark={"dislike"}
                        onDelete={() =>
                          deleteCarFromTheList(carDisLiked.car_id, "dislike")
                        }
                      />
                    ))
                  ) : (
                    <EmptyStateComponent text={"Aun no posees descartes"} />
                  )
                ) : carsLikedList.length > 0 ? (
                  carsLikedList?.map((carLiked, index) => (
                    <CarsCardsComponentLikeDiscard
                      key={index}
                      car={carLiked}
                      isEditing={isEditing}
                      isDiscard={checkedLikeDiscard}
                      mark={"like"}
                      onDelete={() =>
                        deleteCarFromTheList(carLiked.car_id, "like")
                      }
                    />
                  ))
                ) : (
                  <EmptyStateComponent text={"Aun no posees favoritos"} />
                )}
              </>
            ) : (
              <div style={{ width: "100%" }} className="mt-2">
                {checkedLikeDiscard ? (
                  carsDisLikedList.length > 0 ? (
                    carsDisLikedList?.map((carDisLiked, index) => (
                      <CardTypeRowComponent
                        key={index}
                        car={carDisLiked}
                        type={true}
                        isEditing={isEditing}
                        isDiscard={checkedLikeDiscard}
                        mark={"dislike"}
                        onDelete={() =>
                          deleteCarFromTheList(carDisLiked.car_id, "dislike")
                        }
                      />
                    ))
                  ) : (
                    <EmptyStateComponent text={"Aun no posees descartes"} />
                  )
                ) : carsLikedList.length > 0 ? (
                  carsLikedList?.map((carLiked, index) => (
                    <CardTypeRowComponent
                      key={index}
                      car={carLiked}
                      type={true}
                      isEditing={isEditing}
                      isDiscard={checkedLikeDiscard}
                      mark={"like"}
                      onDelete={() =>
                        deleteCarFromTheList(carLiked.car_id, "like")
                      }
                    />
                  ))
                ) : (
                  <EmptyStateComponent text={"Aun no posees favoritos"} />
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default WishListPage;
