import React, { useState } from "react";
import ModalMinKmsComponent from "../../../../components/ModalMinKmsComponent";
import { StepperDesktopComponent } from "./StepperDesktopComponent";
import ContactDataFormRenewalComponent from "../Form/ContactDataFormRenewalComponent";
import ContactDataFormSaleComponent from "../Form/ContactDataFormSaleComponent";

export const CotizadorDesktopComponent = ({ isRenewal }) => {
  const [currentView, setCurrentView] = useState("step2");
  const [number, setNumber] = useState("");
  const [isGnc, setIsGnc] = useState(""); // Estado para el valor seleccionado
  const [isRenewManualModalOpen, setRenewManualModalOpen] = useState(false);

  const toggleRenewModal = () => {
    setRenewManualModalOpen(!isRenewManualModalOpen);
  };

  const [selectedOptions, setSelectedOptions] = useState({
    year: null,
    brand: null,
    model: null,
    version: null,
    kms: null,
  });

  const [showModal, setShowModal] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);
  const [modalSize, setModalSize] = useState("lg");
  const [isSaleModalOpen, setSaleModalOpen] = useState(false);

  const handleStartClick = () => {
    const formattedNumber = parseInt(number.replace(/\./g, ""), 10);
    if (currentView === "step2") {
      if (!isNaN(formattedNumber) && formattedNumber <= 1000) {
        if (!isContinuing) {
          setShowModal(true);
        } else {
          setCurrentView("step3");
        }
      } else {
        setCurrentView("step3");
        setModalSize("xs");
      }
    }
  };

  return (
    <div className="container-cotizador">
      {currentView === "step2" ? (
        <StepperDesktopComponent
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          number={number}
          setNumber={setNumber}
          isGnc={isGnc}
          setIsGnc={setIsGnc}
          handleStartClick={handleStartClick}
          toggleRenewModal={toggleRenewModal}
          isRenewManualModalOpen={isRenewManualModalOpen}
        />
      ) : currentView === "step3" ? (
        <div
          className="d-flex align-items-center justify-content-start flex-column"
          style={{ height: "100%" }}
        >
          <div
            style={{
              marginTop: "40px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {isRenewal ? (
              <ContactDataFormRenewalComponent
                setSaleModalOpen={setSaleModalOpen}
                dataCar={selectedOptions}
                kms={number}
                isGnc={isGnc}
              />
            ) : (
              <ContactDataFormSaleComponent
                setSaleModalOpen={setSaleModalOpen}
                dataCar={selectedOptions}
                kms={number}
                isGnc={isGnc}
              />
            )}
          </div>
        </div>
      ) : null}
      {showModal && (
        <ModalMinKmsComponent
          show={showModal}
          kms={number}
          setShow={setShowModal}
          setIsContinuing={setIsContinuing}
          setCurrentView={setCurrentView}
        />
      )}
    </div>
  );
};
