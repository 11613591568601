import React, { useEffect, useState } from "react";
import { HeaderComponent } from "../../components/HeaderComponent";
import { FooterComponent } from "../../components/FooterComponent";
import { CardComponent } from "./components/CardComponent";
import { AboutUsComponent } from "./components/AboutUsComponent";
import { AdvantagesComponent } from "./components/AdvantagesComponent";
import { ClientExperiencesComponent } from "./components/ClientExperiencesComponent";
import { LocationComponent } from "./components/LocationComponent";
import { SocialMediaComponent } from "./components/SocialMediaComponent";
import VectorFooter from "../Home/images/vector-footer.png";
import VectorFooterMobile from "../Home/images/vector-footer-mobile.png";
import CarMobileOneAdvantages from "../Home/images/car-advantages-one.png";
import CarMobileTwoAdvantages from "../Home/images/car-advantages-two.png";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { WhatsAppButtonComponent } from "./components/WhatsAppButtonComponent";
import { useSpinner } from "../../context/spinnerContext";
import SpinnerComponent from "./../../components/SpinnerComponent";

export const HomePage = () => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");
  const [expanded, setExpanded] = useState(false);
  const { showLoading, hideLoading, showSpinner } = useSpinner();
  useEffect(() => {
    showLoading();
    setTimeout(() => {
      hideLoading();
    }, 1000);
  }, []);

  return (
    <>
      {showSpinner ? (
        <SpinnerComponent />
      ) : (
        <>
          <div className="d-flex flex-column justify-content-between">
            <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
            <div className={`${expanded ? "blur-background" : ""}`}>
              <CardComponent isPageWide={isPageWide} />
              <AboutUsComponent isPageWide={isPageWide} />
              {!isPageWide ? (
                <img
                  style={{ marginTop: "50px" }}
                  src={CarMobileOneAdvantages}
                  alt="car-auta"
                  className="car-auta-advantages"
                />
              ) : null}
              <AdvantagesComponent />
              {!isPageWide ? (
                <img
                  style={{ marginTop: "20px" }}
                  src={CarMobileTwoAdvantages}
                  alt="car-auta"
                  className="car-auta-advantages-two"
                />
              ) : null}
              <div className="container-clientExperiences-location-socialMedia">
                <ClientExperiencesComponent />
                <LocationComponent />
                <SocialMediaComponent isPageWide={isPageWide} />
              </div>
              <img
                src={isPageWide ? VectorFooter : VectorFooterMobile}
                alt="vector-footer"
                style={{ width: "100%", marginTop: "-50px" }}
              />
              <FooterComponent />
            </div>
          </div>
          <WhatsAppButtonComponent />
        </>
      )}
    </>
  );
};
