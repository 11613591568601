import React, { useState } from "react";
import { CharacteristicsIconComponent } from "./CharacteristicsIconComponent";
import { TbHeartFilled } from "react-icons/tb";
import { BsCheckLg } from "react-icons/bs";
import { colors } from "../../../styles/colors";
import NoLike from "../images/cross-button.svg";
import Like from "../images/like-button.svg";
import Whatsapp from "../images/whatsapp-button.svg";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { CardTypeRowComponent } from "../../../components/CardTypeRowComponent";
import { PriceTypeMoneyComponent } from "../../../components/PriceTypeMoneyComponent";
import SessionAlertModalComponent from "../../../components/SessionAlertModalComponent";
import { useAuth } from "../../../context/AuthContext";
import { useFavorites } from "../../../context/FavoritesContext";
import { post } from "../../../utils/apiService";

const Card = ({ onDelete, car, typeLayout }) => {
  const [isFadeOut, setIsFadeOut] = useState(false);
  const handleDelete = () => {
    // setIsFadeOut(true);
    // setTimeout(() => {
    //   // onDelete();
    // }, 250);
  };

  document.querySelectorAll(".delete-btn").forEach((btn) => {
    btn.addEventListener("click", () => {
      btn.parentElement.classList.add("fade-out");
    });
  });

  const navigate = useNavigate();
  let location = useLocation();
  let isPageWide = useMediaQuery("(min-width: 768px)");
  const { isAuthenticated } = useAuth();
  const { addFavorite } = useFavorites();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigateTechnicalData = (e) => {
    if (!e.target.closest(".container-buttons-card-cars")) {
      // Realiza la navegación solo si el clic no proviene de un botón
      navigate(`/car/${car?.car_id}`, {
        state: { from: location.pathname, typeLayout: typeLayout },
      });
    }
  };

  const updateCar = async (mark_as, car_id) => {
    try {
      const result = await post("/cars/mark_car", { mark_as, car_id });
      console.log("Solicitud POST exitosa:", result);
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error.message);
    }
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      {!typeLayout || isPageWide ? (
        <div
          className={` card-cars ${isFadeOut ? "fade-out" : ""} ms-md-4 mb-4`}
          onClick={navigateTechnicalData}
        >
          <div className="background-card-cars">
            <div className="d-flex align-items-center justify-content-start">
              <CharacteristicsIconComponent
                icon={
                  <TbHeartFilled color={colors.purple.purple} fontSize="8px" />
                }
                text={"Muy Likeado"}
                color={colors.white.white}
              />
              <CharacteristicsIconComponent
                icon={<BsCheckLg color={colors.white.white} fontSize="8px" />}
                text={"Bluetooh"}
                color={colors.purple.purple}
              />
              <CharacteristicsIconComponent
                icon={<BsCheckLg color={colors.white.white} fontSize="8px" />}
                text={"Apple Carplay"}
                color={colors.purple.purple}
              />
              <div
                className="container-characteristics-icon d-flex align-items-center justify-content-center"
                style={{ width: "34px", height: "16px" }}
              >
                <p className="text-number-characteristics">+3</p>
              </div>
            </div>
            <div className="divider-card-cars mt-2 mb-3"></div>
            <p className="text-brand-model mb-1">
              {car?.brand} {car?.model}
            </p>
            <p className="text-version">{car?.version}</p>
            <div className="d-flex">
              <p className="text-year-km">{car?.year}</p>
              <p className="ms-1 me-1 text-year-km">|</p>
              <p className="text-year-km mb-3  mb-md-0">{car?.kms}kms</p>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={car?.photos[0]}
                alt="car-catalog"
                className="car-catalog mt-3 mb-3 me-2"
              />
            </div>
            <div className="d-flex align-items-center justify-content-center mb-4 mt-4 mb-md-0 mt-md-0">
              <div className="d-flex align-items-center justify-content-around container-buttons-card-cars">
                <button
                  className={!isAuthenticated ? "btn-card-car" : "delete-btn"}
                  onClick={!isAuthenticated ? null : handleDelete}
                >
                  <img
                    src={NoLike}
                    alt="no-like-button"
                    className="buttons-card-cars"
                    onClick={(e) => {
                      if (!isAuthenticated) {
                        setIsModalOpen(true);
                      } else {
                        updateCar("dislike", car.car_id);
                        onDelete();
                      }
                    }}
                  />
                </button>
                <img
                  src={Whatsapp}
                  alt="whatsapp-button"
                  className="buttons-card-cars"
                  onClick={(e) => {
                    updateCar("consulted", car.car_id);
                  }}
                />
                <button
                  className={!isAuthenticated ? "btn-card-car" : "delete-btn"}
                  onClick={!isAuthenticated ? null : handleDelete}
                >
                  <img
                    src={Like}
                    alt="like-button"
                    className="buttons-card-cars"
                    onClick={(e) => {
                      if (!isAuthenticated) {
                        setIsModalOpen(true);
                        console.log("no log");
                      } else {
                        console.log("si log");
                        addFavorite();
                        updateCar("like", car.car_id);
                        onDelete();
                      }
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="mt-md-3">
            <PriceTypeMoneyComponent car={car} />
          </div>
        </div>
      ) : (
        <CardTypeRowComponent
          navigateTechnicalData={navigateTechnicalData}
          car={car}
          onDelete={() => onDelete(car?.car_id, "dislike")}
        />
      )}
      <SessionAlertModalComponent isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

const CarsCardsComponent = ({ car, typeLayout, setCarsList }) => {
  const deleteCarFromTheList = (car_id) => {
    // Actualizar el estado de la lista después de eliminar la tarjeta
    setCarsList((prevList) => prevList.filter((car) => car.car_id !== car_id));
  };

  return (
    <>
      {car?.map((car, index) => (
        <Card
          onDelete={() => deleteCarFromTheList(car?.car_id)}
          car={car}
          typeLayout={typeLayout}
        />
      ))}
    </>
  );
};

export default CarsCardsComponent;
