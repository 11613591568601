import React, { createContext, useState, useContext } from 'react';

const CurrencyContext = createContext();

export const CurrencyProvider = ({ children }) => {
  const [isDollars, setIsDollars] = useState(false);

  const toggleCurrency = () => {
    setIsDollars((prevValue) => !prevValue);
  };

  return (
    <CurrencyContext.Provider value={{ isDollars, toggleCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};