import React from "react";
import SalePrice from "../../modules/Home/images/sale-price.svg";
import { TitleHomeWithIcon } from "../../modules/Home/components/TitleHomeWithIcon";
import { colors } from "../../styles/colors";

const SuccessComponent = ({ handleClick, isMobile, isRenewal }) => {
  return (
    <div
      style={{
        height: isMobile ? "" : "420px",
      }}
      className="d-flex align-items-center justify-content-between flex-column"
    >
      {isMobile ? (
        <TitleHomeWithIcon
          title={"Tu cotización fue\n realizada con éxito."}
          fontSize={"28px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      ) : null}
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img
          src={SalePrice}
          alt="auta-button"
          className="card-icon-cotizacion"
        />
        <div
          className="container-cotizacion-price d-flex align-items-center justify-content-center mt-3"
          style={{ width: isMobile ? "100%" : "" }}
        >
          <div className="d-flex align-items-center justify-content-center">
            <p className="type-money-cotizacion mt-2 me-1">U$S</p>
            <p className="price-cotizacion">$22.230</p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center mt-3">
          <p className="text-description-cotizacion">
            {isRenewal
              ? "Saldo sujeto a inspeccion"
              : "Oferta sujeta a una inspección física y gratuita."}
          </p>
          <p className="text-description-cotizacion">
            {isRenewal
              ? "Este saldo lo tenes a favor por cotizar en Auta"
              : "En instantes recibirás la oferta por WhatsApp para continuar el proceso con un asesor."}
          </p>
        </div>
        {isRenewal ? (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <p className="text-footer-cotizacion">
              Antes de continuar con la renovación <br /> ¿Te interesa agendar
              la inspección de tu auto?
            </p>
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-4">
            <p className="text-footer-cotizacion">
              ¿Te interesa la oferta? Agenda tu inspección{" "}
              <a
                href="https://turnos-auta-dot-admin-dev-355313.rj.r.appspot.com/turnos-auta/q9gtiYppNulewWob6KDR"
                target="_blank"
              >
                aca
              </a>
            </p>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-center mt-5">
        {isRenewal ? (
          <>
            <button className="purple-button-inspect me-2">
              <a
                href="https://turnos-auta-dot-admin-dev-355313.rj.r.appspot.com/turnos-auta/q9gtiYppNulewWob6KDR"
                target="_blank"
                style={{ textDecoration: "none", color: "white" }}
              >
                Agendar Inspección
              </a>
            </button>
            <button className="white-button-inspect" onClick={handleClick}>
              Continuar
            </button>
          </>
        ) : (
          <button className="white-button-inspect" onClick={handleClick}>
            Volver al inicio
          </button>
        )}
      </div>
    </div>
  );
};

export default SuccessComponent;
