import React from "react";
import { PeriodsTopComponent } from "./PeriodsTopComponent";
import { PeriodsBottomComponent } from "./PeriodsBottomComponent";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { PeriodTimeLineVerticalComponent } from "./PeriodTimeLineVerticalComponent";
import { TimeLineLeftComponent } from "./TimeLineLeftComponent";
import { TimeLineRightComponent } from "./TimeLineRightComponent";

export const TimeLineComponentProcessSale = () => {
  let isPageWide = useMediaQuery("(min-width: 768px)");

  const lineCompleted = {
    backgroundColor: colors.purple.purple,
    height: "6px",
    width: "100%",
  };
  const lineIncompleted = {
    backgroundColor: colors.gray.lightGrey,
    height: "6px",
    width: "100%",
  };

  return (
    <>
      {isPageWide ? (
        <div className="d-flex align-items-center justify-content-between timeline">
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent
              isFirst={true}
              textOne={"Cotización de"}
              textTwo={"tu usado"}
              completed={true}
              width={true}
            />
          </div>
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsBottomComponent
              textOne={"Agendar una"}
              textTwo={"inspección"}
              completed={true}
              width={true}
            />
          </div>
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent
              textOne={"Asistir a la"}
              textTwo={"inspección"}
              completed={true}
            />
          </div>
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsBottomComponent textOne={"Oferta Final"} completed={true} />
          </div>
          <div
            style={lineIncompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent
              textOne={"Agendar"}
              textTwo={"pretoma"}
              completed={false}
            />
          </div>
          <div
            style={lineIncompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsBottomComponent
              textOne={"Asistencia a"}
              textTwo={"pretoma"}
              completed={false}
            />
          </div>
          <div
            style={lineIncompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent
              isLast={true}
              textOne={"Documentación"}
              textTwo={"de Toma"}
              completed={false}
            />
          </div>
          <div
            style={lineIncompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsBottomComponent textOne={"Pago"} completed={false} />
          </div>
          <div
            style={lineIncompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent
              isLast={true}
              textOne={"Entrega del"}
              textTwo={"vehiculo"}
              completed={false}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column mt-4">
          <TimeLineRightComponent>
            <PeriodTimeLineVerticalComponent
              isRight={true}
              textOne={"Cotización de"}
              textTwo={"tu usado"}
              completed={true}
            />
          </TimeLineRightComponent>
          <TimeLineLeftComponent>
            <PeriodTimeLineVerticalComponent
              isRight={false}
              textOne={"Agendar una"}
              textTwo={"inspección"}
              completed={true}
            />
          </TimeLineLeftComponent>
          <TimeLineRightComponent>
            <PeriodTimeLineVerticalComponent
              isRight={true}
              textOne={"Asistir a la"}
              textTwo={"inspección"}
              completed={true}
            />
          </TimeLineRightComponent>
          <TimeLineLeftComponent>
            <PeriodTimeLineVerticalComponent
              isRight={false}
              textOne={"Oferta Final"}
              completed={true}
            />
          </TimeLineLeftComponent>
          <TimeLineRightComponent incompleted={true}>
            <PeriodTimeLineVerticalComponent
              isRight={true}
              textOne={"Agendar"}
              textTwo={"pretoma"}
              completed={false}
            />
          </TimeLineRightComponent>
          <TimeLineLeftComponent incompleted={true}>
            <PeriodTimeLineVerticalComponent
              isRight={false}
              textOne={"Asistencia a"}
              textTwo={"pretoma"}
            />
          </TimeLineLeftComponent>
          <TimeLineRightComponent incompleted={true}>
            <PeriodTimeLineVerticalComponent
              isRight={true}
              textOne={"Documentación"}
              textTwo={"de Toma"}
            />
          </TimeLineRightComponent>
          <TimeLineLeftComponent incompleted={true}>
            <PeriodTimeLineVerticalComponent
              isRight={false}
              textOne={"Entrega del"}
              textTwo={"vehiculo"}
            />
          </TimeLineLeftComponent>
        </div>
      )}
    </>
  );
};
