import React, { createContext, useState, useContext } from "react";

const QuoteContext = createContext();

export const QuoteProvider = ({ children }) => {
  const [quoteRenewal, setQuoteRenewal] = useState("");

  const addQuote = () => {
    setQuoteRenewal("$34.000");
  };

  return (
    <QuoteContext.Provider value={{ addQuote, quoteRenewal }}>
      {children}
    </QuoteContext.Provider>
  );
};

export const useQuote = () => {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error("useQuote must be used within a QuoteProvider");
  }
  return context;
};
