import React, { useEffect, useState } from "react";
import { colors } from "./../../../styles/colors";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { Col, Row } from "react-bootstrap";
import TellUs from "../images/tell-us.svg";
import ChooseCar from "../images/choose-car.svg";
import Messages from "../images/messages.svg";
import "../style/buyFilter.css";
import BaseWhiteButtonComponent from "../../../components/BaseWhiteButtonComponent";
import BasePurpleButtonComponent from "../../../components/BasePurpleButtonComponent";
import { useNavigate } from "react-router-dom";
import BottonGridTypeCarComponent from "../../../components/BottonGridTypeCarComponent";

export default function BuyComponent({ setModalSize }) {
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState("step1");

  const handleStartClick = () => {
    if (currentView === "step1") {
      setCurrentView("step2");
      setModalSize("xs");
    }
  };
  const [selectedButton, setSelectedButton] = useState(1);

  useEffect(() => {
    handleButtonClick(selectedButton);
  }, [selectedButton]);

  const handleButtonClick = (buttonNumber) => {
    setSelectedButton(buttonNumber);
  };

  const goTocatalog = () => {
    navigate("/cars");
  };
  return (
    <>
      {currentView === "step1" ? (
        <div className="mt-4">
          <TitleHomeWithIcon
            title={"¡Querés Comprar!"}
            fontSize={"28px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
            isModal={true}
          />
          <p className="subtitle-pop-up mt-2">
            Seguí estos tres simples pasos:
          </p>
          <Row className="mt-5">
            <Col md={4} className="img-renewl-container">
              <img src={TellUs} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Contanos qué auto buscás.</p>
            </Col>
            <Col md={4} className="img-renewl-container">
              <img src={ChooseCar} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Elegí entre las opciones.</p>
            </Col>
            <Col md={4} className="img-renewl-container">
              <img src={Messages} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Consultá con un Asesor Auta.</p>
            </Col>
          </Row>
          <div className="button-container">
            <BaseWhiteButtonComponent
              buttonText={"Empezar"}
              onClick={handleStartClick}
            />
          </div>
        </div>
      ) : (
        <>
          <TitleHomeWithIcon
            title={<p className="mt-2">¿Estás buscando algo en especial?</p>}
            fontSize={"26px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
          />

          <div
            className="card-contact-data mt-4"
            style={{
              height: "70%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="card-contact-header"
              style={{
                paddingLeft: "28px",
                paddingRight: "28px",
              }}
            >
              <p className="contact-header-title">
                Filtrá y encontrá tu próximo auto.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                padding: "10px 10px 0 10px",
                flexWrap: "wrap",
                height: "100%",
              }}
            >
              <div style={{}}>
                <p className="input-filter-text mt-2">
                  ¿Entre qué años buscás?
                </p>
                <div className="d-flex align-items-center mb-4">
                  <input
                    type="text"
                    className="base-input"
                    placeholder="Ingresar año"
                  />
                  <span className="divider-range" />
                  <input
                    type="text"
                    className="base-input"
                    placeholder="Ingresar año"
                  />
                </div>
                <p className="input-filter-text">¿Qué tipo de auto buscás?</p>
                <div className="mb-4 ">
                  <BottonGridTypeCarComponent />
                </div>
                <p className="input-filter-text">¿Entre qué rango de precio?</p>
                <div className="d-flex align-items-center mb-2">
                  <input
                    type="text"
                    className="base-input"
                    placeholder="ingresar monto"
                  />
                  <span className="divider-range" />
                  <input
                    type="text"
                    className="base-input"
                    placeholder="ingresar monto"
                  />
                </div>
              </div>
              <div
                style={{ width: "100%" }}
                className="d-flex align-items-end justify-content-between"
              >
                <button className="btn-continue-filter" onClick={goTocatalog}>
                  Continuar sin filtrar
                </button>
                <BasePurpleButtonComponent
                  buttonText={"Filtrar"}
                  onClick={goTocatalog}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
