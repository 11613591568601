import React from "react";
import { Modal } from "react-bootstrap";
import IconClose from "../images/icon-close-button.svg";

const CarModalComponent = ({ show, setShowModal, imageGallery }) => {
  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl" centered>
      <div className="d-flex align-items-center justify-content-end">
        <img
          src={IconClose}
          alt="icon-close"
          style={{ marginRight: "-80px", cursor: "pointer" }}
          onClick={handleClose}
        />
      </div>
      <Modal.Body>{imageGallery}</Modal.Body>
    </Modal>
  );
};

export default CarModalComponent;
