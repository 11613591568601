import React, { useState } from "react";
import Sedan from "../../../images/Sedán.png";
import Rural from "../../../images/Rural.png";
import Suv from "../../../images/SUV.png";
import Hatchback from "../../../images/Hatchback.png";
import PickUp from "../../../images/Pick-Up.png";
import Monovolumen from "../../../images/Monovolumen.png";
import Furgon from "../../../images/Furgón.png";
import Coupe from "../../../images/Coupé.png";
import Minivan from "../../../images/Minivan.png";
import { Col, Row } from "react-bootstrap";

const GridTypeCarCatalogComponent = ({ setSelectedCar, selectedCar }) => {
  const buttons = [
    { id: 1, text: "Sedan", imageUrl: Sedan },
    { id: 2, text: "Monovolumen", imageUrl: Monovolumen },
    { id: 3, text: "SUV", imageUrl: Suv },
    { id: 4, text: "Hatchback", imageUrl: Hatchback },
    { id: 5, text: "Pick-Up", imageUrl: PickUp },
    { id: 6, text: "Furgon", imageUrl: Furgon },
    { id: 7, text: "Coupé", imageUrl: Coupe },
    { id: 8, text: "Minivan", imageUrl: Minivan },
    { id: 9, text: "Rural", imageUrl: Rural },
  ];

  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedButtonText, setSelectedButtonText] = useState(
    "Seleccioná el tipo de vehículo"
  );

  const handleButtonClick = (car) => {
    setSelectedButton(car.id);
    setSelectedButtonText(car.text);

    //Para no almacenar el mismo car
    const compareCar = selectedCar.find((objeto) => objeto.id === car.id);
    if (!compareCar) {
      setSelectedCar((prevState) => [...prevState, car]);
    }
  };

  return (
    <>
      <Row>
        {buttons.map((car, index) => (
          <Col key={index} xs={4}>
            <CustomButton
              key={car.id}
              text={car.text}
              imageUrl={car.imageUrl}
              isSelected={selectedButton === car.id}
              onClick={(car) => handleButtonClick(car)}
              car={car}
              selectedCar={selectedCar}
            />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GridTypeCarCatalogComponent;

const CustomButton = ({ text, imageUrl, isSelected, onClick, car }) => {
  return (
    <button
      className={`button-grid-type ${isSelected ? "selected" : ""}`}
      onClick={() => onClick(car)}
    >
      <img src={imageUrl} alt={text} className="button-image" />
      <div
        className={`button-text ${text === "Monovolumen" ? "small-font" : ""}`}
      >
        {text}
      </div>
    </button>
  );
};
