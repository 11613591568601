import React from "react";
import { PeriodsTopComponent } from "./PeriodsTopComponent";
import { PeriodsBottomComponent } from "./PeriodsBottomComponent";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { TimeLineRightComponent } from "./TimeLineRightComponent";
import { TimeLineLeftComponent } from "./TimeLineLeftComponent";
import { PeriodTimeLineVerticalComponent } from "./PeriodTimeLineVerticalComponent";

export const TimeLineBuyComponent = () => {
  let isPageWide = useMediaQuery("(min-width: 768px)");
  const lineCompleted = {
    backgroundColor: colors.purple.purple,
    height: "6px",
    width: "100%",
  };
  const lineIncompleted = {
    backgroundColor: colors.gray.lightGrey,
    height: "6px",
    width: "100%",
  };

  return (
    <>
      {isPageWide ? (
        <div className="d-flex align-items-center justify-content-between timeline">
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent
              isFirst={true}
              textOne={"Busca tu auto"}
              textTwo={"en el catálogo"}
              completed={true}
              width={true}
            />
          </div>
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsBottomComponent
              textOne={"Agendar"}
              textTwo={"visita/reserva"}
              completed={true}
              width={true}
            />
          </div>
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent
              textOne={"Asistencia a la"}
              textTwo={"visita/reserva"}
              completed={true}
            />
          </div>
          <div
            style={lineCompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsBottomComponent
              textOne={"Documentación"}
              textTwo={"de Toma"}
              completed={true}
            />
          </div>
          <div
            style={lineIncompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsTopComponent textOne={"Pago"} completed={false} />
          </div>
          <div
            style={lineIncompleted}
            className="d-flex align-items-center justify-content-between"
          >
            <PeriodsBottomComponent
              textOne={"Entrega del"}
              textTwo={"vehiculo"}
              completed={false}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column mt-4">
          <TimeLineRightComponent>
            <PeriodTimeLineVerticalComponent
              isRight={true}
              textOne={"Busca tu auto"}
              textTwo={"en el catálogo"}
              completed={true}
            />
          </TimeLineRightComponent>
          <TimeLineLeftComponent>
            <PeriodTimeLineVerticalComponent
              isRight={false}
              textOne={"Agendar"}
              textTwo={"visita/reserva"}
              completed={true}
            />
          </TimeLineLeftComponent>
          <TimeLineRightComponent>
            <PeriodTimeLineVerticalComponent
              isRight={true}
              textOne={"Asistencia a la"}
              textTwo={"visita/reserva"}
              completed={true}
            />
          </TimeLineRightComponent>
          <TimeLineLeftComponent incompleted={true}>
            <PeriodTimeLineVerticalComponent
              isRight={false}
              textOne={"Pago"}
              completed={false}
            />
          </TimeLineLeftComponent>
          <TimeLineRightComponent incompleted={true}>
            <PeriodTimeLineVerticalComponent
              isRight={true}
              textOne={"Entrega del"}
              textTwo={"vehiculo"}
              completed={false}
            />
          </TimeLineRightComponent>
        </div>
      )}
    </>
  );
};
