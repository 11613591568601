import React, { useState } from "react";
import { HeaderComponent } from "../../components/HeaderComponent";
import "../Profile/styles/style.css";
import { MenuProfileComponent } from "./components/MenuProfileComponent";
import { UserInformationComponent } from "./components/UserInformationComponent";
import { AppointmentsProfileComponent } from "./components/AppointmentsProfileComponent";
import { QuotesProfileComponent } from "./components/QuotesProfileComponent";
import { ProcessProfileComponent } from "./components/ProcessProfileComponent";

export const ProfilePage = () => {
  const [expanded, setExpanded] = useState(false);
  const [showInformationUser, setShowInformationUser] = useState(true);
  const [showAppointmentsUser, setShowAppointmentsUser] = useState(false);
  const [showQuotesUser, setShowQuotesUser] = useState(false);
  const [showProcessUser, setShowProcessUser] = useState(false);

  return (
    <div style={{ height: "100%" }}>
      <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
      <div
        className={`container-profile mt-md-4 ${
          expanded ? "blur-background" : ""
        }`}
      >
        <MenuProfileComponent
          setShowInformationUser={setShowInformationUser}
          setShowAppointmentsUser={setShowAppointmentsUser}
          setShowQuotesUser={setShowQuotesUser}
          setShowProcessUser={setShowProcessUser}
        />
        {showInformationUser ? <UserInformationComponent /> : null}
        {showAppointmentsUser ? <AppointmentsProfileComponent /> : null}
        {showQuotesUser ? <QuotesProfileComponent /> : null}
        {showProcessUser ? <ProcessProfileComponent /> : null}
      </div>
    </div>
  );
};
