import React, { useState } from "react";
import { HeaderComponent } from "../components/HeaderComponent";
import "../styles/components/pageNotFound.css";
import { LuSearchX } from "react-icons/lu";

export const NotFoundPage = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <div className="d-flex align-items-center justify-content-start flex-column container-page-not-found">
        <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
        <div className="d-flex align-items-center justify-content-center container-car-page-not-found flex-column mb-5">
          <LuSearchX fontSize={150} className="icon-search" />
          <p className="text-page-not-found">
            La página a la que <br /> quieres acceder no existe
          </p>
        </div>
      </div>
    </>
  );
};
