import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../styles/components/headerCatalogLikes.css";
import { useState } from "react";
import IconFilter from "../modules/CarCatalog/images/icon-filter.svg";
import ArrowDownIcon from "../modules/CarCatalog/images/arrow-down-accordion.svg";
import SwitchTypeGridComponent from "../modules/CarCatalog/components/SwitchTypeGridComponent";
import { ModalFilterComponent } from "../modules/CarCatalog/components/ModalFilterComponent";
import IconEdit from "../images/icon-edit.svg";
import IconClose from "../images/icon-close.svg";
import { SwitchLikeDiscardComponent } from "./SwitchLikeDiscardComponent";
import { useQuote } from "../context/QuoteContext";

export const HeaderLikesDiscardComponent = ({
  type,
  isEditing,
  setisEditing,
  checked,
  setChecked,
  setCheckedLikeDiscard,
  checkedLikeDiscard,
  totalCars,
}) => {
  const arrayOrderBy = [
    "Relevancia",
    "Menor precio",
    "Mayor precio",
    "Más antiguos",
    "Más recientes",
    "Menos km",
  ];

  const [show, setShow] = useState(false);
  const { quoteRenewal } = useQuote();
  const [showOrderBy, setshowOrderBy] = useState(false);

  const handleShow = () => setShow(true);

  const handleClickShowOrderBy = () => {
    setshowOrderBy(!showOrderBy);
  };

  const handleEdit = () => {
    setisEditing(!isEditing);
  };

  const handleChange = (newChecked) => {
    localStorage.setItem("checkedLikeDiscard", JSON.stringify(newChecked));
    setCheckedLikeDiscard(newChecked);
  };

  return (
    <>
      <Row className="mt-3 mt-md-4">
        <Col className="d-flex align-items-end justify-content-between justify-content-md-center">
          <p className="title-header-catalog-likes d-none d-md-block">
            {type === "catalog" && quoteRenewal !== ""
              ? `¿Que esperas a elegir tu próximo auto? Tenes a favor ${quoteRenewal}`
              : type === "catalog"
              ? "¡Comenzá a elegir entre miles de autos!"
              : type === "likes" && !checkedLikeDiscard
              ? "¡Elegi entre tus favoritos!"
              : "¡Revisá tus descartes!"}
          </p>
          {type === "catalog" ? (
            <>
              <div className="d-md-none mb-2">
                <SwitchTypeGridComponent
                  setChecked={setChecked}
                  checked={checked}
                />
              </div>
              <div className="d-flex align-items-center mb-2">
                <div
                  className="filterMobile d-flex align-items-center mt-1  d-md-none"
                  onClick={handleShow}
                >
                  <p className="textFilterMobile ms-2 me-2">Filtrar por</p>
                  <div className="containerIconoFilter d-flex align-items-center justify-content-center">
                    <img src={IconFilter} alt="icon-filter" />
                  </div>
                </div>
              </div>
            </>
          ) : null}
          <ModalFilterComponent show={show} setShow={setShow} />
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6}>
          {type === "catalog" ? (
            <p className="text-results">{totalCars} resultados</p>
          ) : (
            <>
              <SwitchLikeDiscardComponent
                setCheckedLikeDiscard={setCheckedLikeDiscard}
                checked={checkedLikeDiscard}
                handleChange={handleChange}
              />
            </>
          )}
        </Col>
        <Col
          xs={6}
          sm={6}
          className="d-flex align-items-end justify-content-end"
        >
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-center">
              <div
                className="d-flex align-items-center"
                onClick={handleClickShowOrderBy}
                style={{ cursor: "pointer" }}
              >
                {type === "catalog" ? (
                  <>
                    <p className="text-order-by d-none d-md-block">
                      Ordenar por
                    </p>
                    <img
                      src={ArrowDownIcon}
                      alt="arrow-icon-down"
                      className="arrow-icon-down  d-none d-md-block"
                    />
                  </>
                ) : (
                  <>
                    <p className="text-order-by ">Ordenar por</p>
                    <img
                      src={ArrowDownIcon}
                      alt="arrow-icon-down"
                      className="arrow-icon-down"
                    />
                  </>
                )}
              </div>
              {type === "catalog" ? null : (
                <div style={{ cursor: "pointer" }}>
                  {isEditing ? (
                    <img
                      src={IconClose}
                      alt="icon-edit"
                      className="ms-2"
                      onClick={handleEdit}
                    />
                  ) : (
                    <img
                      src={IconEdit}
                      alt="icon-edit"
                      className="ms-2"
                      onClick={handleEdit}
                    />
                  )}
                </div>
              )}
            </div>
            {showOrderBy ? (
              <div className="container-desplegable-orderBy d-flex align-items-center justify-content-center flex-column">
                {arrayOrderBy.map((data, index) => (
                  <div key={index} style={{ width: "100%" }}>
                    <p className="text-option-desplegable-orderBy mt-1 mb-1">
                      {data}
                    </p>
                    <div
                      className="divider-desplegable-orderBy"
                      style={{ width: "100%" }}
                    ></div>
                  </div>
                ))}
                <p className="text-option-desplegable-orderBy mt-1 mb-1">
                  Más km
                </p>
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Container>
          <div className="divider-header-catalog-likes mt-2 mt-md-2"></div>
        </Container>
      </Row>
    </>
  );
};
