import React from "react";
import { CardProfileComponent } from "./CardProfileComponent";
import { CardMyQuotesComponent } from "./CardMyQuotesComponent";

export const QuotesProfileComponent = () => {
  return (
    <>
      <CardProfileComponent>
        <p className="title-card-profile mb-2">Mis cotizaciones</p>
        <CardMyQuotesComponent />
        <CardMyQuotesComponent />
      </CardProfileComponent>
    </>
  );
};
