import React from "react";
import "../styles/components/baseDropDownItems.css";

export default function BaseDropDownItemsComponent({
  items,
  onItemClick,
  selectedItem,
  isYear,
  setSelectedOptions,
  dataSelectedOptions,
  type,
  // isMobile,
}) {
  const handleItemClick = (item) => {
    onItemClick(item.name, item.id);
    if (type === "year") {
      setSelectedOptions({
        year: {
          name: item.name,
          id: item.id,
        },
        brand: null,
        model: null,
        version: null,
        kms: null,
      });
    } else if (type === "brand") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions.year.name,
          id: dataSelectedOptions.year.id,
        },
        brand: {
          name: item.name,
          id: item.id,
        },
        model: null,
        version: null,
        kms: null,
      });
    } else if (type === "model") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions?.year?.name,
          id: dataSelectedOptions?.year?.id,
        },
        brand: {
          name: dataSelectedOptions?.brand?.name,
          id: dataSelectedOptions?.brand?.id,
        },
        model: {
          name: item.name,
          id: item.id,
        },
        version: null,
        kms: null,
      });
    } else if (type === "version") {
      setSelectedOptions({
        year: {
          name: dataSelectedOptions?.year?.name,
          id: dataSelectedOptions?.year?.id,
        },
        brand: {
          name: dataSelectedOptions?.brand?.name,
          id: dataSelectedOptions?.brand?.id,
        },
        model: {
          name: dataSelectedOptions?.model?.name,
          id: dataSelectedOptions?.model?.id,
        },
        version: {
          name: item.name,
          id: item.id,
        },
        kms: null,
      });
    }
  };

  return (
    <div
      className="dropdown-item-container"
      style={{
        height: isYear ? "100%" : "310px",
        marginTop: isYear ? "10px" : "",
      }}
    >
      {items?.map((item, index) => (
        <div key={index} className="container-text-animated">
          {item.name.length > 18 ? (
            <div id="animated-text-strip" onClick={() => handleItemClick(item)}>
              <div className="text-animated">
                &nbsp; &nbsp; &nbsp; &nbsp;{item.name}&nbsp;
              </div>
              <div className="text-animated">
                &nbsp; &nbsp; &nbsp; &nbsp;{item.name}&nbsp;
              </div>
              <div className="text-animated">
                &nbsp; &nbsp; &nbsp; &nbsp;{item.name}&nbsp;
              </div>
            </div>
          ) : (
            <div
              className="text-animated"
              onClick={() => handleItemClick(item)}
            >
              {item.name}
            </div>
          )}
          <div className="dropdown-item-divider" />
        </div>
      ))}
    </div>
  );
}
