import React from "react";
import { Modal } from "react-bootstrap";
import { FiltersComponent } from "./FiltersComponent";

export const ModalFilterComponent = ({ show, setShow, isSettings }) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        dialogClassName="modal-width"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Body style={{ backgroundColor: "white" }}>
          <FiltersComponent isSettings={isSettings} />
        </Modal.Body>
      </Modal>
    </>
  );
};
