// AuthContext.js
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import { auth } from '../firebase/firebase';
import { get } from '../utils/apiService'; // Importa la función renewToken
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthLoaded, setIsAuthLoaded] = useState(true);
  const [token, setToken] = useState(localStorage.getItem('accessToken') || ""); // Obtener token del localStorage
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || ""); // Obtener refreshToken del localStorage
  const [user, setUser] = useState({
    phone: "",
    name: "",
    email: ""
  });

  useEffect(() => {
    const apiUrl = 'https://backend-web-dot-admin-dev-355313.rj.r.appspot.com/';
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        if (currentUser) {
          // Realizar la solicitud POST
          axios.post(`${apiUrl}tokens/get_token?firebase_token=${currentUser?.accessToken}`)
            .then(response => {
              const accessToken = response?.data?.access_token;
              const refreshToken = response?.data?.refresh_token;
              setRefreshToken(refreshToken)
              setToken(accessToken);
              localStorage.setItem('accessToken', accessToken); // Guardar en localStorage
              localStorage.setItem('refreshToken', refreshToken); // Guardar en localStorage
              getAndSetUserData(accessToken);
              getAndSetUserData(accessToken);
            })
            .catch(error => {
              if (error)
                console.error('Error de solicitud:', error);

              setIsAuthLoaded(false); // Asegúrate de marcar como cargado incluso en caso de error
            });
        } else {
          setUser({
            phone: '',
            name: '',
            email: ''
          });
          setAuthenticated(false);
          setIsAuthLoaded(false);
        }
      } catch (error) {
        console.error('Error al obtener datos del usuario:', error.message);
        setAuthenticated(false);
        setIsAuthLoaded(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const getAndSetUserData = async () => {
    try {
      const data = await get('/users/get_user', null);
      setUser(data);
      setAuthenticated(true);
      setIsAuthLoaded(false); // Marcar como cargado solo cuando la autenticación fue exitosa
    } catch (error) {
      console.error('Error al obtener datos del usuario:', error.message);
      setAuthenticated(false);
      setIsAuthLoaded(false);
    }
  };

  const logout = () => {
    setIsAuthLoaded(true);
    setUser({
      phone: "",
      name: "",
      email: ""
    });
    signOut(auth);
    setAuthenticated(false);
    setIsAuthLoaded(false);
    localStorage.removeItem('accessToken'); // Eliminar del localStorage al cerrar sesión
    localStorage.removeItem('refreshToken'); // Eliminar del localStorage al cerrar sesión
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated, logout, user, setUser, isAuthLoaded, token, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
