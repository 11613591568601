import React from 'react';
import Spinner from "react-bootstrap/Spinner";

export default function SpinnerComponent() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <Spinner
        animation="border"
        style={{
          margin: "auto",
          color: ' #7340d8'
        }}
      />
    </div>
  )
}
