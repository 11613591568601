import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import BaseSecondaryButtonComponent from '../../../components/BaseSecondaryButtonComponent'
import BasePurpleButtonComponent from '../../../components/BasePurpleButtonComponent'

export default function AlertDeleteModalComponent({ isOpen, onClose, onConfirm, loading }) {

  return (
    <Modal show={isOpen} onHide={onClose} centered size='sm'>
      <div style={{ backgroundColor: '#ffffff', borderRadius: '8px' }}>
        <Modal.Body >
          <p>¿Estás seguro de que deseas eliminar este elemento?</p>
        </Modal.Body>
        <Modal.Footer>
          <BaseSecondaryButtonComponent buttonText={'Cancelar'} onClick={onClose} />
          <BasePurpleButtonComponent buttonDisabled={loading} buttonText={loading ? <Spinner size='sm' style={{ margin: '0 20px' }} /> : 'Eliminar'} onClick={onConfirm} />
        </Modal.Footer>
      </div>
    </Modal >
  )
}
