import React from "react";
import { Col, Row } from "react-bootstrap";
import { CardProfileComponent } from "./CardProfileComponent";

export const UserInformationComponent = () => {
  return (
    <CardProfileComponent>
      <p className="title-card-profile">Información personal</p>
      <Row className="mt-4">
        <Col md={6}>
          <p className="label-input-card-profile">Nombre y apellido</p>
          <input
            placeholder="Nombre Apellido"
            type="text"
            className="input-card-profile"
          />
        </Col>
        <Col md={6} className="mt-4 mt-md-0">
          <p className="label-input-card-profile">Email</p>
          <input
            placeholder="email@email.com"
            type="text"
            className="input-card-profile"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col lg={6}>
          <p className="label-input-card-profile">Teléfono celular</p>
          <input
            placeholder="3531231231"
            type="text"
            className="input-card-profile"
          />
        </Col>
      </Row>
    </CardProfileComponent>
  );
};
