import React from "react";

export const ModalPriceDeleteDealsComponent = ({
  show,
  setShow,
  title,
  type,
  lengthItems,
  handleClick,
}) => {
  if (!show) return null;

  const handleClose = () => {
    setShow(false);
  };

  const handleModalClick = (e) => {
    if (e.target.closest(".modal-success")) {
      return;
    }

    handleClose();
  };

  return (
    <>
      <div className="modal-overlay" onClick={handleModalClick}>
        <div className="modal-success">
          {type === "price" ? (
            <p className="text-title-modal-table-deals mb-3">{title}</p>
          ) : (
            <p className="text-title-modal-table-deals mb-2">{title}</p>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {type === "price" && lengthItems === 1 ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <input
                  type="text"
                  placeholder="$000.000.000"
                  className="input-modal input-width mb-4"
                />
                <p className="description-coeficiente mb-3">
                  El cambio de precio, fluctua en usd.
                </p>
              </div>
            ) : type === "price" ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <input
                  type="text"
                  placeholder="Ingresar coeficiente"
                  className="input-modal input-width mb-2"
                />
                <p className="description-coeficiente mb-3">
                  Ej: Si queres dismunuir el precio un 10% el coeficiente sera
                  0,9.
                  <br /> Si queres aumentar el pricing el coeficiente sera 1,1.
                </p>
              </div>
            ) : null}
            {type === "delete" && lengthItems === 1 ? (
              <p className="text-question-delete mb-3">
                ¿Estás seguro de eliminar este vehiculo?
              </p>
            ) : type === "delete" ? (
              <p className="text-question-delete mb-3">
                Estás seguro de borrar {lengthItems} vehículos
              </p>
            ) : null}
          </div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <button
              className="btn-outlined-modal btn-width"
              onClick={handleClose}
            >
              {type === "delete" ? "No" : "Cerrar"}
            </button>
            <button
              className="btn-contained-modal btn-width ms-3"
              onClick={() => {
                handleClick();
                handleClose();
              }}
            >
              {type === "delete" ? "Si" : "Guardar cambios"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
