import React from "react";
import { AiFillStar } from "react-icons/ai";
import { colors } from "../../../styles/colors";

export const CardClientExperienciesComponent = ({
  image,
  opinion,
  name,
  score,
  age,
}) => {
  return (
    <div>
      <div className="mb-5 cardClient ms-2">
        <img src={image} alt="client" className="cliente-photo" />
        <div className="containerInfoClient p-2">
          <div className="d-flex align-items-center justify-content-start">
            <div className="containerNameAge d-flex flex-column align-items-start justify-content-center">
              <p className="name">{name}</p>
              <p className="age">{age} años</p>
            </div>
            <div
              className="containerStar d-flex align-items-center justify-content-center ms-2 me-2"
              style={{ width: "32px", height: "32px" }}
            >
              <AiFillStar fontSize={"24px"} color={colors.purple.purple} />
            </div>
            <p className="numberPromedio" style={{ fontSize: "16px" }}>
              {score}
            </p>
          </div>
          <p className="opinionClient mt-2">{opinion}</p>
        </div>
      </div>
    </div>
  );
};
