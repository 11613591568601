import React from "react";
import { Col } from "react-bootstrap";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const CardAdvantagesComponent = ({ imagen, title, description }) => {
  let isPageWide = useMediaQuery("(min-width: 768px)");

  return (
    <Col lg={6} className="d-flex align-items-center mb-2  mt-2">
      <div>
        <img
          src={imagen}
          alt="clock-auta"
          style={{
            width: isPageWide ? "auto" : "90px",
            height: isPageWide ? "auto" : "90px",
          }}
        />
      </div>
      <div className="ms-4">
        <p
          className="titleCardAdvantages mb-2"
          style={{ fontSize: isPageWide ? "20px" : "18px" }}
        >
          {title}
        </p>
        <p
          className="descriptionCardAdvantages"
          style={{ fontSize: isPageWide ? "16px" : "11px" }}
        >
          {description}
        </p>
      </div>
    </Col>
  );
};
