import React, { useEffect, useState } from "react";
import { HeaderComponent } from "../../components/HeaderComponent";
import { Container } from "react-bootstrap";
import "../CarCatalog/style/catalog.css";
import { SideBarFilterComponent } from "./components/SideBarFilterComponent";
import { CarsListComponent } from "./components/CarsListComponent";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { HeaderLikesDiscardComponent } from "../../components/HeaderLikesDiscardComponent";
import SpinnerComponent from "../../components/SpinnerComponent";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import { get } from "../../utils/apiService";

export const CarCatalogPage = () => {
  const [checked, setChecked] = useState(
    JSON.parse(localStorage.getItem("checkedTinderView")) || false
  );
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [carsList, setCarsList] = useState([]);
  const [totalCars, setTotalCars] = useState(0);
  const { isAuthenticated } = useAuth();
  let isPageWide = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    localStorage.setItem("checkedTinderView", JSON.stringify(checked));
    if (isAuthenticated) {
      const fetchData = async () => {
        try {
          const result = await get("/cars/get_filtered_cars_by_user");
          setCarsList(result?.cars);
          setTotalCars(result?.total);
        } catch (error) {
          console.error("Error al obtener datos:", error);
        }
        setLoading(false);
      };
      fetchData();
    } else {
      //aca no usamos el get de apiService por q viene con token y header
      axios
        .get(
          `https://backend-web-dot-admin-dev-355313.rj.r.appspot.com/cars/get_filtered_cars`
        )
        .then((resp) => {
          setCarsList(resp?.data?.cars);
          setTotalCars(resp?.data?.total);
        })
        .catch((e) => {
          console.log(e);
        });
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <div>
          <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
          <Container className={`${expanded ? "blur-background" : ""}`}>
            <div className="container-all">
              <HeaderLikesDiscardComponent
                totalCars={totalCars}
                type={"catalog"}
                checked={checked}
                setChecked={setChecked}
              />
              <div className="d-flex" style={{ marginTop: "16px" }}>
                {isPageWide ? (
                  <div>
                    <SideBarFilterComponent />
                  </div>
                ) : null}
                <div className="ms-md-3">
                  <CarsListComponent
                    carsList={carsList}
                    setCarsList={setCarsList}
                    typeLayout={checked}
                    setTypeLayout={setChecked}
                    type={"catalog"}
                  />
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};
