import React from "react";
import { Row } from "react-bootstrap";
import Clock from "../images/clock.svg";
import Car from "../images/car.svg";
import Calendar from "../images/calendar.svg";
import Key from "../images/key.svg";
import { CardAdvantagesComponent } from "./CardAdvantagesComponent";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const ContainerCardAdvantagesComponent = () => {
  let isPageWide = useMediaQuery("(min-width: 768px)");

  return (
    <Row
      className="cardAdvantagesHome d-flex align-items-center justify-content-center mb-5 p-md-4"
      style={{ width: isPageWide ? "90%" : "100%" }}
    >
      <CardAdvantagesComponent
        imagen={Clock}
        title={"Compra inmediata"}
        description={
          <>
            50% al instante y 50% 72 horas luego de cerrar la operación.
            <br />
            Bono AR$ 10.000 / U$D 50 / € 40.
          </>
        }
      />
      <CardAdvantagesComponent
        imagen={Car}
        title={"Compra Clásica"}
        description={
          <>
            Pago 100% al entragar el auto.
            <br />
            Pago en AR$, U$D o €.
          </>
        }
      />

      <CardAdvantagesComponent
        imagen={Key}
        title={"Llave por Llave"}
        description={
          <>
            Cambio por un 0km Chevrolet.
            <br />
            No importa el valor del auto.
          </>
        }
      />

      <CardAdvantagesComponent
        imagen={Calendar}
        title={"Compra Diferida"}
        description={
          <>
            Compra asegurada.
            <br />
            Plazo consignación en 60 días.
          </>
        }
      />
    </Row>
  );
};
