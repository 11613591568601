import React from "react";
import { FaWhatsapp } from "react-icons/fa";

export const WhatsAppButtonComponent = () => {
  return (
    <a
      href="https://wa.me/5491123707738" // Reemplaza con el número de teléfono de WhatsApp      className="whatsapp-button-contact d-flex align-items-center justify-content-center"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp className="whatsapp-icon" />
    </a>
  );
};
