import React from "react";
import { useState } from "react";
// import { CarsCardsComponent } from "./CarsCardsComponent";
import CardsComponent from "./PruebasComponent";
import CarsCardsComponent from "./CarsCardsComponent";

export const CarsListComponent = ({
  carsList,
  setCarsList,
  typeLayout,
  setTypeLayout,
  type,
}) => {
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start ">
      {/* <CardsComponent car={carsList} /> */}
      <CarsCardsComponent
        car={carsList}
        typeLayout={typeLayout}
        setCarsList={setCarsList}
        // onDelete={() => deleteCarFromTheList(car?.car_id)}
      />
    </div>
  );
};
