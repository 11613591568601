import React, { useState } from "react";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { Col, Row } from "react-bootstrap";
import ChargeCar from "../images/charge-car.svg";
import ChooseCar from "../images/choose-car.svg";
import TellUs from "../images/tell-us.svg";
import BaseWhiteButtonComponent from "../../../components/BaseWhiteButtonComponent";
import "../style/renewal-modal.css";
import "../../../styles/components/baseInput.css";
import ModalMinKmsComponent from "../../../components/ModalMinKmsComponent";
import { useNavigate } from "react-router-dom";

export default function RenewalComponent() {
  const [currentView, setCurrentView] = useState("step1");
  const [number, setNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      {currentView === "step1" ? (
        <div className="mt-4">
          <TitleHomeWithIcon
            title={"¡Querés Renovar!"}
            fontSize={"30px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
            isModal={true}
          />
          <p className="subtitle-pop-up mb-5 mt-2">
            Seguí estos tres simples pasos:
          </p>
          <Row className="mt-5">
            <Col md={4} className="img-renewl-container">
              <img src={ChargeCar} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Cargá tu auto.</p>
            </Col>
            <Col md={4} className="img-renewl-container">
              <img src={TellUs} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Contanos qué auto buscás.</p>
            </Col>
            <Col md={4} className="img-renewl-container">
              <img src={ChooseCar} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Elegí tu próximo auto.</p>
            </Col>
          </Row>
          <div className="button-container">
            <BaseWhiteButtonComponent
              buttonText={"Empezar"}
              onClick={() => navigate("/renewal")}
            />
          </div>
        </div>
      ) : null}
      {showModal && (
        <ModalMinKmsComponent
          show={showModal}
          kms={number}
          setShow={setShowModal}
          setIsContinuing={setIsContinuing}
          setCurrentView={setCurrentView}
        />
      )}
    </>
  );
}
