import React from "react";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { Container } from "react-bootstrap";
import { ContainerCardAdvantagesComponent } from "./ContainerCardAdvantagesComponent";

export const AdvantagesComponent = () => {
  return (
    <div className="containerAdvantages">
      <Container className="d-flex flex-column align-items-center justify-content-center p-all">
        <div className="mb-3 mt-4">
          <TitleHomeWithIcon
            fontSize={"24px"}
            title={"La mejor forma de comprar, vender y renovar."}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
          />
        </div>
        <ContainerCardAdvantagesComponent />
      </Container>
    </div>
  );
};
