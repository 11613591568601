import CarExample from "../images/car-example.png";

export const dealsArray = [
  {
    img: CarExample,
    car_id: "11",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "22",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "33",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "44",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "55",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "66",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "77",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "88",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "99",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "100",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
  {
    img: CarExample,
    car_id: "110",
    brand: "Marca",
    model: "Modelo",
    version: "Version",
    year: "2024",
    kms: "10.000",
    color: "Color",
    price: "$000.000.000",
  },
];
