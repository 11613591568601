import React from "react";
import { Modal } from "react-bootstrap";
import "../styles/components/modalLowKms.css";

export default function ModalMinKmsComponent({
  show,
  setShow,
  kms,
  setIsContinuing,
  setSelectedButton,
  setCurrentView,
  isMobile,
}) {
  const handleClose = () => setShow(false);
  const HandleYesButton = () => {
    setIsContinuing(true);
    if (isMobile) {
      setSelectedButton(6);
    } else {
      setCurrentView("step3");
    }
    setShow(false);
  };
  return (
    <Modal show={show} onHide={handleClose} size="md" centered>
      <Modal.Body
        style={{ backgroundColor: "white" }}
        className="modal-kms-low"
      >
        <p className="title-modal-low-kms">
          El kilometraje <br /> ingresado es muy bajo
        </p>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <p className="value-kms-modal mt-2 mb-2">
            Ingresaste <strong> {kms}</strong> kms
          </p>
          <p className="ask-kms-modal mb-5">¿Es correcto?</p>
        </div>
        <div className="d-flex align-items-center justify-content-around flex-column flex-md-row mt-4">
          <button className="btn-white-kms-modal" onClick={HandleYesButton}>
            Si
          </button>
          <button className="btn-purple-kms-modal" onClick={handleClose}>
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
