import axios from 'axios';

const apiService = axios.create({
  baseURL: 'https://backend-web-dot-admin-dev-355313.rj.r.appspot.com',
  timeout: 5000,
});

const fetchDataPhoneNumberExists = async (phoneNumber) => {

  try {
    const response = await apiService.get('/users/check_user_existence', {
      params: { phone: phoneNumber },
    });
    return response.data.exists;
  } catch (error) {
    throw error;
  }
};

export default fetchDataPhoneNumberExists;