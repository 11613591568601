import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { HeaderComponent } from "../../components/HeaderComponent";
import { HeaderFilterTableDealsComponent } from "./components/HeaderFilterTableDealsComponent";
import "../../modules/TableDeals/style/style.css";
import { TableDealsComponent } from "./components/TableDealsComponent";
import { dealsArray } from "./services/deals";

export const TableDealsPage = () => {
  const [expanded, setExpanded] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemTable, setSelectedItemTable] = useState([]);
  const [dealsMap, setDealsMap] = useState([]);

  useEffect(() => {
    setDealsMap(dealsArray);
  }, [selectedItemTable]);

  return (
    <div className="container-table-deals">
      <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
      <Container className={`${expanded ? "blur-background" : ""}`}>
        <div className="container-all">
          <div className="container-all-table-header">
            <HeaderFilterTableDealsComponent
              selectedItemTable={selectedItemTable}
              setSelectedItemTable={setSelectedItemTable}
              selectedItems={selectedItems}
              setDealsMap={setDealsMap}
              setSelectedItems={setSelectedItems}
            />
            <TableDealsComponent
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              selectedItemTable={selectedItemTable}
              setSelectedItemTable={setSelectedItemTable}
              dealsMap={dealsMap}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
