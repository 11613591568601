
import React, { useState, useRef, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import BasePurpleButtonComponent from '../../../components/BasePurpleButtonComponent'
import { TitleHomeWithIcon } from '../../Home/components/TitleHomeWithIcon'
import { colors } from '../../../styles/colors'
import { PhoneAuthProvider, RecaptchaVerifier, signInWithCredential, signInWithPhoneNumber } from 'firebase/auth'
import { auth } from '../../../firebase/firebase'
import '../style/RegistrationAndLoginModal.css'
import { useAuth } from '../../../context/AuthContext'
import fetchDataPhoneNumberExists from '../../../utils/validatePhoneNumberExists'


export default function LoginModalComponent({ setShowLogin, user, toggleLoginModal }) {
  const [isLoading, setIsLoading] = useState();
  const [currentView, setCurrentView] = useState('step1')
  const [isDisabledB, setIsDisabledB] = useState(false);
  const [countryCode, setCountryCode] = useState(""); // Estado para el código de país
  const [phoneNumber, setPhoneNumber] = useState(""); // Estado para el número de teléfono
  const [confirmationResult, setConfirmationResult] = useState(null); // Estado para el resultado de la confirmación
  const [verificationCodes, setVerificationCodes] = useState(["", "", "", "", "", ""]); // Estado para el código de verificación
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)]; // Referencias a los inputs
  const [textError, setTextError] = useState('')
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [showEmptyLogin, setShowEmptyLogin] = useState(false)
  const { isAuthenticated } = useAuth()


  useEffect(() => {
    const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });
    setRecaptchaVerifier(verifier);
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true)
    setIsDisabledB(true)
    e.preventDefault();
    try {
      const fullPhoneNumber = `+549${countryCode}${phoneNumber}`; // Combina el código de país y el número de teléfono
      const isPhoneNumberValid = await fetchDataPhoneNumberExists(fullPhoneNumber);

      if (!isPhoneNumberValid) {
        // Si el número no es válido, muestra una alerta y detén el flujo
        setTextError('Número de teléfono no pertenece a una cuenta existente');
        return;
      }
      const confirmationResult = await signInWithPhoneNumber(auth, fullPhoneNumber, recaptchaVerifier)
      // Al enviar el código de verificación, Firebase devuelve un objeto de confirmación
      setConfirmationResult(confirmationResult); // Almacena el objeto de confirmación para su uso posterior
      handleStartClick()
      setIsLoading(false)
      setIsDisabledB(false)

    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-phone-number':
          // El número de teléfono es inválido
          setTextError('El número de teléfono es inválido')
          break;
        case 'auth/quota-exceeded':
          // Se ha superado la cuota de SMS para este proyecto
          setTextError('Se ha superado la cuota de SMS')
          break;
        case 'auth/captcha-check-failed':
          // Error de reCAPTCHA
          setTextError('Error de reCAPTCHA')
          break;
        default:
          // Otros errores
          console.error('Error al verificar el número de teléfono:', error);
          setTextError('Error al verificar el número de teléfono, intenta de nuevo')
          break;
      }
    } finally {
      setIsLoading(false)
      setIsDisabledB(false)
    }

  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    // Verifica si el valor ingresado es un número o una cadena vacía y está en el rango de 0 a 9
    if (/^[0-9]$|^$/.test(value) && index >= 0 && index <= 5) {
      const newVerificationCodes = [...verificationCodes];
      newVerificationCodes[index] = value;
      setVerificationCodes(newVerificationCodes);

      // Mueve el foco al siguiente input solo si se ha ingresado un dígito
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setIsDisabledB(true)
    setIsLoading(true)

    try {
      const enteredCode = verificationCodes.join("");
      const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, enteredCode);
      await signInWithCredential(auth, credential);
      // Usuario autenticado correctamente
      const user = auth.currentUser;
      console.log("Usuario autenticado:", user, 'cerra el modal?');
      toggleLoginModal();
    } catch (error) {
      // Manejo de errores específicos
      switch (error.code) {
        case 'auth/invalid-verification-code':
          // El código de verificación es inválido
          setTextError('El código de verificación es inválido')
          break;
        case 'auth/code-expired':
          // El código de verificación ha caducado
          setTextError('El código de verificación ha caducado')
          break;
        case 'auth/credential-already-in-use':
          // La credencial ya está en uso por otro usuario
          setTextError('La credencial ya está en uso por otro usuario')
          break;
        default:
          // Otros errores
          console.error('Error al verificar el código de verificación:', error);
          setTextError('Error al verificar el código.')
          break;
      }
    } finally {
      setIsLoading(false);
      setIsDisabledB(false);
    }

  };

  const handleStartClick = () => {
    if (currentView === "step1") {
      setCurrentView("step2");
    } else if (currentView === "step2") {
      setCurrentView("step3");
    }
  };

  const isFormValidated = countryCode !== "" && phoneNumber !== "";
  return (
    <>
      {currentView === 'step1' ? (
        <>
          <TitleHomeWithIcon
            title={"Iniciar sesión"}
            fontSize={"28px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple} />
          <div className='card-contact-data' >
            <div className='card-contact-header'>
              <p className='contact-header-title'>Completa todos los campos</p>
            </div>
            <div style={{ padding: '12px', height: '364px' }}>
              <form onSubmit={handleSubmit} style={{
                height: '100%', display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
                <div>
                  {showEmptyLogin ? null : (
                    <>
                      {isAuthenticated ? (
                        <>
                          <div style={{ marginBottom: '14px' }}>
                            <p style={{ color: '#482481', fontSize: '16px', fontWeight: 'bold' }}> ¡Hola {user?.name}! </p>

                          </div><div style={{ marginBottom: '14px' }}>
                            <p style={{ color: '#482481', fontSize: '14px', fontWeight: '400' }}>Para ingresar en tu cuenta completa tu numero de teléfono. <br />
                              Si deseas iniciar sesión con otra cuenta existente dirigite <span className='other-user-login-text' onClick={() => setShowEmptyLogin(true)}>aca.</span></p>
                          </div>
                        </>
                      ) : null}
                    </>
                  )
                  }
                  <div style={{ marginBottom: '12px' }}>
                    <label className='input-labels'>Teléfono celular</label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p className='phone-number'>+549</p>
                      <input type="text"
                        className="base-input"
                        placeholder='Ej: 11'
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        maxLength={3}
                        style={{ width: '65px', marginRight: '10px' }} />
                      <input type="text"
                        className="base-input"
                        placeholder='Sin 15'
                        value={phoneNumber}
                        maxLength={8}
                        onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                    <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }}>{textError}</p>
                  </div>
                </div>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  <p className='security-code-text'>Recibirás un código de seguridad</p>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      style={{
                        margin: '10px',
                        color: ' #7340d8',
                      }} />
                  ) :
                    ''}
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div id="recaptcha-container"></div>
                  <BasePurpleButtonComponent buttonDisabled={!isFormValidated || isLoading} buttonText={isLoading ? 'Enviando...' : 'Continuar'} type="submit" />
                </div>
              </form>
            </div>
          </div >
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }} onClick={() => setShowLogin(false)}>
            <p className='bottom-text-onclick' >Crear una cuenta nueva</p>
          </div>
        </>
      ) : (
        <>
          <TitleHomeWithIcon
            title={`¡Bienvenido!`}
            fontSize={"28px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple} />
          <div className='card-contact-data' >
            <div className='card-contact-header'>
              <p className='contact-header-title'>Verificación telefónica.</p>
            </div>
            <div style={{
              padding: '12px', height: '364px'
            }}>
              <form onSubmit={handleVerification} style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: ' space-between',
                height: '100%'
              }}>
                <div style={{ marginTop: '10px' }}>
                  <p className='phone-number-two'>Ingresá el código que te enviamos
                    por SMS al <span className='phone-number-title'>+54{countryCode}{phoneNumber}</span></p>
                  <div style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}>
                    {inputRefs.map((inputRef, index) => (
                      <input
                        key={index}
                        ref={inputRef}
                        type="text"
                        value={verificationCodes[index]}
                        className="code-input"
                        onChange={(e) => handleChange(e, index)}
                        maxLength="1" />
                    ))}
                  </div>
                  <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }}>{textError}</p>
                </div>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    style={{
                      margin: "auto",
                      color: ' #7340d8',
                      marginTop: '40px'
                    }} />
                ) :
                  ''}
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <BasePurpleButtonComponent buttonDisabled={isDisabledB} buttonText={'Continuar'} type="submit" />
                </div>
              </form>
            </div>
          </div>
        </>
      )
      }
    </>
  )
}
