import React, { useEffect, useState } from "react";
import "../style/ButtonGroupDerivator.css";
import { Carousel, Container } from "react-bootstrap";
import CardDerivatorComponent from "./CardDerivatorComponent";
import Car1 from "../images/car-derivador1.png";
import Car2 from "../images/car-derivador2.png";
import Car3 from "../images/car-derivador3.png";
import ArrowDown from "../images/ic_arrow_down.svg";
import { useNavigate } from "react-router-dom";
import CurveRight from "../../../images/icon-right-curva.svg";
import CurveLeft from "../../../images/icon-left-curva.svg";
import ModalBaseDerivatorComponent from "../../QuoteStepper/components/Mobile/ModalBaseDerivatorComponent";
import ChargeCar from "../../Home/images/charge-car.svg";
import Calendar from "../../Home/images/calendar.svg";
import SalePrice from "../../Home/images/sale-price.svg";
import ChooseCar from "../../Home/images/choose-car.svg";
import TellUs from "../../Home/images/tell-us.svg";
import Messages from "../../Home/images/messages.svg";

export default function ButtonGroupDerivatorComponent() {
  const [showModalRenewal, setShowModalRenewal] = useState();
  const [showModalSale, setShowModalSale] = useState();
  const [showModalBuy, setShowModalBuy] = useState();
  const [selectedButton, setSelectedButton] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    handleButtonClick(selectedButton);
  }, [selectedButton]);

  const handleButtonClick = (buttonNumber) => {
    setSelectedButton(buttonNumber);
  };

  return (
    <Container>
      <div className="button-group-wrapper">
        <div className="d-flex">
          <img src={CurveLeft} alt="curve-left" />
          <div className="button-group" style={{ width: "100%" }}>
            <button
              className={`button ${selectedButton === 1 ? "selected" : ""}`}
              onClick={() => handleButtonClick(1)}
            >
              Renovar
            </button>
            <button
              className={`button ${selectedButton === 2 ? "selected" : ""}`}
              onClick={() => handleButtonClick(2)}
            >
              Comprar
            </button>
            <button
              className={`button ${selectedButton === 3 ? "selected" : ""}`}
              onClick={() => handleButtonClick(3)}
            >
              Vender
            </button>
          </div>
          <img src={CurveRight} alt="curve-right" />
        </div>
      </div>
      <div className="component-display">
        <Carousel
          controls={false}
          indicators={false}
          interval={null}
          activeIndex={selectedButton - 1}
        >
          <Carousel.Item style={{ height: "385px" }}>
            <CardDerivatorComponent
              title="Quiero Renovar"
              description="Cargá tu auto, contanos qué buscás y elegí entre todas las opciones que tenemos para vos."
              iconSrc={ArrowDown}
              carImgSrc={Car1}
              buttonText="Empezar"
              backgroundColor="1"
              onClick={() => setShowModalRenewal(true)}
            />
          </Carousel.Item>
          <Carousel.Item style={{ height: "385px" }}>
            <CardDerivatorComponent
              title="Quiero Comprar"
              description="Contanos qué buscás y nosotros filtramos entre todas las opciones que tenemos para darte las mejores propuestas."
              iconSrc={ArrowDown}
              carImgSrc={Car2}
              buttonText="Empezar"
              backgroundColor="2"
              onClick={() => setShowModalBuy(true)}
            />
          </Carousel.Item>
          <Carousel.Item style={{ height: "385px" }}>
            <CardDerivatorComponent
              title="Quiero Vender"
              description="Subí tu vehículo, contanos todo sobre él y lo cotizamos cuanto antes para que vos puedas decidir."
              iconSrc={ArrowDown}
              carImgSrc={Car3}
              buttonText="Empezar"
              backgroundColor="3"
              onClick={() => setShowModalSale(true)}
            />
          </Carousel.Item>
        </Carousel>
      </div>
      <ModalBaseDerivatorComponent
        show={showModalRenewal}
        setShow={setShowModalRenewal}
        title={"Renovar"}
        iconOne={ChargeCar}
        iconTwo={TellUs}
        iconThree={ChooseCar}
        descriptionOne={"Cargá tu auto."}
        descriptionTwo={"Contanos qué buscás."}
        descriptionThree={"Elegí tu próximo auto."}
        handleEvent={() => navigate("/renewal")}
      />
      <ModalBaseDerivatorComponent
        show={showModalBuy}
        setShow={setShowModalBuy}
        title={"Comprar"}
        iconOne={TellUs}
        iconTwo={ChooseCar}
        iconThree={Messages}
        descriptionOne={"Contanos qué auto buscas."}
        descriptionTwo={"Elegí entre las opciones."}
        descriptionThree={"Consultá con un Asesor Auta."}
        handleEvent={() => navigate("/cars")}
      />
      <ModalBaseDerivatorComponent
        show={showModalSale}
        setShow={setShowModalSale}
        title={"Vender"}
        iconOne={ChargeCar}
        iconTwo={SalePrice}
        iconThree={Calendar}
        descriptionOne={"Cargá tu auto."}
        descriptionTwo={"Recibí tu cotización."}
        descriptionThree={
          <>
            Agendá tu turno para <br /> inspección y entrega.
          </>
        }
        handleEvent={() => navigate("/sale")}
      />
    </Container>
  );
}
