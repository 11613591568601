import React from "react";
import { FiltersComponent } from "./FiltersComponent";

export const SideBarFilterComponent = () => {
  return (
    <div className="card-filter mb-3">
      <FiltersComponent />
    </div>
  );
};
