import React from 'react';
import '../styles/components/basePurpleButton.css'

export default function BasePurpleButtonComponent({ buttonText, buttonDisabled, onClick }) {
  return (
    <button className="purple-button" disabled={buttonDisabled} onClick={onClick}>
      {buttonText}
    </button>
  )
}
