import React from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";

export const ViewStepOneComponent = ({
  isAuthenticated,
  handleSubmitWithUser,
  user,
  textError,
  isLoading,
  handleSubmit,
  showloginView,
  setName,
  email,
  handleEmailChange,
  isEmailValid,
  countryCode,
  setCountryCode,
  phoneNumber,
  setPhoneNumber,
  setShowLoginView,
  setTextError,
  isFormValidated,
  loginAlert,
  name,
}) => {
  let isPageWide = useMediaQuery("(min-width: 1000px)");

  return (
    <>
      {isAuthenticated ? (
        <div
          className={
            isPageWide ? "card-contact-data" : "mobile-dropdown-container"
          }
        >
          <div className="card-contact-header">
            <p className="contact-header-title">¡Estos son tus datos!</p>
          </div>
          <div style={{ padding: "12px", height: "364px" }}>
            <form
              onSubmit={handleSubmitWithUser}
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div style={{ marginBottom: "12px" }}>
                  <label className="input-labels">Nombre</label>
                  <p>{user?.name}</p>
                </div>
                <div style={{ marginBottom: "12px" }}>
                  <label className="input-labels">Email</label>
                  <p>{user?.email}</p>
                </div>
                <div style={{ marginBottom: "12px" }}>
                  <label className="input-labels">Teléfono celular</label>
                  <p>{user?.phone}</p>
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {textError}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between align-items-center">
                <p className="security-code-text">
                  Enviaremos la cotizacion a este numero.
                </p>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    style={{
                      margin: "10px",
                      color: " #7340d8",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <BasePurpleButtonComponent
                  buttonText={"Continuar"}
                  type="submit"
                />
              </div>
            </form>
          </div>
        </div>
      ) : (
        <>
          <div
            className={
              isPageWide ? "card-contact-data" : "mobile-dropdown-container"
            }
          >
            <div className="card-contact-header">
              <p className="contact-header-title">
                ¡Solo nos faltan tus datos!
              </p>
            </div>
            <div style={{ padding: "12px", height: "364px" }}>
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {showloginView ? null : (
                    <>
                      <div style={{ marginBottom: "12px" }}>
                        <label className="input-labels">Nombre completo</label>
                        <input
                          type="text"
                          className="base-input"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Ingresar tu nombre"
                        />
                      </div>
                      <div style={{ marginBottom: "12px" }}>
                        <label className="input-labels">Email</label>
                        <input
                          type="text"
                          className="base-input"
                          value={email}
                          onChange={handleEmailChange}
                          placeholder="Ingresar tu email"
                        />
                        {!isEmailValid ? (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            Email invalido
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                  <div style={{ marginBottom: "12px" }}>
                    <label className="input-labels">Teléfono celular</label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <p className="phone-number">+549</p>
                      <input
                        type="text"
                        className="base-input"
                        placeholder="Ej: 11"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        maxLength={3}
                        style={{ width: "65px", marginRight: "10px" }}
                      />
                      <input
                        type="text"
                        className="base-input"
                        placeholder="Sin 15"
                        value={phoneNumber}
                        maxLength={8}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {textError}
                    </p>
                  </div>
                </div>
                {loginAlert && !showloginView ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <BasePurpleButtonComponent
                      buttonText={"Inicia sesion"}
                      onClick={() => {
                        setShowLoginView(true);
                        setTextError("");
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="d-flex flex-column justify-content-between align-items-center">
                      <p className="security-code-text">
                        Recibirás un código de seguridad
                      </p>
                      {isLoading ? (
                        <Spinner
                          animation="border"
                          style={{
                            margin: "10px",
                            color: " #7340d8",
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <BasePurpleButtonComponent
                        buttonDisabled={!isFormValidated || isLoading}
                        buttonText={"Continuar"}
                        type="submit"
                      />
                    </div>
                  </>
                )}
                <div id="recaptcha-container"></div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
