import React, { useState } from "react";
import "../../../styles/components/basePurpleButton.css";

export default function CardDerivatorComponent({
  title,
  description,
  iconSrc,
  carImgSrc,
  buttonText,
  backgroundColor,
  isPageWide,
  onClick,
}) {
  const [isHovered, setIsHovered] = useState(false);

  const cardBackgroundStyle = {
    backgroundColor: backgroundColor || "#ffffff",
  };

  const cardStyle = {
    transform: isPageWide && isHovered ? "scale(1.08)" : "",
    transition: isPageWide ? "transform 0.3s ease" : "",
  };

  const buttonDisabled = isPageWide ? !isHovered : isHovered;

  const handleMouseEnter = () => {
    if (isPageWide) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (isPageWide) {
      setIsHovered(false);
    }
  };

  return (
    <div
      className="card-home"
      style={cardStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="card-home-background" style={cardBackgroundStyle}>
        <div
          className={`card-home-background-left card-home-background-color-${backgroundColor}`}
        >
          {iconSrc && <img src={iconSrc} className="card-icon" alt="" />}
          {title && <h2 className="card-title">{title}</h2>}
          {description && <p className="card-description">{description}</p>}
          {carImgSrc && (
            <img src={carImgSrc} className="car-img car-img-renew" alt="" />
          )}
        </div>
        <div
          className={`card-home-background-right card-home-background-color-${backgroundColor}`}
        >
          {/* Contenido personalizado para la parte derecha de la tarjeta */}
        </div>
      </div>
      {buttonText && (
        <button
          className="purple-button"
          disabled={buttonDisabled}
          onClick={onClick}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
}
