import React from "react";
import Switch from "react-switch";
import { colors } from "../../../styles/colors";
import IconModeTinderWhite from "../images/icon-mode-tinder-white.svg";
import IconModeTinderPurple from "../images/icon-mode-tinder-purple.svg";
import IconModeRowWhite from "../images/icon-mode-row-white.svg";
import IconModeRowPurple from "../images/icon-mode-row-purple.svg";

function SwitchTypeGridComponent({ setChecked, checked }) {
  const handleChange = (newChecked) => {
    localStorage.setItem('checkedTinderView', JSON.stringify(newChecked));
    setChecked(newChecked);
  };
  const checkedValue = checked !== undefined ? checked : true;

  return (
    <label htmlFor="small-radius-switch">
      <Switch
        checked={checkedValue}
        onChange={handleChange}
        handleDiameter={30}
        offColor={colors.purple.lightPurple}
        onColor={colors.purple.lightPurple}
        offHandleColor={colors.purple.purple}
        onHandleColor={colors.purple.purple}
        height={30}
        width={60}
        borderRadius={100}
        activeBoxShadow={`0px 0px 1px 2px solid ${colors.purple.strokePurple}`}
        uncheckedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={IconModeRowPurple} alt="icon-mode-row-purple" />
          </div>
        }
        checkedIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <img src={IconModeTinderPurple} alt="icon-mode-tinder-purple" />
          </div>
        }
        uncheckedHandleIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              border: `3px solid ${colors.purple.strokePurple}`,
              borderRadius: "100px",
            }}
          >
            <img src={IconModeTinderWhite} alt="icon-mode-tinder-white" />
          </div>
        }
        checkedHandleIcon={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              border: `3px solid ${colors.purple.strokePurple}`,
              borderRadius: "100px",
            }}
          >
            <img src={IconModeRowWhite} alt="icon-mode-row-white" />
          </div>
        }
        className="react-switch"
        id="small-radius-switch"
      />
    </label>
  );
}

export default SwitchTypeGridComponent;
