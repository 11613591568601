import React from "react";
import { FiPlus } from "react-icons/fi";
import { HiSearch } from "react-icons/hi";
import IconFilter from "../images/icon-filter.svg";
import IconDeleteDisabled from "../images/icon-delete-disabled.svg";
import IconDeleteActive from "../images/icon-delete-active.svg";
import IconPriceActive from "../images/icon-price-active.svg";
import IconPriceDisabled from "../images/icon-price-disabled.svg";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useState } from "react";
import { ModalsTableDealsComponent } from "./ModalsTableDealsComponent";
import { ModalFilterTableDealsComponent } from "./ModalFilterTableDealsComponent";
import { ModalPriceDeleteDealsComponent } from "./ModalPriceDeleteDealsComponent";
import { dealsArray } from "../services/deals";

export const HeaderFilterTableDealsComponent = ({
  selectedItemTable,
  setSelectedItemTable,
  selectedItems,
  setDealsMap,
  setSelectedItems,
}) => {
  let isPageWide = useMediaQuery("(min-width: 992px)");
  const [showModal, setShowModal] = useState(false);
  const [showModalFIlter, setShowModalFIlter] = useState(false);
  const [showModalPriceDelete, setShowModalPriceDelete] = useState(false);
  const [type, setType] = useState("");
  const [placeholder, setplaceHolder] = useState("");
  const [titleModalPriceDelete, setTitleModalPriceDelete] = useState("");
  const [typeModalPriceDelete, setTypeModalPriceDelete] = useState("");

  const handleChangeInput = (type, placeholder) => {
    setShowModal(true);
    setType(type);
    setplaceHolder(placeholder);
  };

  const activeModalPriceDelete = (title, typeModal) => {
    setShowModalPriceDelete(true);
    setTitleModalPriceDelete(title);
    setTypeModalPriceDelete(typeModal);
  };

  const handleClick = () => {
    // Realiza la lógica para eliminar los elementos seleccionados
    // Puedes utilizar la función setSelectedItemTable para actualizar el estado
    // y eliminar los elementos de la tabla.
    const updatedTable = dealsArray.filter(
      (item, index) => !selectedItems.includes(index)
    );
    // setSelectedItemTable(updatedTable);
    setDealsMap(updatedTable);
    setSelectedItems([]);

    // Cierra el modal
    // setShowModalPriceDelete(false);
  };

  return (
    <>
      <ModalsTableDealsComponent
        show={showModal}
        setShow={setShowModal}
        type={type}
        placeholder={placeholder}
        setType={setType}
      />
      <ModalFilterTableDealsComponent
        show={showModalFIlter}
        setShow={setShowModalFIlter}
      />
      <ModalPriceDeleteDealsComponent
        show={showModalPriceDelete}
        setShow={setShowModalPriceDelete}
        title={titleModalPriceDelete}
        type={typeModalPriceDelete}
        lengthItems={selectedItemTable?.length}
        handleClick={handleClick}
      />
      {isPageWide ? (
        <div className="d-flex align-items-center justify-content-between">
          <p className="title-car-stock">Autos en stock</p>
          <button
            className="btn-add-car d-flex align-items-center justify-content-center"
            onClick={() => handleChangeInput("addcar", "Ingresar Car ID")}
          >
            <FiPlus fontSize="18px" className="me-1" />
            Agregar auto
          </button>
          <button
            className="btn-type-change"
            onClick={() => handleChangeInput("typechange", "Ingresar valor")}
          >
            Tipo de cambio
          </button>
          <div className="container-input-search d-flex align-items-center justify-content-center">
            <input
              type="text"
              placeholder="Buscá la marca"
              className="input-search-brand"
            />
            <HiSearch fontSize="22px" color="#D7C9EE" />
          </div>
          <button
            className="btn-filter-table-deals d-flex align-items-center justify-content-center"
            onClick={() => setShowModalFIlter(true)}
          >
            <img src={IconFilter} alt="icon-filter" className="me-1" />
            Filtro
          </button>
          <div>
            {selectedItemTable?.length > 0 ? (
              <img
                src={IconPriceActive}
                alt="icon-price-active"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  activeModalPriceDelete(
                    selectedItemTable?.length === 1
                      ? "Cambiar precio"
                      : "Coeficiente de pricing",
                    "price"
                  )
                }
              />
            ) : (
              <img src={IconPriceDisabled} alt="icon-price-disabled" />
            )}
          </div>
          <div>
            {selectedItemTable?.length > 0 ? (
              <img
                src={IconDeleteActive}
                alt="icon-delete-active"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  activeModalPriceDelete(
                    selectedItemTable?.length === 1
                      ? "Eliminar vehiculo"
                      : "Eliminar vehiculos",
                    "delete"
                  )
                }
              />
            ) : (
              <img src={IconDeleteDisabled} alt="icon-delete-disabled" />
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-start justify-content-center flex-column">
          <p className="title-car-stock">Autos en stock</p>
          <div
            className="d-flex align-items-center justify-content-between mt-3"
            style={{ width: "100%" }}
          >
            <button
              className="btn-add-car d-flex align-items-center justify-content-center"
              onClick={() => handleChangeInput("addcar", "Ingresar Car ID")}
            >
              <FiPlus fontSize="18px" className="me-1" />
              Agregar auto
            </button>
            <button
              className="btn-type-change ms-2"
              onClick={() => handleChangeInput("typechange", "Ingresar valor")}
            >
              Tipo de cambio
            </button>
          </div>
          <div
            className="container-input-search d-flex align-items-center justify-content-center mt-3"
            style={{ width: "100%" }}
          >
            <input
              type="text"
              placeholder="Buscá la marca"
              className="input-search-brand"
            />
            <HiSearch fontSize="22px" color="#D7C9EE" />
          </div>
          <div className="d-flex align-items-center mt-3">
            <button
              className="btn-filter-table-deals d-flex align-items-center justify-content-center"
              onClick={() => setShowModalFIlter(true)}
            >
              <img src={IconFilter} alt="icon-filter" className="me-1" />
              Filtro
            </button>
            <div>
              {selectedItemTable?.length > 0 ? (
                <img
                  src={IconPriceActive}
                  alt="icon-price-active"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    activeModalPriceDelete(
                      selectedItemTable?.length === 1
                        ? "Cambiar precio"
                        : "Coeficiente de pricing",
                      "price"
                    )
                  }
                />
              ) : (
                <img
                  src={IconPriceDisabled}
                  alt="icon-price-disabled"
                  className="ms-4 me-4"
                />
              )}
            </div>
            <div>
              {selectedItemTable?.length > 0 ? (
                <img
                  src={IconDeleteActive}
                  alt="icon-delete-active"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    activeModalPriceDelete(
                      selectedItemTable?.length === 1
                        ? "Eliminar vehiculo"
                        : "Eliminar vehiculos",
                      "delete"
                    )
                  }
                />
              ) : (
                <img src={IconDeleteDisabled} alt="icon-delete-disabled" />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
