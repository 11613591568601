import React, { createContext, useState, useContext, useEffect } from 'react';
import { get } from '../utils/apiService';
import { useAuth } from './AuthContext';

const FavoritesContext = createContext();

export const FavoritesProvider = ({ children }) => {
  const { token } = useAuth();
  const [countedLikes, setCountedLikes] = useState(0);
  const getCarsLiked = async () => {

    try {
      const result = await get('/users/get_user_likes');
      setCountedLikes(result?.total);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }

  };
  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        await getCarsLiked();
      }
    };
    fetchData();
  }, [token]);

  const addFavorite = async () => {
    setCountedLikes(countedLikes + 1);
  };

  const removeFavorite = async (crId) => {
    setCountedLikes(countedLikes - 1);
  };
  return (
    <FavoritesContext.Provider value={{ countedLikes, setCountedLikes, addFavorite, removeFavorite }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = () => {
  const context = useContext(FavoritesContext);
  if (!context) {
    throw new Error('useFavorites must be used within a FavoritesProvider');
  }
  return context;
};