import React from "react";
import { ModalFilterComponent } from "../../CarCatalog/components/ModalFilterComponent";

export const ModalFilterTableDealsComponent = ({ show, setShow }) => {
  return (
    <>
      <ModalFilterComponent show={show} setShow={setShow} isSettings={true} />
    </>
  );
};
