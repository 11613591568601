import React from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";

export const ModalAuthenticated = ({
  handleSubmitWithUser,
  user,
  textError,
  isLoading,
}) => {
  return (
    <div className="card-contact-data">
      <div className="card-contact-header">
        <p className="contact-header-title">¡Estos son tus datos!</p>
      </div>
      <div style={{ padding: "12px", height: "364px" }}>
        <form
          className="d-flex flex-column justify-content-between"
          onSubmit={handleSubmitWithUser}
          style={{
            height: "100%",
          }}
        >
          <div>
            <div className="mb-2">
              <label className="input-labels">Nombre</label>
              <p>{user?.name}</p>
            </div>
            <div className="mb-2">
              <label className="input-labels">Email</label>
              <p>{user?.email}</p>
            </div>
            <div className="mb-2">
              <label className="input-labels">Teléfono celular</label>
              <p>{user?.phone}</p>
              <p className="text-error">{textError}</p>
            </div>
          </div>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <p className="security-code-text">
              Enviaremos la cotizacion a este numero.
            </p>
            {isLoading ? (
              <Spinner
                animation="border"
                style={{
                  margin: "10px",
                  color: " #7340d8",
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <BasePurpleButtonComponent
              buttonText={isLoading ? "Enviando..." : "Continuar"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
