import React from "react";
import { useCurrency } from "../context/CurrencyContext";
import { useQuote } from "../context/QuoteContext";

export const PriceTypeMoneyComponent = ({
  colorContainerValueMoney,
  colorValueMoney,
  colorContainerPriceCar,
  colorTypeMoney,
  car,
}) => {
  const { isDollars } = useCurrency();
  const { quoteRenewal } = useQuote();
  const formatNumber = (price) => {
    // Convierte el precio a una cadena y elimina caracteres no numéricos
    const inputValue = String(price).replace(/\D/g, "");
    // Si inputValue no está vacío, formatea el número insertando puntos como separadores de miles
    if (inputValue !== "") {
      return inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
    // Si inputValue está vacío, devuelve una cadena vacía
    return "";
  };

  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{ width: "100%" }}
    >
      {quoteRenewal !== "" ? (
        <div className="container-price-renewal">
          <div className="container-text-renewal">
            <p className="text-renewal-price ms-3 pt-1">OFERTA POR RENOVAR</p>
          </div>
          <div
            className="d-flex align-items-center justify-content-center container-price-value-catalog"
            style={colorContainerValueMoney}
          >
            <p className="value-money-catalog mt-1 me-1" style={colorTypeMoney}>
              {!isDollars ? "ARS$" : "U$D"}
            </p>
            <p className="price-car-catalog me-2" style={colorValueMoney}>
              $
              {!isDollars
                ? formatNumber(car?.price)
                : formatNumber(car?.usd_price)}
            </p>
            <p className="text-renewal-discount">34.000</p>
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center container-price-value-catalog"
          style={colorContainerValueMoney}
        >
          <p className="value-money-catalog mt-1 me-1" style={colorTypeMoney}>
            {!isDollars ? "ARS$" : "U$D"}
          </p>
          <p className="price-car-catalog me-4" style={colorValueMoney}>
            $
            {!isDollars
              ? formatNumber(car?.price)
              : formatNumber(car?.usd_price)}
          </p>
        </div>
      )}
    </div>
  );
};
