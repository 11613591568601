import React from 'react';
import '../styles/components/baseSecondaryButton.css'

export default function BaseSecondaryButtonComponent({ buttonText, buttonDisabled, onClick }) {
  return (
    <button className="secondary-button" disabled={buttonDisabled} onClick={onClick}>
      {buttonText}
    </button>
  )
}

