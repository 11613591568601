import React from "react";
import { CardProfileComponent } from "./CardProfileComponent";
import { CardMyQuotesComponent } from "./CardMyQuotesComponent";
import { Tab, Tabs } from "react-bootstrap";
import { TimeLineComponentProcessSale } from "./TimeLineComponentSale";
import { TimeLineRenewalComponent } from "./TimeLineRenewalComponent";
import { TimeLineBuyComponent } from "./TimeLineBuyComponent";

export const ProcessProfileComponent = () => {
  return (
    <CardProfileComponent>
      <p className="title-card-profile mb-2">Estado de gestiones</p>
      <div className="flex-column">
        <Tabs
          defaultActiveKey="1"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="1" title="Proceso de venta">
            <CardMyQuotesComponent />
            <TimeLineComponentProcessSale />
          </Tab>
          <Tab eventKey="2" title="Proceso de renovar">
            <CardMyQuotesComponent />
            <CardMyQuotesComponent isRenovar={true} />
            <TimeLineRenewalComponent />
          </Tab>
          <Tab eventKey="3" title="Proceso de compra">
            <CardMyQuotesComponent isRenovar={true} />
            <TimeLineBuyComponent />
          </Tab>
        </Tabs>
      </div>
    </CardProfileComponent>
  );
};
