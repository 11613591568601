import React, { useState } from "react";

export const MenuProfileComponent = ({
  setShowInformationUser,
  setShowAppointmentsUser,
  setShowQuotesUser,
  setShowProcessUser,
}) => {
  const [activeOption, setActiveOption] = useState("information");

  const handleShowInformationUser = () => {
    setActiveOption("information");
    setShowInformationUser(true);
    setShowAppointmentsUser(false);
    setShowQuotesUser(false);
    setShowProcessUser(false);
  };

  const handleShowAppointmentUser = () => {
    setActiveOption("appointments");
    setShowInformationUser(false);
    setShowAppointmentsUser(true);
    setShowQuotesUser(false);
    setShowProcessUser(false);
  };

  const handleShowQuoteUser = () => {
    setActiveOption("quotes");
    setShowInformationUser(false);
    setShowAppointmentsUser(false);
    setShowQuotesUser(true);
    setShowProcessUser(false);
  };

  const handleShowProcessUser = () => {
    setActiveOption("process");
    setShowInformationUser(false);
    setShowAppointmentsUser(false);
    setShowQuotesUser(false);
    setShowProcessUser(true);
  };

  return (
    <div className="d-flex flex-column align-items-start justify-content-center card-menu-profile mb-3 mb-md-0 me-4">
      <p
        className={`text-option-menu-profile ${
          activeOption === "information" ? "active" : ""
        }`}
        onClick={handleShowInformationUser}
      >
        Datos personales
      </p>
      <p
        className={`text-option-menu-profile ${
          activeOption === "appointments" ? "active" : ""
        }`}
        onClick={handleShowAppointmentUser}
      >
        Mis turnos
      </p>
      <p
        className={`text-option-menu-profile ${
          activeOption === "quotes" ? "active" : ""
        }`}
        onClick={handleShowQuoteUser}
      >
        Mis cotizaciones
      </p>
      <p
        className={`text-option-menu-profile ${
          activeOption === "process" ? "active" : ""
        }`}
        onClick={handleShowProcessUser}
      >
        Estado de gestiones
      </p>
    </div>
  );
};
