import React from "react";

export const FooterStepperComponent = () => {
  return (
    <div
      className="d-flex w-100 justify-content-center"
      style={{ cursor: "pointer" }}
    >
      <a
        href="https://wa.me/+5491138876132"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <p className="mobile-modal-footer mt-4">
          ¿No lográs cotizar tu vehículo?
          <br />
          <strong>Consulta con un asesor y recibí tu cotzación.</strong>
        </p>
      </a>
    </div>
  );
};
