import React, { useState } from "react";
import Like from "../modules/CarCatalog/images/like-button.svg";
import Whatsapp from "../modules/CarCatalog/images/whatsapp-button.svg";
import WhatsappDiscard from "../modules/Dislikes/images/icon-whatsapp-discard.svg";
import { Col, Row } from "react-bootstrap";
import IconTrash from "../images/icon-trash.svg";
import { colors } from "../styles/colors";
import { PriceTypeMoneyComponent } from "./PriceTypeMoneyComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../utils/apiService";
import { useAuth } from "../context/AuthContext";
import SessionAlertModalComponent from "./SessionAlertModalComponent";
import AlertDeleteModalComponent from "../modules/Wishlist/components/AlertDeleteModalComponent";
import { useFavorites } from "../context/FavoritesContext";

export const CardTypeRowComponent = ({
  type,
  car,
  isEditing,
  isDiscard,
  mark,
  onDelete,
}) => {
  const { isAuthenticated } = useAuth();
  const { addFavorite, removeFavorite } = useFavorites();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const borderColorCardCars = {
    border: `2px solid ${
      isDiscard ? colors.gray.darkGrey : colors.purple.lightPurple
    }`,
  };

  const colorBrandModel = {
    color: isDiscard ? colors.gray.strokeGray : colors.purple.purple,
  };
  const colorVersion = {
    color: isDiscard ? colors.gray.strokeGray : colors.purple.darkPurple,
  };

  const colorYearKms = {
    color: isDiscard ? colors.gray.strokeGray : colors.purple.darkPurple,
  };

  const colorContainerValueMoney = {
    border: `2px solid ${isDiscard ? colors.gray.bone : colors.purple.purple}`,
    height: "34px",
  };
  const colorValueMoney = {
    color: isDiscard ? colors.gray.strokeGray : colors.purple.purple,
    fontSize: "20px",
  };
  const colorContainerPriceCar = {
    backgroundColor: isDiscard ? colors.gray.darkGrey : colors.purple.purple,
    border: `2px solid ${
      isDiscard ? colors.gray.strokeGray : colors.purple.strokePurple
    }`,
  };

  const colorTypeMoney = {
    color: isDiscard ? colors.gray.darkGrey : colors.purple.lightPurple,
  };

  const [isFadeOut, setIsFadeOut] = useState(false);

  const handleDelete = () => {
    setIsFadeOut(true);
    setTimeout(() => {
      // onDelete();
    }, 250);
  };

  document.querySelectorAll(".delete-btn").forEach((btn) => {
    btn.addEventListener("click", () => {
      btn.parentElement.classList.add("fade-out");
    });
  });

  const navigate = useNavigate();
  let location = useLocation();

  const navigateTechnicalData = (e) => {
    if (!e.target.closest(".container-button-like-type-row")) {
      navigate(`/car/${car.car_id}`, { state: { from: location.pathname } });
    }
  };
  const updateCar = async (mark_as, car_id) => {
    try {
      const result = await post("/cars/mark_car", { mark_as, car_id });
      console.log("Solicitud POST exitosa:", result);
      onDelete();
      if (mark_as === "like") {
        addFavorite();
      }
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error.message);
    }
  };
  const handleButtonClick = (mark_as, car_id, e) => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    } else {
      updateCar(mark_as, car_id);
    }
  };

  const handleDeleteConfirmation = (e) => {
    e.stopPropagation();
    setShowConfirmationModal(true);
  };

  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };

  const confirmDelete = async (e) => {
    setLoading(true);
    try {
      const result = await post("/cars/unmark_car", {
        car_id: car.car_id,
        unmark_as: mark,
      });
      console.log("Solicitud POST exitosa:", result);
      // Llama a la función onDelete para actualizar el estado después de la eliminación
      onDelete();
      if (mark === "like") {
        removeFavorite();
      }
      setLoading(false);
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error.message);
    } finally {
      // Cierra el modal después de la ejecución
      handleModalClose();
    }
  };
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div
        className={`card-cars-type-row  ${
          isFadeOut ? "fade-out" : ""
        } mb-2 mt-1`}
        onClick={navigateTechnicalData}
        style={borderColorCardCars}
      >
        <div className="d-flex align-items-center justify-content-end margins">
          {isEditing ? (
            <img
              src={IconTrash}
              alt="icon-trash"
              className="icon-trash"
              style={{ width: "24px", height: "24px" }}
              onClick={(e) => handleDeleteConfirmation(e)}
            />
          ) : null}
        </div>
        <Row>
          <Col xs={6}>
            <div
              className={`d-flex align-items-center justify-content-center   ${
                isDiscard
                  ? "background-car-type-row-discard"
                  : "background-car-type-row"
              }`}
            >
              <img
                src={car?.photos[0]}
                alt="car-catalog"
                className="car-catalog-type-row mt-2 mb-1"
              />
            </div>
          </Col>
          <Col xs={6} style={{ paddingLeft: "0px" }}>
            <div
              style={{ height: "100%" }}
              className="d-flex flex-column justify-content-between"
            >
              <div>
                <div>
                  <p
                    className="text-brand-model-type-row"
                    style={colorBrandModel}
                  >
                    {car?.brand} {car?.model}
                  </p>
                  <p className="text-version-type-row" style={colorVersion}>
                    {car?.version}
                  </p>
                </div>
                <div className="d-flex">
                  <p className="text-year-km-type-row" style={colorYearKms}>
                    {car?.year}
                  </p>
                  <p
                    className="ms-1 me-1 text-year-km-type-row"
                    style={colorYearKms}
                  >
                    |
                  </p>
                  <p className="text-year-km-type-row" style={colorYearKms}>
                    {car?.kms} kms
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-center">
          <PriceTypeMoneyComponent
            car={car}
            colorValueMoney={colorValueMoney}
            colorContainerPriceCar={colorContainerPriceCar}
            colorContainerValueMoney={colorContainerValueMoney}
            colorTypeMoney={colorTypeMoney}
          />
          <div className="container-button-like-type-row">
            {type ? (
              <img
                src={isDiscard ? WhatsappDiscard : Whatsapp}
                alt="like-button"
                className="buttons-card-cars ms-2"
                style={{ width: "40px", height: "40px" }}
              />
            ) : (
              <>
                <button className="delete-btn" onClick={handleDelete}>
                  <img
                    src={Like}
                    alt="like-button"
                    className="buttons-card-cars ms-2"
                    style={{ width: "40px", height: "40px" }}
                    onClick={(e) => {
                      handleButtonClick("like", car?.car_id, e);
                    }}
                  />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <AlertDeleteModalComponent
        isOpen={showConfirmationModal}
        onClose={handleModalClose}
        onConfirm={confirmDelete}
        loading={loading}
      />
      <SessionAlertModalComponent isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};
