import React from 'react'
import { Container } from 'react-bootstrap'
import { MdInfo } from 'react-icons/md'

export default function EmptyStateComponent({ text }) {
  return (
    <Container className='mt-4'>
      <MdInfo style={{
        color: '#e6d9f3',
        fontSize: '200px',
        width: '100%',
        textAlign: 'center'
      }} />
      <p className='title-header-catalog-likes '>
        {text}
      </p>
    </Container>
  )
}
