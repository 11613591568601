import React, { useState } from "react";
import { colors } from "./../../../styles/colors";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { Col, Row } from "react-bootstrap";
import Calendar from "../images/calendar.svg";
import SalePrice from "../images/sale-price.svg";
import ChargeCar from "../images/charge-car.svg";
import BaseWhiteButtonComponent from "../../../components/BaseWhiteButtonComponent";
import ModalMinKmsComponent from "../../../components/ModalMinKmsComponent";
import { useNavigate } from "react-router-dom";

export default function SaleComponent({ setModalSize, setSaleModalOpen }) {
  const [currentView, setCurrentView] = useState("step1");
  const [number, setNumber] = useState("");
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [isContinuing, setIsContinuing] = useState(false);

  return (
    <>
      {currentView === "step1" ? (
        <div className="mt-4">
          <TitleHomeWithIcon
            title={"¡Querés Vender!"}
            fontSize={"28px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
            isModal={true}
          />
          <p className="subtitle-pop-up mb-5 mt-2">
            Seguí estos tres simples pasos:
          </p>

          <Row className="mt-5">
            <Col xl="4" className="img-renewl-container">
              <img src={ChargeCar} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Cargá tu auto.</p>
            </Col>
            <Col xl="4" className="img-renewl-container">
              <img src={SalePrice} alt="auta-button" className="img-renewl" />
              <p className="icon-description">Recibí tu cotización</p>
            </Col>
            <Col xl="4" className="img-renewl-container">
              <img src={Calendar} alt="auta-button" className="img-renewl" />
              <p className="icon-description">
                Agendá turno para <br /> inspección y entrega.
              </p>
            </Col>
          </Row>
          <div className="button-container">
            <BaseWhiteButtonComponent
              buttonText={"Empezar"}
              onClick={() => navigate("/sale")}
            />
          </div>
        </div>
      ) : null}
      {showModal && (
        <ModalMinKmsComponent
          show={showModal}
          kms={number}
          setShow={setShowModal}
          setIsContinuing={setIsContinuing}
          setCurrentView={setCurrentView}
        />
      )}
    </>
  );
}
