// axiosInstance.js
import axios from 'axios';

const apiUrl = 'https://backend-web-dot-admin-dev-355313.rj.r.appspot.com/';

const instance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
});

// Interceptor para agregar el token de acceso a cada solicitud
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor para manejar la renovación del token en caso de expiración
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Si el error status es 401 y no hay originalRequest._retry,
    // significa que el token ha expirado y necesitamos renovarlo
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post(`${apiUrl}tokens/refresh_token?refresh_token=${refreshToken}`);
        const newAccessToken = response.data.access_token;

        localStorage.setItem('accessToken', newAccessToken);
        // Reintentar la solicitud original con el nuevo token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return instance(originalRequest);
      } catch (renewError) {
        // Manejar error al renovar el token o redirigir a iniciar sesión
        console.error('Error al renovar el token:', renewError);
        throw error;
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
