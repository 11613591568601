import React, { useEffect, useState } from "react";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import customIcon from "../images/icon-location.png";

export const LocationComponent = () => {
  let isPageWide = useMediaQuery("(min-width: 770px)");

  const mapContainerStyle = {
    width: isPageWide ? "80%" : "100%",
    height: isPageWide ? "450px" : "240px",
    borderRadius: "12px",
    border: `4px solid ${colors.purple.strokePurple}`,
  };

  const center = { lat: -34.638211, lng: -58.396341 };

  const [map, setMap] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY_GOOGLE_MAPS,
  });
  useEffect(() => {
    if (isLoaded && !loadError) {
      // Verifica si 'maps' está definido antes de acceder a sus propiedades
      if (window.google && window.google.maps) {
        setMap(window.google.maps);
      } else {
        console.error("La API de Google Maps no se ha cargado correctamente.");
      }
    }
  }, [isLoaded, loadError]);

  return (
    <div className="containerLocation d-flex flex-column align-items-center justify-content-center mb-5">
      <div className="mb-4">
        <TitleHomeWithIcon
          title={"¿Dónde nos podés encontrar?"}
          fontSize={"24px"}
          colorText={colors.white.white}
          colorIcon={colors.white.white}
        />
      </div>
      <Container>
        <Row>
          <Col className="d-flex flex-column align-items-center justify-content-center mb-5">
            {isLoaded && !loadError && map && (
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={20}
                center={center}
              >
                <Marker
                  position={center}
                  icon={{
                    url: customIcon,
                    scaledSize: new map.Size(80, 80),
                  }}
                />
              </GoogleMap>
            )}
            {(loadError || !isLoaded) && (
              <div>
                Error al cargar el mapa. Verifica tu conexión o la clave de API.
              </div>
            )}
            <div>
              <p
                className="footerMaps"
                style={{
                  padding: isPageWide
                    ? "10px 44px 10px 44px"
                    : "10px 38px 10px 38px",
                  fontSize: isPageWide ? "16px" : "10px",
                }}
              >
                Manuel García 250, CABA (1248)
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
