import React, { useEffect, useRef, useState } from "react";
import "../../styles/contactDataForm.css";
import { TitleHomeWithIcon } from "../../../Home/components/TitleHomeWithIcon";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../../firebase/firebase";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { enviarDatosAuto } from "../../../Home/services/fetchDataCar";
import { useAuth } from "../../../../context/AuthContext";
import fetchDataPhoneNumberExists from "../../../../utils/validatePhoneNumberExists";
import { post } from "../../../../utils/apiService";
import { ModalFIlter } from "../Modals/ModalFIlter";
import { ModalVerificationPhone } from "../Modals/ModalVerificationPhone";
import { ModalNoAuthenticated } from "../Modals/ModalNoAuthenticated";
import { ModalAuthenticated } from "../Modals/ModalAuthenticated";
import { colors } from "../../../../styles/colors";
import SuccessComponent from "../../SuccessComponent";
import { useQuote } from "../../../../context/QuoteContext";

export default function ContactDataFormRenewalComponent({
  dataCar,
  kms,
  isGnc,
}) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState();
  const [isDisabledB, setIsDisabledB] = useState(false);
  const [currentView, setCurrentView] = useState("step1");
  const [selectedButton, setSelectedButton] = useState(1);
  const [countryCode, setCountryCode] = useState(""); // Estado para el código de país
  const [phoneNumber, setPhoneNumber] = useState(""); // Estado para el número de teléfono
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null); // Estado para el resultado de la confirmación
  const [verificationCodes, setVerificationCodes] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]); // Estado para el código de verificación
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]; // Referencias a los inputs
  const [textError, setTextError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const { isAuthenticated, user, setAuthenticated } = useAuth();
  const { addQuote } = useQuote();
  const [loginAlert, setLoginAlert] = useState(false);
  const [showloginView, setShowLoginView] = useState(false);

  const handleStartClick = () => {
    if (currentView === "step1") {
      setCurrentView("step2");
    } else if (currentView === "step2") {
      setCurrentView("step3");
    } else if (currentView === "step3") {
      setCurrentView("step4");
    }
  };

  useEffect(() => {
    handleButtonClick(selectedButton);
  }, [selectedButton]);

  const handleButtonClick = (buttonNumber) => {
    setSelectedButton(buttonNumber);
  };
  const goTocatalog = () => {
    addQuote();
    navigate("/cars");
  };

  useEffect(() => {
    if (!isAuthenticated) {
      const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });
      setRecaptchaVerifier(verifier);
    }
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    setIsDisabledB(true);
    e.preventDefault();

    try {
      const fullPhoneNumber = `+549${countryCode}${phoneNumber}`;
      // Llamada a la función fetchDataPhoneNumberExists para verificar si el número es válido
      if (!loginAlert) {
        const isPhoneNumberValid = await fetchDataPhoneNumberExists(
          fullPhoneNumber
        );
        if (isPhoneNumberValid) {
          setLoginAlert(true);
          // Si el número es válido, muestra una alerta y detén el flujo
          setTextError(
            "Número de teléfono ya pertenece a una cuenta existente"
          );
          return;
        }
      }
      // Continuar con el proceso de autenticación si el número es válido
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        fullPhoneNumber,
        recaptchaVerifier
      );
      setConfirmationResult(confirmationResult);
      handleStartClick();
    } catch (error) {
      // Manejar errores como en tu implementación original
      switch (error.code) {
        case "auth/invalid-phone-number":
          // El número de teléfono es inválido
          setTextError("El número de teléfono es inválido");
          break;
        case "auth/quota-exceeded":
        case "auth/too-many-requests":
          // Se ha superado la cuota de SMS para este proyecto
          setTextError("Se ha superado la cuota de SMS");
          break;
        case "auth/captcha-check-failed":
          // Error de reCAPTCHA
          setTextError("Error de reCAPTCHA");
          break;
        default:
          // Otros errores
          console.error("Error al verificar el número de teléfono:", error);
          setTextError("Error al verificar el número de teléfono");
          break;
      }
    } finally {
      // Asegúrate de manejar la carga y la desactivación de los botones en la sección finally
      setIsLoading(false);
      setIsDisabledB(false);
    }
  };
  const handleSubmitWithUser = async (e) => {
    e.preventDefault();
    setIsDisabledB(true);
    setIsLoading(true);
    try {
      await enviarDatosAuto(
        dataCar?.brand?.name,
        dataCar?.brand?.id,
        dataCar?.model?.name,
        dataCar?.model?.id,
        dataCar?.version?.name,
        dataCar?.version?.id,
        dataCar?.year?.name,
        isGnc,
        kms,
        user?.phone,
        user?.name
      );
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setIsDisabledB(false);
      setCurrentView("step3");
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    // Verifica si el valor ingresado es un número o una cadena vacía y está en el rango de 0 a 9
    if (/^[0-9]$|^$/.test(value) && index >= 0 && index <= 5) {
      const newVerificationCodes = [...verificationCodes];
      newVerificationCodes[index] = value;
      setVerificationCodes(newVerificationCodes);

      // Mueve el foco al siguiente input solo si se ha ingresado un dígito
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const authenticateUser = async (enteredCode) => {
    const credential = PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      enteredCode
    );
    const userCredential = await signInWithCredential(auth, credential);
    const user = userCredential.user;
    console.log("Usuario autenticado:", user);
  };

  const sendData = async (phone, name) => {
    try {
      const result = await post("/users/create_user", { name, email, phone });
      console.log("Solicitud POST exitosa:", result);
      setAuthenticated(true);
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error.message);
    }
  };

  const handleError = (error) => {
    switch (error.code) {
      case "auth/invalid-verification-code":
        setTextError("El código de verificación es inválido");
        break;
      case "auth/code-expired":
        setTextError("El código de verificación ha caducado");
        break;
      case "auth/credential-already-in-use":
        setTextError("La credencial ya está en uso por otro usuario");
        break;
      default:
        console.error("Error al verificar el código de verificación:", error);
        setTextError("Error al verificar el código.");
        break;
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setIsDisabledB(true);
    setIsLoading(true);

    const enteredCode = verificationCodes.join("");

    try {
      await authenticateUser(enteredCode);
      if (!loginAlert) {
        await sendData(`+549${countryCode}${phoneNumber}`, name);
      }
      await enviarDatosAuto(
        dataCar?.brand?.name,
        dataCar?.brand?.id,
        dataCar?.model?.name,
        dataCar?.model?.id,
        dataCar?.version?.name,
        dataCar?.version?.id,
        dataCar?.year?.name,
        isGnc,
        kms,
        `+549${countryCode}${phoneNumber}`,
        name
      );
      handleStartClick();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setIsDisabledB(false);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value)); // Verificar el formato del correo electrónico
  };
  const isFormValid =
    countryCode !== "" &&
    phoneNumber !== "" &&
    name !== "" &&
    email !== "" &&
    isEmailValid;
  return (
    <>
      {currentView === "step3" ? (
        <TitleHomeWithIcon
          title={"Tu cotización fue\n realizada con éxito."}
          fontSize={"28px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      ) : (
        <TitleHomeWithIcon
          title={"¡Ya casi terminamos!"}
          fontSize={"28px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      )}
      {currentView === "step1" ? (
        <>
          {isAuthenticated ? (
            <ModalAuthenticated
              handleSubmitWithUser={handleSubmitWithUser}
              user={user}
              textError={textError}
              isLoading={isLoading}
            />
          ) : (
            <ModalNoAuthenticated
              handleSubmit={handleSubmit}
              name={name}
              email={email}
              handleEmailChange={handleEmailChange}
              isEmailValid={isEmailValid}
              countryCode={countryCode}
              phoneNumber={phoneNumber}
              textError={textError}
              loginAlert={loginAlert}
              showloginView={showloginView}
              isFormValid={isFormValid}
              isLoading={isLoading}
              setName={setName}
              setCountryCode={setCountryCode}
              setPhoneNumber={setPhoneNumber}
              setShowLoginView={setShowLoginView}
              setTextError={setTextError}
            />
          )}
        </>
      ) : currentView === "step2" ? (
        <ModalVerificationPhone
          handleVerification={handleVerification}
          countryCode={countryCode}
          phoneNumber={phoneNumber}
          inputRefs={inputRefs}
          verificationCodes={verificationCodes}
          isLoading={isLoading}
          textError={textError}
          isDisabledB={isDisabledB}
          handleChange={handleChange}
        />
      ) : currentView === "step3" ? (
        <SuccessComponent handleClick={handleStartClick} isRenewal={true} />
      ) : currentView === "step4" ? (
        <ModalFIlter goTocatalog={goTocatalog} />
      ) : null}
    </>
  );
}
