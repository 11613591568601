import React from "react";
import { colors } from "../../../styles/colors";

export const PeriodsBottomComponent = ({
  textOne,
  textTwo,
  completed,
  width,
}) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center container-line-bottom">
      <div
        className="circle-timeline"
        style={{
          marginTop: "-70px",
          backgroundColor: completed
            ? colors.purple.darkPurple
            : colors.gray.disabled,
        }}
      ></div>
      <div
        className="line-top-timeline"
        style={{
          backgroundColor: completed
            ? colors.purple.darkPurple
            : colors.gray.disabled,
        }}
      ></div>
      <div
        className="container-text-timeline"
        style={{ width: width ? "106px" : "" }}
      >
        <p
          className="text-timeline"
          style={{
            color: completed ? colors.purple.purple : colors.gray.coolGray,
          }}
        >
          {textOne} <br /> {textTwo}
        </p>
      </div>
    </div>
  );
};
