import React from "react";
import BottonGridTypeCarComponent from "../../../../components/BottonGridTypeCarComponent";
import BaseSecondaryButtonComponent from "../../../../components/BaseSecondaryButtonComponent";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";

export const ModalFIlter = ({ goTocatalog }) => {
  return (
    <div className="card-contact-data" style={{ width: "314px" }}>
      <div className="card-contact-header">
        <p className="contact-header-title">Filtros de búsqueda</p>
      </div>
      <div
        style={{
          height: "360px",
          padding: "6px",
        }}
        className="d-flex flex-column justify-content-between"
      >
        <div className="mt-2">
          <p className="input-filter-text">¿Entre qué años buscás?</p>
          <div className="d-flex align-items-center mb-2">
            <input
              type="text"
              className="base-input"
              placeholder="Ingresar año"
            />
            <span className="divider-range" />
            <input
              type="text"
              className="base-input"
              placeholder="Ingresar año"
            />
          </div>
          <p className="input-filter-text">¿Qué tipo de auto buscás?</p>
          <div className="mb-2">
            <BottonGridTypeCarComponent />
          </div>
          <p className="input-filter-text">¿Entre qué rango de precio?</p>
          <div className="d-flex align-items-center mb-2">
            <input
              type="text"
              className="base-input"
              placeholder="Ingresar monto"
            />
            <span className="divider-range" />
            <input
              type="text"
              className="base-input"
              placeholder="Ingresar monto"
            />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <BaseSecondaryButtonComponent
            buttonText={"Continuar sin filtrar"}
            onClick={goTocatalog}
          />
          <BasePurpleButtonComponent
            buttonText={"Filtrar"}
            onClick={goTocatalog}
          />
        </div>
      </div>
    </div>
  );
};
