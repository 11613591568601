import React, { useState } from "react";
import ButtonWithIconComponent from "../../../components/ButtonWithIconComponent";
import { colors } from "../../../styles/colors";
import IconWhatsapp from "../images/icon-whatsapp.svg";
import IconCheck from "../images/icon-check.svg";
import IconLike from "../images/icon-like.svg";
import DisabledLike from "../images/DisabledLike.svg";
import IconChange from "../images/icon-change.svg";
import { useAuth } from "../../../context/AuthContext";
import SessionAlertModalComponent from "../../../components/SessionAlertModalComponent";
import { useFavorites } from "../../../context/FavoritesContext";
import { post } from "../../../utils/apiService";

const CardDataTechnicalComponent = ({ isPageWide, carData, isCarLiked, setIsCarLiked }) => {
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addFavorite } = useFavorites()

  const updateCar = async (mark_as, car_id) => {
    try {
      const result = await post('/cars/mark_car', { mark_as, car_id });
      console.log('Solicitud POST exitosa:', result);
      setIsCarLiked(true)
      if (mark_as === "like") {
        addFavorite()
      }
    } catch (error) {
      console.error('Error al realizar la solicitud POST:', error.message);
    }
  };

  const handleButtonClick = (mark_as, car_id) => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    } else {
      updateCar(mark_as, car_id);
    }
  };
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <div className="card-technical-data d-flex flex-column mt-4 mt-md-3 ms-md-4 mb-4 mb-md-0">
        <div className="d-flex flex-column">
          <p className="brand-model-car-technical">{carData?.brand} {carData?.model}</p>
          <p className="version-car-technical">{carData?.version}</p>
        </div>
        <div className="d-flex align-items-center mt-2 mb-4">
          <p className="year-km-car-technical">{carData?.year}</p>
          <p className="ms-1 me-1 divider-km-year mb-1">|</p>
          <p className="year-km-car-technical">{carData?.kms} km</p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-center mt-1">
            <p className="type-money-car-technical mt-2">U$D</p>
            <p className="price-car-technical">{carData?.usd_price}</p>
          </div>
          {isCarLiked ?
            <img src={DisabledLike} alt="icon-like-disabled" />
            :
            <img src={IconLike} alt="icon-like"
              style={{ cursor: 'pointer' }}
              onClick={() => handleButtonClick("like", carData?.car_id)}
            />}
        </div>
        <div className="divider-all mb-3 mt-2"></div>
        <div className="d-flex flex-column">
          <div className="mb-3">
            <ButtonWithIconComponent
              backgroundColor={colors.green.lightGreen}
              borderColor={colors.green.darkGreen}
              width={isPageWide ? "256px" : "100%"}
              height={"40px"}
              text={"Consultanos via Whatsapp"}
              icon={IconWhatsapp}
              sizeContainerIcon={"40px"}
              colorText={colors.white.white}
            />
          </div>
          <div className="mb-3">
            <ButtonWithIconComponent
              backgroundColor={colors.purple.purple}
              borderColor={colors.purple.strokePurple}
              width={isPageWide ? "256px" : "100%"}
              height={"40px"}
              text={"Sé el primero en reservarlo"}
              icon={IconCheck}
              sizeContainerIcon={"40px"}
              colorText={colors.white.white}
            />
          </div>
          <ButtonWithIconComponent
            backgroundColor={colors.white.white}
            borderColor={colors.purple.purple}
            width={isPageWide ? "256px" : "100%"}
            height={"40px"}
            text={"Renová tu usado por uno nuevo"}
            icon={IconChange}
            sizeContainerIcon={"40px"}
            colorText={colors.purple.purple}
            fontSize={"13px"}
          />
        </div>
      </div>
      <SessionAlertModalComponent isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default CardDataTechnicalComponent;
