import { useEffect } from 'react';
import { useAuth } from '../context/AuthContext';


export const useRequireAuth = (requireAuth, onAuthFailure) => {
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const checkAuthentication = async () => {
      if (requireAuth && !isAuthenticated) {
        onAuthFailure();
      }
    };

    checkAuthentication();
  }, [requireAuth, isAuthenticated, onAuthFailure]);

  return { isAuthenticated };
};

const RequireAuth = ({ children, requireAuth, onAuthFailure }) => {
  const { isAuthenticated } = useRequireAuth(requireAuth, onAuthFailure);

  return isAuthenticated ? children : null;
};

export default RequireAuth;