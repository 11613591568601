import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AutaButton from "../images/button-auta.png";
import "../styles/components/footerStyle.css";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";

export const FooterComponent = () => {
  let isPageWide = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  const styleNavigate = {
    cursor: "pointer",
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center flex-column"
      style={{ marginTop: isPageWide ? "-50px" : "-10px" }}
    >
      <Row
        className="d-flex align-items-center justify-content-center mb-2"
        style={{ width: "100%" }}
      >
        <Col lg={2} md={12}>
          <p
            style={styleNavigate}
            className="text-footer-options mb-2"
            onClick={() => navigate("/renewal")}
          >
            Quiero Renovar
          </p>
        </Col>
        <Col lg={2} md={12}>
          <p
            style={styleNavigate}
            className="text-footer-options mb-2"
            onClick={() => navigate("/buy")}
          >
            Quiero Comprar
          </p>
        </Col>
        <Col lg={2} md={12}>
          <p
            style={styleNavigate}
            className="text-footer-options mb-2"
            onClick={() => navigate("/sale")}
          >
            Quiero Vender
          </p>
        </Col>
        <Col lg={2} md={12}>
          <p className="text-footer-options mb-2">Preguntas Frecuentes</p>
        </Col>
        <Col lg={1} md={12}>
          <p className="text-footer-options mb-2">Contacto</p>
        </Col>
      </Row>
      <Row
        className="d-flex align-items-center justify-content-center mt-3 mb-2"
        style={{ width: "100%" }}
      >
        <Col lg={3}>
          <p className="text-footer-copyright-terminos-politicas">
            2024 Kardur S.A. - Manuel García 250, CABA (1248) - Tel: 011
            15-5263-8870
          </p>
        </Col>
        <Col lg={2}>
          <p className="text-footer-copyright-terminos-politicas mb-2">
            Términos y Condiciones
          </p>
        </Col>
        <Col lg={2}>
          <p className="text-footer-copyright-terminos-politicas mb-2">
            Políticas de Privacidad
          </p>
        </Col>
      </Row>
      <Row>
        <img
          src={AutaButton}
          alt="auta-button"
          className="auta-button mt-3 mb-4 d-none d-md-block"
        />
        <img
          src={AutaButton}
          alt="auta-button"
          className="auta-button mt-3 mb-4 d-md-none"
          style={{ width: "75px", height: "50px" }}
        />
      </Row>
    </Container>
  );
};
