import React, { useState } from "react";
import "../QuoteStepper/styles/derivatorPages.css";
import { HeaderComponent } from "../../components/HeaderComponent";
import ButtonGroupStepperSaleComponent from "./components/Mobile/ButtonGroupStepperSaleComponent";
import { CotizadorDesktopComponent } from "./components/Desktop/CotizadorDesktopComponent";
import { useMediaQuery } from "../../hooks/useMediaQuery";

export default function SalePage() {
  const [isTitleShow, setIsTitleShow] = useState(true);
  const [expanded, setExpanded] = useState(false);
  let breakPointMobile = useMediaQuery("(min-width: 430px)");

  return (
    <div style={{ height: breakPointMobile ? "94vh" : "95vh" }}>
      <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
      <div className={`cotizador-desktop ${expanded ? "blur-background" : ""}`}>
        <CotizadorDesktopComponent />
      </div>
      <div className={`cotizador-mobile ${expanded ? "blur-background" : ""}`}>
        <div style={{ height: "100%" }}>
          <ButtonGroupStepperSaleComponent
            isTitleShow={isTitleShow}
            setIsTitleShow={setIsTitleShow}
          />
        </div>
      </div>
    </div>
  );
}
