import React from "react";
import IconLikeGrey from "../images/icon-like-grey.svg";
import IconDislikeGrey from "../images/icon-dislike-grey.svg";
import IconLike from "../images/icon-like.svg";
import IconDislike from "../images/icon-dislike.svg";
import Switch from "react-switch";
import { colors } from "../styles/colors";
import { useMediaQuery } from "../hooks/useMediaQuery";

export const SwitchLikeDiscardComponent = ({
  checked,
  handleChange,
  setCheckedLikeDiscard,
}) => {
  let isPageWide = useMediaQuery("(min-width: 760px)");

  const styleColorTextFavorites = {
    color: !checked ? colors.purple.purple : colors.purple.lightPurple,
    fontSize: isPageWide ? "18px" : "12px",
    fontWeight: 700,
  };

  const styleColorTextDiscard = {
    color: checked ? colors.red.red : colors.purple.lightPurple,
    fontWeight: 700,
    fontSize: isPageWide ? "18px" : "12px",
  };

  const handleSwitch = () => {
    setCheckedLikeDiscard(!checked);
  };

  return (
    <div className="d-flex align-items-center">
      <div
        className="d-flex align-items-center justify-content-center"
        onClick={handleSwitch}
        style={{ cursor: "pointer" }}
      >
        <img
          src={checked ? IconLikeGrey : IconLike}
          alt="IconLikeGrey"
          width={"30px"}
          height={"30px"}
          className="me-0 me-md-1 mb-0 md-md-1"
        />
        {isPageWide ? <p style={styleColorTextFavorites}>Favoritos</p> : null}
      </div>
      <div className="ms-2 me-2 mt-1  d-flex align-items-center justify-content-center">
        <label htmlFor="material-switch">
          <Switch
            checked={checked}
            onChange={handleChange}
            offColor={colors.purple.lightPurple}
            offHandleColor={colors.purple.purple}
            onColor={colors.purple.lightPurple}
            onHandleColor={colors.purple.purple}
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            height={10}
            width={24}
            boxShadow="0px 0px 0px"
            activeBoxShadow="0px 0px 0px 0px"
            className="react-switch"
            id="material-switch"
          />
        </label>
      </div>
      <div
        className="d-flex align-items-center justify-content-center"
        onClick={handleSwitch}
        style={{ cursor: "pointer" }}
      >
        {isPageWide ? <p style={styleColorTextDiscard}>Descartes</p> : null}
        <img
          src={!checked ? IconDislikeGrey : IconDislike}
          alt="icon-dislike"
          width={"30px"}
          height={"30px"}
          className="ms-0 ms-md-1 mb-0 md-md-1"
        />
      </div>
    </div>
  );
};
