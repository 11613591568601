import React, { useEffect } from "react";
import { HomeComingSoonComponent } from "./components/HomeComingSoonComponent";
import { useSpinner } from "../../context/spinnerContext";
import SpinnerComponent from "../../components/SpinnerComponent";

export const ComingSoonPage = () => {
  const { showLoading, hideLoading, showSpinner } = useSpinner();
  useEffect(() => {
    showLoading()
    setTimeout(() => {
      hideLoading()
    }, 1000);

  }, [])
  return (
    <>
      {showSpinner ? (
        <SpinnerComponent />
      ) : (
        <HomeComingSoonComponent />)}
    </>
  );
};
