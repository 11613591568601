import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LogoAutaHeader from "../images/logo-auta-header.png";
import "../styles/components/headerStyle.css";
import { useNavigate } from "react-router-dom";
import IconLikeCart from "../modules/CarCatalog/images/icon-cart-likes.svg";
import IconMenu from "../images/icon-menu.svg";
import IconUserDark from "../images/icon-user-dark.svg";
import IconCloseMenu from "../images/icon-close-menu.svg";
import HeaderSessionButtonComponent from "./HeaderSessionButtonComponent";
import { useAuth } from "../context/AuthContext";
import BaseModalComponet from "./BaseModalComponet";
import RegistrationModalComponent from "../modules/Login/components/RegistrationModalComponent";
import LoginModalComponent from "../modules/Login/components/LoginModalComponent";
import IconUser from "../images/icon-user.svg";
import IconArrowDown from "../images/icon-arrow-down.svg";
import IconLogout from "../images/icon-logout.svg";
import IconSettings from "../images/icon-settings.svg";
import { useFavorites } from "../context/FavoritesContext";

export const HeaderComponent = ({ expanded, setExpanded }) => {
  const navigate = useNavigate();
  const { isAuthenticated, logout, user } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [loading, setLoading] = useState(true);
  const { countedLikes } = useFavorites();

  const toggleLoginModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const logOutAndRedirect = () => {
    logout();
    navigate("/home");
  };

  const showDropDownLogout = () => {
    setShowDropDown(!showDropDown);
  };
  useEffect(() => {
    if (countedLikes || countedLikes === 0) {
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Container
        style={{
          position: "sticky",
          top: "0",
          zIndex: "1",
        }}
      >
        <div className="container-all">
          <Navbar expand="md" className="header">
            <Navbar.Brand
              onClick={() => navigate("/home")}
              style={{ cursor: "pointer", padding: "0px !important" }}
            >
              <img
                src={LogoAutaHeader}
                alt="logo-auta"
                className="logo-auta-header ms-3 ms-md-4"
              />
            </Navbar.Brand>
            <div className="d-flex align-items-center justify-content-center">
              {isAuthenticated ? (
                <Nav.Link
                  className="text-header  d-md-none"
                  onClick={() => navigate("/wishlist")}
                >
                  <div className="d-flex">
                    <img src={IconLikeCart} alt="icon-like-cart" />
                    <div className="container-cart d-flex align-items-center justify-content-center">
                      <p className="number-cart">
                        {loading ? "..." : countedLikes}
                      </p>
                    </div>
                  </div>
                </Nav.Link>
              ) : null}
              <Nav.Link
                className="d-flex align-items-center justify-content-center d-md-none"
                onClick={() => navigate("/deals")}
              >
                <img src={IconSettings} alt="icon-settings" />
              </Nav.Link>
              <Navbar.Toggle onClick={handleToggle} className="icon-header">
                {expanded ? (
                  <img src={IconCloseMenu} alt="icon-close-menu" />
                ) : (
                  <img src={IconMenu} alt="icon-menu" />
                )}
              </Navbar.Toggle>
            </div>
            <Navbar.Collapse className="justify-content-end mt-3">
              <Nav.Link className="me-4" onClick={() => navigate("/sale")}>
                <p className="text-header">Vender</p>
              </Nav.Link>
              <div className="d-flex align-items-center justify-content-center d-md-none">
                <div className="divider-header-menu"></div>
              </div>
              <Nav.Link
                className="me-4 textHeader"
                onClick={() => navigate("/cars")}
              >
                <p className="text-header">Catálogo</p>
              </Nav.Link>
              <div className="d-flex align-items-center justify-content-center d-md-none">
                <div className="divider-header-menu"></div>
              </div>
              {isAuthenticated ? (
                <Nav.Link
                  className="me-4 text-header d-none d-md-block"
                  onClick={() => navigate("/wishlist")}
                >
                  <div className="d-flex">
                    <img src={IconLikeCart} alt="icon-like-cart" />
                    <div className="container-cart d-flex align-items-center justify-content-center">
                      <p className="number-cart">
                        {loading ? "..." : countedLikes}
                      </p>
                    </div>
                  </div>
                </Nav.Link>
              ) : null}
              <Nav.Link className="me-4 d-flex justify-content-center ">
                {isAuthenticated ? (
                  <div
                    onClick={showDropDownLogout}
                    className={`d-flex flex-column align-items-center ${
                      showDropDown ? "dropdown-logout" : ""
                    }`}
                    style={{ marginBottom: showDropDown ? "-80px" : "" }}
                  >
                    <div className="dropdown-user d-flex align-items-center justify-content-between">
                      <img
                        src={IconUser}
                        alt="icon-user"
                        className="icon-user"
                      />
                      <p className="text-user-name ms-1 me-1">{user?.name}</p>
                      <img src={IconArrowDown} alt="arrow-down" />
                    </div>
                    {showDropDown ? (
                      <>
                        <div
                          className="d-flex align-items-center justify-content-center mt-3"
                          onClick={() => navigate("/profile")}
                        >
                          <img
                            src={IconUserDark}
                            alt="icon-logout"
                            className="me-1"
                          />
                          <p className="text-logout">Perfil y Gestiones</p>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-center mt-3"
                          onClick={logOutAndRedirect}
                        >
                          <img src={IconLogout} alt="icon-logout" />
                          <p className="text-logout">Logout</p>
                        </div>
                      </>
                    ) : null}
                  </div>
                ) : (
                  <HeaderSessionButtonComponent
                    buttonText={"Sign up / Login"}
                    onClick={toggleLoginModal}
                  />
                )}
              </Nav.Link>
              <Nav.Link
                className="d-flex align-items-center justify-content-center me-3 d-none d-md-block"
                onClick={() => navigate("/deals")}
              >
                <img src={IconSettings} alt="icon-settings" />
              </Nav.Link>
              <div
                className="d-flex align-items-center justify-content-center d-md-none"
                style={{ height: "10px" }}
              ></div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Container>
      <BaseModalComponet
        isOpen={isModalOpen}
        onClose={toggleLoginModal}
        size={"xs"}
      >
        {showLogin && user ? (
          <LoginModalComponent
            setShowLogin={setShowLogin}
            user={user}
            toggleLoginModal={toggleLoginModal}
          />
        ) : (
          <RegistrationModalComponent
            setShowLogin={setShowLogin}
            toggleLoginModal={toggleLoginModal}
          />
        )}
      </BaseModalComponet>
    </>
  );
};
