import React from "react";
import { CardProfileComponent } from "./CardProfileComponent";
import { Col, Row } from "react-bootstrap";
import IconCalendar from "../images/icon-calendar.svg";
import IconWatch from "../images/icon-watch.svg";
import IconEdit from "../images/icon-edit.svg";
import IconClose from "../images/icon-close.svg";

export const AppointmentsProfileComponent = () => {
  return (
    <CardProfileComponent>
      <p className="title-card-profile">Mis turnos</p>
      <div className="container-card-appointments-profile mt-3">
        <Row>
          <Col md={7}>
            <p className="text-appointment-profile ms-2 ms-md-4">Servicio</p>
          </Col>
          <Col
            md={5}
            className="d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center justify-content-end me-md-4">
              <img
                src={IconCalendar}
                alt="icon-calendar"
                className="me-md-2 ms-2 ms-md-0"
              />
              <p className="text-appointment-profile">01/01/2021</p>
            </div>
            <div className="d-flex align-items-center justify-content-end me-md-4">
              <img src={IconWatch} alt="icon-watch" className="me-1" />
              <p className="text-appointment-profile">00:00</p>
            </div>
            <div className="ms-lg-4 d-flex align-items-center ">
              <img
                src={IconEdit}
                alt="icon-edit"
                className="icon-table-profile"
              />
              <img
                src={IconClose}
                alt="icon-close"
                className="icon-table-profile"
              />
            </div>
          </Col>
        </Row>
        <div className="divider-appointment mt-1 mb-1"></div>
        <Row>
          <Col md={7}>
            <p className="text-appointment-profile ms-2 ms-md-4">Servicio</p>
          </Col>
          <Col
            md={5}
            className="d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center justify-content-end me-md-4">
              <img
                src={IconCalendar}
                alt="icon-calendar"
                className="me-md-2 ms-2 ms-md-0"
              />
              <p className="text-appointment-profile">01/01/2021</p>
            </div>
            <div className="d-flex align-items-center justify-content-end me-md-4">
              <img src={IconWatch} alt="icon-watch" className="me-1" />
              <p className="text-appointment-profile">00:00</p>
            </div>
            <div className="ms-lg-4 d-flex align-items-center ">
              <img
                src={IconEdit}
                alt="icon-edit"
                className="icon-table-profile"
              />
              <img
                src={IconClose}
                alt="icon-close"
                className="icon-table-profile"
              />
            </div>
          </Col>
        </Row>
        <div className="divider-appointment mt-1 mb-1"></div>
        <Row>
          <Col md={7}>
            <p className="text-appointment-profile ms-2 ms-md-4">Servicio</p>
          </Col>
          <Col
            md={5}
            className="d-flex align-items-center justify-content-between"
          >
            <div className="d-flex align-items-center justify-content-end me-md-4">
              <img
                src={IconCalendar}
                alt="icon-calendar"
                className="me-md-2 ms-2 ms-md-0"
              />
              <p className="text-appointment-profile">01/01/2021</p>
            </div>
            <div className="d-flex align-items-center justify-content-end me-md-4">
              <img src={IconWatch} alt="icon-watch" className="me-1" />
              <p className="text-appointment-profile">00:00</p>
            </div>
            <div className="ms-lg-4 d-flex align-items-center">
              <img
                src={IconEdit}
                alt="icon-edit"
                className="icon-table-profile"
              />
              <img
                src={IconClose}
                alt="icon-close"
                className="icon-table-profile"
              />
            </div>
          </Col>
        </Row>
      </div>
    </CardProfileComponent>
  );
};
