import React from "react";
import { colors } from "../../../../styles/colors";

export const ButtonGroupComponent = ({
  selectedButton,
  selectedOptions,
  handleButtonClick,
  number,
  isGnc,
}) => {
  return (
    <div className="button-group-wrapper-stepper">
      <div className="button-group-stepper">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <button
            className={`button-stepper ${
              selectedButton === 1 ? "selected" : ""
            }`}
            onClick={() => {
              handleButtonClick(1);
            }}
            style={{
              backgroundColor:
                selectedButton !== 1 && selectedOptions?.year?.name
                  ? colors.purple.strokeDarkPurple
                  : "",
              color: colors.white.white,
              width: "100%",
              height: "100%",
            }}
          >
            Año
          </button>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            background: `linear-gradient(to right, ${colors.purple.strokeDarkPurple} 50%, transparent 50%)`,
            width: "100%",
          }}
        >
          <button
            className={`button-stepper ${
              selectedButton === 2 ? "selected" : ""
            }`}
            onClick={() => {
              handleButtonClick(2);
            }}
            disabled={!selectedOptions?.year?.name}
            style={{
              backgroundColor:
                selectedOptions?.year?.name &&
                selectedOptions?.brand?.name &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.purple.strokeDarkPurple
                  : "",
              color:
                selectedButton !== 2 &&
                selectedButton !== 1 &&
                selectedOptions?.brand?.name
                  ? colors.white.white
                  : "",
              height: "100%",
            }}
          >
            Marca
          </button>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            background: `linear-gradient(to right, ${colors.purple.strokeDarkPurple} 50%, transparent 50%)`,
            width: "100%",
          }}
        >
          <button
            className={`button-stepper ${
              selectedButton === 3 ? "selected" : ""
            }`}
            onClick={() => {
              handleButtonClick(3);
            }}
            disabled={!selectedOptions?.brand?.name}
            style={{
              backgroundColor:
                selectedOptions?.model?.name &&
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.purple.strokeDarkPurple
                  : "",
              color:
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.white.white
                  : "",
              height: "100%",
            }}
          >
            Modelo
          </button>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            background: `linear-gradient(to right, ${colors.purple.strokeDarkPurple} 50%, transparent 50%)`,
            width: "100%",
          }}
        >
          <button
            className={`button-stepper ${
              selectedButton === 4 ? "selected" : ""
            }`}
            onClick={() => {
              handleButtonClick(4);
            }}
            disabled={!selectedOptions?.model?.name}
            style={{
              backgroundColor:
                selectedOptions?.model?.name &&
                selectedButton !== 4 &&
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.purple.strokeDarkPurple
                  : "",
              color:
                selectedButton !== 4 &&
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.white.white
                  : "",
              height: "100%",
            }}
          >
            Versión
          </button>
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            background: `linear-gradient(to right, ${colors.purple.strokeDarkPurple} 50%, transparent 50%)`,
            width: "100%",
          }}
        >
          <button
            className={`button-stepper ${
              selectedButton === 5 ? "selected" : ""
            }`}
            onClick={() => {
              handleButtonClick(5);
            }}
            disabled={!selectedOptions?.version?.name}
            style={{
              backgroundColor:
                selectedOptions?.model?.name &&
                selectedButton !== 5 &&
                selectedButton !== 4 &&
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.purple.strokeDarkPurple
                  : "",
              color:
                selectedButton !== 5 &&
                selectedButton !== 4 &&
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.white.white
                  : "",
              height: "100%",
            }}
          >
            Kms
          </button>
        </div>
        <div
          style={{
            background: `linear-gradient(to right, ${colors.purple.strokeDarkPurple} 50%, transparent 50%)`,
            width: "100%",
          }}
        >
          <button
            className={`button-stepper ${
              selectedButton === 6 ? "selected" : ""
            }`}
            onClick={() => handleButtonClick(6)}
            disabled={!number || !isGnc || !selectedOptions?.version?.name}
            style={{
              backgroundColor:
                selectedOptions?.model?.name &&
                selectedButton !== 6 &&
                selectedButton !== 5 &&
                selectedButton !== 4 &&
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.purple.strokeDarkPurple
                  : "",
              color:
                selectedButton !== 6 &&
                selectedButton !== 5 &&
                selectedButton !== 4 &&
                selectedButton !== 3 &&
                selectedButton !== 2 &&
                selectedButton !== 1
                  ? colors.white.white
                  : "",
              height: "100%",
              width: "100%",
            }}
          >
            Datos
          </button>
        </div>
      </div>
    </div>
  );
};
