import React from "react";
import { useState } from "react";
import { MdSearch } from "react-icons/md";
import "../styles/components/baseSearchInput.css";

export default function BaseSearchInputComponent({
  searchText,
  dataSearch,
  setDataSearch,
}) {
  const [searchResults, setSearchResults] = useState([]);

  const handleChange = (e) => {
    setSearchResults(e.target.value);
    filtrar(e.target.value);
  };

  const filtrar = (terminoBusqueda) => {
    var resultadosBusqueda = dataSearch.filter((elemento) => {
      if (
        elemento.name
          .toString()
          .toLowerCase()
          .includes(terminoBusqueda.toLowerCase())
      ) {
        return elemento;
      }
    });
    setDataSearch(resultadosBusqueda);
  };

  return (
    <div className="search-container">
      <input
        type="text"
        className="search-input"
        placeholder={searchText}
        value={searchResults}
        onChange={handleChange}
      />
      <div className="search-icon-container">
        <MdSearch className="search-icon" />
      </div>
    </div>
  );
}
