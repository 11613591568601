import React from "react";
import AppRouter from "./routes/AppRouter";
import { SpinnerProvider } from "./context/spinnerContext";
import { AuthProvider } from "./context/AuthContext";
import { CurrencyProvider } from "./context/CurrencyContext";
import { FavoritesProvider } from "./context/FavoritesContext";
import { QuoteProvider } from "./context/QuoteContext";

function App() {
  return (
    <>
      <SpinnerProvider>
        <AuthProvider>
          <QuoteProvider>
            <FavoritesProvider>
              <CurrencyProvider>
                <AppRouter />
              </CurrencyProvider>
            </FavoritesProvider>
          </QuoteProvider>
        </AuthProvider>
      </SpinnerProvider>
    </>
  );
}

export default App;
