import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../styles/components/bottonGridTypeCar.css";
import Sedan from "../images/Sedán.png";
import Rural from "../images/Rural.png";
import Suv from "../images/SUV.png";
import Hatchback from "../images/Hatchback.png";
import PickUp from "../images/Pick-Up.png";
import Monovolumen from "../images/Monovolumen.png";
import Furgon from "../images/Furgón.png";
import Coupe from "../images/Coupé.png";
import Minivan from "../images/Minivan.png";
import { MdKeyboardArrowDown } from "react-icons/md";

export default function BottonGridTypeCarComponent() {
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedButtonText, setSelectedButtonText] = useState(
    "Seleccioná el tipo de vehículo"
  );
  const [isExpanded, setIsExpanded] = useState(false); // Estado para controlar la expansión/contracción

  const handleButtonClick = (id, text) => {
    setSelectedButton(id);
    setSelectedButtonText(text);
    console.log(id, text);
  };

  const handleWrapperClick = () => {
    setIsExpanded(!isExpanded); // Cambia el estado al hacer clic en el wrapper
  };
  const buttons = [
    { id: 1, text: "Sedan", imageUrl: Sedan },
    { id: 2, text: "Monovolumen", imageUrl: Monovolumen },
    { id: 3, text: "SUV", imageUrl: Suv },
    { id: 4, text: "Hatchback", imageUrl: Hatchback },
    { id: 5, text: "Pick-Up", imageUrl: PickUp },
    { id: 6, text: "Furgon", imageUrl: Furgon },
    { id: 7, text: "Coupé", imageUrl: Coupe },
    { id: 8, text: "Minivan", imageUrl: Minivan },
    { id: 9, text: "Rural", imageUrl: Rural },
  ];

  return (
    <div
      className={`button-grid-wrapper ${isExpanded ? "expanded" : "collapsed"}`}
      onClick={handleWrapperClick}
    >
      {isExpanded ? (
        <Row>
          {buttons.map((button) => (
            <Col key={button.id} xs={4}>
              <CustomButton
                key={button.id}
                id={button.id}
                text={button.text}
                imageUrl={button.imageUrl}
                isSelected={selectedButton === button.id}
                onClick={(id, text) => handleButtonClick(id, text)}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <div className="empty-div">
          <p
            className="value-input-select"
            style={{
              color:
                selectedButtonText !== "Seleccioná el tipo de vehículo"
                  ? "#180E31"
                  : "#D7C9EE",
            }}
          >
            {selectedButtonText}{" "}
          </p>
          {!isExpanded ? (
            <MdKeyboardArrowDown className="icon-button-grid" />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
  );
}
const CustomButton = ({ id, text, imageUrl, isSelected, onClick }) => {
  return (
    <button
      className={`button-grid-type ${isSelected ? "selected" : ""}`}
      onClick={() => onClick(id, text)}
    >
      <img src={imageUrl} alt={text} className="button-image" />
      <div
        className={`button-text ${text === "Monovolumen" ? "small-font" : ""}`}
      >
        {text}
      </div>
    </button>
  );
};
