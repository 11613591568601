import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import "../styles/components/sessionAlertModal.css";
import BaseWhiteButtonComponent from "./BaseWhiteButtonComponent";
import ChooseCar from "../modules/Home/images/choose-car.svg";
import BaseModalComponet from "./BaseModalComponet";
import RegistrationModalComponent from "../modules/Login/components/RegistrationModalComponent";
import LoginModalComponent from "../modules/Login/components/LoginModalComponent";
import { useAuth } from "../context/AuthContext";
import { useMediaQuery } from "../hooks/useMediaQuery";

export default function SessionAlertModalComponent({ isOpen, onClose }) {
  let breakPoint = useMediaQuery("(min-width: 990px)");
  let breakPointMobile = useMediaQuery("(min-width: 430px)");
  const { user, isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const toggleLoginModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} centered size="lg">
        <Modal.Body className="session-modal">
          <div className="icon-container">
            <span className="button-close m-2" onClick={onClose}>
              <MdOutlineClose className="icon-close" />
            </span>
          </div>
          <div className="top-white-container">
            <img
              src={ChooseCar}
              alt="auta-button"
              style={{ height: "100px", width: "112px" }}
            />
            <p className="title mt-4 mb-2">Inicia sesión para continuar</p>
            <p className="text mb-0 mb-md-4">
              Para acceder a todas las funciones y {!breakPoint && <br />}
              disfrutar de una
              {breakPoint && <br />} experiencia personalizada,
              {!breakPoint && <br />} te invitamos a loguearte y
              {breakPoint && <br />}
              verificar tu {!breakPoint && <br />} cuenta de forma sencilla.
            </p>
          </div>
          <div className="button-purple-container">
            {breakPointMobile ? (
              <BaseWhiteButtonComponent
                buttonText={"Continuar"}
                buttonDisabled={isAuthenticated}
                onClick={toggleLoginModal}
              />
            ) : (
              <button
                className="btn-white-user-nologged"
                onClick={toggleLoginModal}
              >
                Continuar
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <BaseModalComponet
        isOpen={isModalOpen}
        onClose={toggleLoginModal}
        size={"xs"}
      >
        {!showLogin ? (
          <RegistrationModalComponent
            setShowLogin={setShowLogin}
            toggleLoginModal={toggleLoginModal}
          />
        ) : (
          <LoginModalComponent
            setShowLogin={setShowLogin}
            user={user}
            toggleLoginModal={toggleLoginModal}
          />
        )}
      </BaseModalComponet>
    </>
  );
}
