import React from "react";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const CardAboutUsComponent = ({ number, title, description }) => {
  let isPageWide = useMediaQuery("(min-width: 768px)");

  return (
    <div
      className="cardAboutUs d-flex flex-column p-2 p-md-3 mb-4 mb-md-3"
      style={{
        height: isPageWide ? "170px" : "auto",
      }}
    >
      <div className="d-flex align-items-center justify-content-start">
        <div className="circleNumberCardAboutUs d-flex align-items-center justify-content-center">
          <p className="numberCardAboutUs ms-1">.0{number}</p>
        </div>
        <p className="titleCardAboutUs ms-2">{title}</p>
      </div>
      <p className="mt-2 descriptionCardAboutUs">{description}</p>
    </div>
  );
};
