import React from "react";
import { colors } from "../../../styles/colors";

export const TimeLineRightComponent = ({ children, incompleted }) => {
  return (
    <div
      style={{
        width: "250px",
      }}
      className="d-flex"
    >
      <div
        style={{
          width: "100%",
        }}
      ></div>
      <div
        style={{
          width: "100%",
          borderLeft: `6px solid ${
            incompleted ? colors.gray.lightGrey : colors.purple.purple
          }`,
          marginRight: "-43px",
        }}
        className="d-flex align-items-center justify-content-start pb-4"
      >
        {children}
      </div>
    </div>
  );
};
