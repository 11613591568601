import React, { useState } from "react";
import { InputsTechnicalDataComponent } from "./InputsTechnicalDataComponent";
import { AttachFilesComponent } from "./AttachFilesComponent";
import { Container } from "react-bootstrap";
import { HeaderComponent } from "../../../components/HeaderComponent";
import IconBack from "../images/icon-arrow-left.svg";
import { useNavigate } from "react-router-dom";

export const TechnicalDataCarComponent = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const backToNavigate = () => {
    navigate("/deals");
  };

  return (
    <div>
      <div className="container-table-deals">
        <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
        <Container className={`${expanded ? "blur-background" : ""}`}>
          <div className="d-flex align-items-center justify-content-center mt-3 mt-md-4 flex-column">
            <div>
              <div className="d-flex align-items-center mb-3 mb-md-1">
                <img src={IconBack} alt="arrow-back" onClick={backToNavigate} />
                <p className="text-back-to-dashboard" onClick={backToNavigate}>
                  Volver al dashboard
                </p>
              </div>
              <div className="card-technical-data-car mb-2">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-title-technical-data">
                    Ficha del vehículo
                  </p>
                  <p className="text-car-id">Car ID: 000000</p>
                </div>
                <InputsTechnicalDataComponent />
                <AttachFilesComponent />
                <div className="d-flex align-items-center justify-content-between mt-4 flex-column flex-md-row">
                  <button className="btn-save-changes">Guardar cambios</button>
                  <button className="btn-delete-car">Eliminar vehiculo</button>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
