import React from "react";
import { Container } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import IconArrowDown from "../images/icon-arrow-down-purple.svg";
import IconArrowDownWhite from "../images/icon-arrow-down-white.svg";

export const TitleHomeWithIcon = ({
  title,
  colorText,
  colorIcon,
  fontSize,
  isModal,
}) => {
  const styleText = {
    color: colorText,
    textAlign: "center",
    fontSize: fontSize,
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  };

  const styleIcon = {
    marginTop: "-10px",
    width: isModal ? "50px" : "",
    height: isModal ? "50px" : "",
  };

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center">
      <p style={styleText}>{title}</p>
      <img
        src={colorIcon === "#FFFFFF" ? IconArrowDownWhite : IconArrowDown}
        alt="icon-arrow-down"
        style={styleIcon}
      />
    </Container>
  );
};
