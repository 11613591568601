import React from "react";

export const RangeSlider = ({ min, max, value, step, onChange }) => {
  const [minValue, setMinValue] = React.useState(value ? value.min : min);
  const [maxValue, setMaxValue] = React.useState(value ? value.max : max);

  React.useEffect(() => {
    if (value) {
      setMinValue(value.min);
      setMaxValue(value.max);
    }
  }, [value]);

  const handleMinChange = (e) => {
    e.preventDefault();
    const newMinVal = Math.min(+e.target.value, maxValue - step);
    setMinValue(newMinVal);
    onChange({ min: newMinVal, max: maxValue });
  };

  const handleMaxChange = (e) => {
    e.preventDefault();
    const newMaxVal = Math.max(+e.target.value, minValue + step);
    setMaxValue(newMaxVal);
    onChange({ min: minValue, max: newMaxVal });
  };

  const minPos = ((minValue - min) / (max - min)) * 100;
  const maxPos = ((maxValue - min) / (max - min)) * 100;

  return (
    <>
      <div className="range-numbers-container mt-2">
        <div
          className="d-flex mb-2 align-items-center justify-content-center"
          style={{ width: "100%" }}
        >
          <input
            className="inputKmsPrice"
            placeholder="Mínimo"
            value={value.min
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
            readOnly
          />
          <div className="ms-1 me-1 dividerInputs"></div>
          <input
            className="inputKmsPrice"
            placeholder="Máximo"
            value={value.max
              .toFixed(0)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
            readOnly
          />
        </div>
      </div>
      <div className="wrapper">
        <div className="input-wrapper">
          <input
            className="input"
            type="range"
            min={min}
            max={max}
            step={step}
            value={minValue}
            onChange={handleMinChange}
          />
          <input
            className="input"
            type="range"
            min={min}
            max={max}
            step={step}
            value={maxValue}
            onChange={handleMaxChange}
          />
        </div>
        <div style={{ marginLeft: "6px", marginTop: "-10px" }}>
          <div className="control-wrapper">
            <div className="control" style={{ left: `${minPos}%` }} />
            <div className="rail">
              <div
                className="inner-rail"
                style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }}
              />
            </div>
            <div className="control" style={{ left: `${maxPos}%` }} />
          </div>
        </div>
      </div>
    </>
  );
};
