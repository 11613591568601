import React, { useEffect } from "react";
import SpinnerComponent from "../components/SpinnerComponent";
import { useAuth } from "../context/AuthContext";


export function LoadingRoutes({ children }) {
  const { isAuthLoaded } = useAuth();

  useEffect(() => {
    // Aquí puedes realizar cualquier lógica adicional que necesites para cargar datos
    // Puedes hacer llamadas a la API, etc.
  }, []);

  // if (error) return <p>Error al cargar los datos</p>;

  // Si loadingCompany es true, mostrar el spinner
  if (isAuthLoaded) return <SpinnerComponent />;

  // Si no hay error y loadingCompany es false, mostrar el contenido
  return <>{children}</>;
}