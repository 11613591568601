import React from "react";
import "../styles/components/baseModal.css";
import { Modal } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";

const BaseModalComponet = ({ isOpen, onClose, size, children }) => {
  if (!isOpen) return null;

  const backgroundClassName = `${size}-modal-background`;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      centered
      size={size}
      className={size === "sm" ? "custom-sm-modal" : ""}
    >
      <Modal.Body className={`custom-modal ${backgroundClassName}`}>
        <div className="icon-container">
          <span className="button-close me-0 me-md-3" onClick={onClose}>
            <MdOutlineClose className="icon-close" />
          </span>
        </div>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default BaseModalComponet;
