import React, { useState } from "react";
import "../style/home.css";
import { Container } from "react-bootstrap";
import Car1 from "../images/car-derivador1.png";
import Car2 from "../images/car-derivador2.png";
import Car3 from "../images/car-derivador3.png";
import ArrowDown from "../images/ic_arrow_down.svg";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import ButtonGroupDerivatorComponent from "./ButtonGroupDerivatorComponent";
import CardDerivatorComponent from "./CardDerivatorComponent";
import BaseModalComponet from "../../../components/BaseModalComponet";
import RenewalComponent from "./RenewalComponent";
import BuyComponent from "./BuyComponent";
import SaleComponent from "./SaleComponent";

export const CardComponent = ({ isPageWide }) => {
  const [isRenewModalOpen, setRenewModalOpen] = useState(false);
  const [modalSize, setModalSize] = useState("lg");

  const toggleRenewModal = () => {
    setRenewModalOpen(!isRenewModalOpen);
    setModalSize("lg");
  };

  const [isBuyModalOpen, setBuyModalOpen] = useState(false);
  const toggleBuyModal = () => {
    setBuyModalOpen(!isBuyModalOpen);
    setModalSize("lg");
  };

  const [isSaleModalOpen, setSaleModalOpen] = useState(false);
  const toggleSaleModal = () => {
    setSaleModalOpen(!isSaleModalOpen);
    setModalSize("lg");
  };

  return (
    <>
      <div className="mt-4 mt-md-5 mb-3">
        <TitleHomeWithIcon
          title={"¿Qué estás buscando hoy?"}
          fontSize={isPageWide ? "24px" : "14px"}
          colorText={colors.purple.purple}
          colorIcon={colors.purple.purple}
        />
      </div>
      {isPageWide ? (
        <Container className="card-home-container mb-3">
          <CardDerivatorComponent
            title="Quiero Renovar"
            description="Cargá tu auto, contanos qué buscás y elegí entre todas las opciones que tenemos para vos."
            iconSrc={ArrowDown}
            carImgSrc={Car1}
            buttonText="Empezar"
            backgroundColor="1"
            isPageWide={isPageWide}
            onClick={toggleRenewModal}
          />
          <CardDerivatorComponent
            title="Quiero Comprar"
            description="Contanos qué buscás y nosotros filtramos entre todas las opciones que tenemos para darte las mejores propuestas."
            iconSrc={ArrowDown}
            carImgSrc={Car2}
            buttonText="Empezar"
            backgroundColor="2"
            isPageWide={isPageWide}
            onClick={toggleBuyModal}
          />
          <CardDerivatorComponent
            title="Quiero Vender"
            description="Subí tu vehículo, contanos todo sobre él y lo cotizamos cuanto antes para que vos puedas decidir."
            iconSrc={ArrowDown}
            carImgSrc={Car3}
            buttonText="Empezar"
            backgroundColor="3"
            isPageWide={isPageWide}
            // onClick={() => navigate("/sale")}
            onClick={toggleSaleModal}
          />
        </Container>
      ) : (
        <ButtonGroupDerivatorComponent />
      )}
      <BaseModalComponet
        isOpen={isRenewModalOpen}
        onClose={toggleRenewModal}
        size={modalSize}
      >
        <RenewalComponent
          setModalSize={setModalSize}
          setRenewModalOpen={setRenewModalOpen}
        />
      </BaseModalComponet>
      <BaseModalComponet
        isOpen={isBuyModalOpen}
        onClose={toggleBuyModal}
        size={modalSize}
      >
        <BuyComponent setModalSize={setModalSize} isPageWide={isPageWide} />
      </BaseModalComponet>
      <BaseModalComponet
        isOpen={isSaleModalOpen}
        onClose={toggleSaleModal}
        size={modalSize}
      >
        <SaleComponent
          setModalSize={setModalSize}
          setSaleModalOpen={setSaleModalOpen}
          isPageWide={isPageWide}
        />
      </BaseModalComponet>
    </>
  );
};
