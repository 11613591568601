import React from "react";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { StarsComponent } from "./StarsComponent";
import { CardClientExperienciesComponent } from "./CardClientExperienciesComponent";
import Client from "../images/client.png";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import PeopleUlises from "../images/ulises-izaguirre.jpeg";
import PeopleAldana from "../images/aldana-jazmin.jpg";
import PeopleLucas from "../images/lucas-albarracin.jpg";

export const ClientExperiencesComponent = () => {
  let breakPointMobile = useMediaQuery("(min-width: 430px)");

  return (
    <>
      <div
        className="containerClientExperiences d-flex flex-column align-items-center justify-content-center mb-5"
        style={{ marginTop: "80px" }}
      >
        <div className="mt-5 mb-3">
          <TitleHomeWithIcon
            title={
              <>
                ¿Qué opinás vos {!breakPointMobile && <br />} de nuestros
                servicios?
              </>
            }
            fontSize={"24px"}
            colorText={colors.white.white}
            colorIcon={colors.white.white}
          />
        </div>
        <div className="d-flex align-items-center mb-5">
          <div
            className="d-none d-md-block"
            style={{ marginRight: "-40px", zIndex: 1 }}
          >
            <div className="circleStarPromedio d-flex align-items-center justify-content-center">
              <p className="numberPromedio">4.7</p>
            </div>
          </div>
          <div className="containerStars d-flex align-items-center justify-content-center p-2">
            <div className=" d-flex align-items-center justify-content-center ms-md-5">
              <p
                className="numberPromedio d-md-none me-2 ms-2 mt-1"
                style={{ fontSize: "16px" }}
              >
                4.8
              </p>
              <StarsComponent />
              <StarsComponent />
              <StarsComponent />
              <StarsComponent />
              <StarsComponent />
            </div>
            <p className="textTotalReseñas ms-md-4 mt-ms-1 me-2">
              327 Reseñas.
            </p>
          </div>
        </div>
        <Container className="d-flex align-items-center justify-content-center ">
          <Row className="d-none d-lg-block">
            <div className="d-flex">
              <Col lg={4}>
                <CardClientExperienciesComponent
                  image={PeopleUlises}
                  opinion={
                    "Excelente atención. Pude vender mi auto rápido, fácil y seguro. Cumplieron con la cotización inicial y respetaron todo."
                  }
                  name={"Ulises Izaquirre"}
                  score={"5.0"}
                  age={"27"}
                />
              </Col>
              <Col lg={4}>
                <CardClientExperienciesComponent
                  image={PeopleAldana}
                  opinion={
                    "Los recomiendo por seguridad y confianza a la hora de vender el auto. Profesionales y se ocupan de todo."
                  }
                  name={"Aldana Jazmin"}
                  score={"4.2"}
                  age={"30"}
                />
              </Col>
              <Col lg={4}>
                <CardClientExperienciesComponent
                  image={PeopleLucas}
                  opinion={
                    "La operación fue muy simple. Lleve el auto a inspeccionar y tenia acreditado el dinero en el día."
                  }
                  name={"Lucas Albarracin"}
                  score={"4.7"}
                  age={"34"}
                />
              </Col>
            </div>
          </Row>
          <Row className="containerCardExperiences d-lg-none">
            <Col
              xs={4}
              className="d-flex align-items-center justify-content-between "
            >
              <CardClientExperienciesComponent
                image={PeopleUlises}
                opinion={
                  "Excelente atención. Pude vender mi auto rápido, fácil y seguro. Cumplieron con la cotización inicial y respetaron todo."
                }
                name={"Ulises Izaguirre"}
                score={"5.0"}
                age={"27"}
              />
              <CardClientExperienciesComponent
                image={PeopleAldana}
                opinion={
                  "Los recomiendo por seguridad y confianza a la hora de vender el auto. Profesionales y se ocupan de todo."
                }
                name={"Aldana Jazmin"}
                score={"4.2"}
                age={"30"}
              />
              <CardClientExperienciesComponent
                image={PeopleLucas}
                opinion={
                  "La operación fue muy simple. Lleve el auto a inspeccionar y tenia acreditado el dinero en el día."
                }
                name={"Lucas Albarracin"}
                score={"4.7"}
                age={"34"}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
