import React from "react";
import { Col, Row } from "react-bootstrap";

export const InputsTechnicalDataComponent = () => {
  return (
    <>
      <Row className="mt-3">
        <Col md={4}>
          <p className="label-input">Marca</p>
          <select className="style-input arrow-select  mb-3 mb-md-0">
            <option>Option</option>
            <option>Option</option>
            <option>Option</option>
          </select>
        </Col>
        <Col md={4}>
          <p className="label-input">Modelo</p>
          <select className="style-input arrow-select  mb-3 mb-md-0">
            <option>Option</option>
            <option>Option</option>
            <option>Option</option>
          </select>
        </Col>
        <Col md={4}>
          <p className="label-input">Versión</p>
          <select className="style-input arrow-select  mb-3 mb-md-0">
            <option>Option</option>
            <option>Option</option>
            <option>Option</option>
          </select>
        </Col>
      </Row>
      <Row className="mt-md-3">
        <Col md={4}>
          <p className="label-input">Año</p>
          <select className="style-input arrow-select  mb-3 mb-md-0">
            <option>Option</option>
            <option>Option</option>
            <option>Option</option>
          </select>
        </Col>
        <Col md={4}>
          <p className="label-input">Kilometraje</p>
          <input
            placeholder="Ingresá el kilometraje del vehiculo"
            className="style-input mb-3 mb-md-0"
          />
        </Col>
        <Col md={4}>
          <p className="label-input">Precio</p>
          <input
            placeholder="Ingresá el kilometraje del vehiculo"
            className="style-input mb-3 mb-md-0"
          />
        </Col>
      </Row>
    </>
  );
};
