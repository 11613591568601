import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { ComingSoonPage } from "../modules/ComingSoon/ComingSoonPage";
import { HomePage } from "../modules/Home/HomePage";
import { CarCatalogPage } from "../modules/CarCatalog/CarCatalogPage";
import BuyPage from "../modules/MobileDerivator/BuyPage";
import TechnicalDataPage from "../modules/TechnicalData/TechnicalDataPage";
import RequireAuth from "./RequireAuth";
import SessionAlertModalComponent from "../components/SessionAlertModalComponent";
import WishListPage from "../modules/Wishlist/WishListPage";
import { LoadingRoutes } from "./LoadingRoutes";
import { TableDealsPage } from "../modules/TableDeals/TableDealsPage";
import { TechnicalDataCarComponent } from "../modules/TableDeals/components/TechnicalDataCarComponent";
import SalePage from "../modules/QuoteStepper/SalePage";
import RenewalPage from "../modules/QuoteStepper/RenewalPage";
import { ProfilePage } from "../modules/Profile/ProfilePage";
import { NotFoundPage } from "./NotFoundPage";

export default function AppRouter() {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <Router>
      <LoadingRoutes>
        <Routes>
          <Route path="/" element={<ComingSoonPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/cars" element={<CarCatalogPage />} />
          <Route path="/renewal" element={<RenewalPage />} />
          {/* <Route path="/buy" element={<BuyPage />} /> */}
          <Route path="/sale" element={<SalePage />} />
          <Route path="/deals" element={<TableDealsPage />} />
          <Route path="/deal/:car_id" element={<TechnicalDataCarComponent />} />
          <Route path="/car/:car_id" element={<TechnicalDataPage />} />
          <Route path="/car/:car_id" element={<TechnicalDataPage />} />
          <Route
            path="/wishlist"
            element={
              <RequireAuth requireAuth={true} onAuthFailure={openModal}>
                <WishListPage />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </LoadingRoutes>
      {isModalOpen && (
        <SessionAlertModalComponent isOpen={isModalOpen} onClose={closeModal} />
      )}
    </Router>
  );
}
