import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { MdWhatsapp } from "react-icons/md";
import LineSocialMedia from "../images/line-social-media.svg";
import { FaLinkedinIn } from "react-icons/fa";

export const SocialMediaComponent = () => {
  return (
    <div className="containerSocialMedia d-flex flex-column align-items-center justify-content-center mb-5">
      <TitleHomeWithIcon
        title={"Seguinos en nuestras redes."}
        fontSize={"24px"}
        colorText={colors.white.white}
        colorIcon={colors.white.white}
      />
      <Container>
        <Row className="mb-5">
          <Col className="d-flex align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div
                className="d-flex align-items-center justify-content-around"
                style={{ width: "100%" }}
              >
                <div className="circle-social-media d-flex align-items-center justify-content-center mb-4">
                  <a
                    href="https://www.linkedin.com/company/autaoficial" // Reemplaza con el número de teléfono de WhatsApp
                    className="d-flex align-items-center justify-content-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn
                      color={colors.purple.purple}
                      fontSize={"24px"}
                    />
                  </a>
                </div>
                <div className="circle-social-media d-flex align-items-center justify-content-center ms-4  mb-4">
                  <a
                    href="https://www.facebook.com/auta.tienda.de.autos" // Reemplaza con el número de teléfono de WhatsApp
                    className="d-flex align-items-center justify-content-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF
                      color={colors.purple.purple}
                      fontSize={"22px"}
                    />
                  </a>
                </div>
                <div className="circle-social-media d-flex align-items-center justify-content-center ms-4  mb-4">
                  <a
                    href="https://wa.me/5491123707738" // Reemplaza con el número de teléfono de WhatsApp
                    className="d-flex align-items-center justify-content-center"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MdWhatsapp
                      color={colors.purple.purple}
                      fontSize={"24px"}
                    />
                  </a>
                </div>
              </div>
              <img src={LineSocialMedia} alt="linea-social-media" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
