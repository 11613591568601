import React, { useEffect, useState } from "react";
import "../../styles/ButtonGroupStepper.css";
import { useNavigate } from "react-router-dom";
import BaseModalComponet from "../../../../components/BaseModalComponet";
import ManualQuoteModalComponent from "../../../../components/ManualQuoteModalComponent";
import { useRef } from "react";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../../../firebase/firebase";
import {
  enviarDatosAuto,
  getBrands,
  getModels,
  getVersion,
} from "../../../Home/services/fetchDataCar";
import ModalMinKmsComponent from "../../../../components/ModalMinKmsComponent";
import { colors } from "../../../../styles/colors";
import { useAuth } from "../../../../context/AuthContext";
import fetchDataPhoneNumberExists from "../../../../utils/validatePhoneNumberExists";
import { TitleHomeWithIcon } from "../../../Home/components/TitleHomeWithIcon";
import { ButtonGroupComponent } from "./ButtonGroupComponent";
import { FooterStepperComponent } from "./FooterStepperComponent";
import { CarrouselComponent } from "./CarrouselComponent";
import { post } from "../../../../utils/apiService";

export default function ButtonGroupStepperSaleComponent({
  isTitleShow,
  setIsTitleShow,
}) {
  const [currentView, setCurrentView] = useState("step1");
  const [number, setNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const isMobile = true;
  const { isAuthenticated, user, setAuthenticated } = useAuth();
  const [showDropdownKms, setShowDropdownKms] = useState(false);

  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isRenewModalOpen, setIsRenewModalOpen] = useState(false);
  const [modalSize, setModalSize] = useState("xs");
  const [countryCode, setCountryCode] = useState(""); // Estado para el código de país
  const [phoneNumber, setPhoneNumber] = useState(""); // Estado para el número de teléfono
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null); // Estado para el resultado de la confirmación
  const [verificationCodes, setVerificationCodes] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]); // Estado para el código de verificación
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]; // Referencias a los inputs
  const [textError, setTextError] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [isDisabledB, setIsDisabledB] = useState(false);
  const [isGnc, setIsGnc] = useState(""); // Estado para el valor seleccionado
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [isContinuing, setIsContinuing] = useState(false);
  const [loginAlert, setLoginAlert] = useState(false);
  const [showloginView, setShowLoginView] = useState(false);

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
    if (inputValue === "") {
      setNumber(""); // Si inputValue está vacío, establece el estado como una cadena vacía
    } else {
      const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      setNumber(formattedNumber);
    }
  };
  const navigate = useNavigate();
  const handleStartClick = () => {
    if (currentView === "step1") {
      setCurrentView("step2");
    } else if (currentView === "step2") {
      setCurrentView("step3");
      setIsTitleShow(false);
    }
  };
  const toggleRenewModal = () => {
    setIsRenewModalOpen(!isRenewModalOpen);
    setModalSize("xs");
  };
  useEffect(() => {
    if (!isAuthenticated) {
      const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });
      setRecaptchaVerifier(verifier);
    }
  }, []);

  const [selectedOptions, setSelectedOptions] = useState({
    year: null,
    brand: null,
    model: null,
    version: null,
    kms: null,
  });
  const [dataBrands, setDataBrands] = useState();
  const [dataModels, setDataModels] = useState();
  const [dataVersions, setDataVersions] = useState();
  const [dataVersionsSearch, setDataVersionsSearch] = useState();
  const [dataModelsSearch, setDataModelsSearch] = useState();
  const [dataBrandsSearch, setDataBrandsSearch] = useState();

  useEffect(() => {
    getBrands(selectedOptions?.year?.name, setDataBrands, setDataBrandsSearch);
  }, [selectedOptions?.year]);

  useEffect(() => {
    getModels(
      selectedOptions?.brand?.name,
      selectedOptions?.year?.name,
      setDataModels,
      setDataModelsSearch
    );
  }, [selectedOptions?.brand]);

  useEffect(() => {
    getVersion(
      selectedOptions?.year?.name,
      selectedOptions?.brand?.name,
      selectedOptions?.model?.name,
      setDataVersions,
      setDataVersionsSearch
    );
  }, [selectedOptions?.model]);

  useEffect(() => {
    handleButtonClick(selectedButton);
  }, [selectedButton]);

  const handleButtonClick = (buttonNumber) => {
    const formattedNumber = parseInt(number.replace(/\./g, ""), 10);
    if (buttonNumber === 6) {
      if (!isNaN(formattedNumber) && formattedNumber <= 1000) {
        if (!isContinuing) {
          setShowModal(true);
        } else {
          setSelectedButton(buttonNumber);
        }
      } else {
        setSelectedButton(buttonNumber);
      }
    } else {
      setSelectedButton(buttonNumber);
    }
  };
  const handleOptionSelect = (option, value, id) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [option]: { id: id, name: value },
    }));
  };

  const handleItemClick = (selectedValue) => {
    setSelectedItem(selectedValue);
  };
  const handleSubmit = async (e) => {
    setIsLoading(true);
    setIsDisabledB(true);
    e.preventDefault();

    try {
      const fullPhoneNumber = `+549${countryCode}${phoneNumber}`;
      // Llamada a la función fetchDataPhoneNumberExists para verificar si el número es válido
      if (!loginAlert) {
        const isPhoneNumberValid = await fetchDataPhoneNumberExists(
          fullPhoneNumber
        );
        if (isPhoneNumberValid) {
          setLoginAlert(true);
          // Si el número es válido, muestra una alerta y detén el flujo
          setTextError(
            "Número de teléfono ya pertenece a una cuenta existente"
          );
          return;
        }
      }

      // Continuar con el proceso de autenticación si el número es válido
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        fullPhoneNumber,
        recaptchaVerifier
      );
      setConfirmationResult(confirmationResult);
      handleStartClick();
    } catch (error) {
      // Manejar errores como en tu implementación original
      switch (error.code) {
        case "auth/invalid-phone-number":
          // El número de teléfono es inválido
          setTextError("El número de teléfono es inválido");
          break;
        case "auth/quota-exceeded":
        case "auth/too-many-requests":
          // Se ha superado la cuota de SMS para este proyecto
          setTextError("Se ha superado la cuota de SMS");
          break;
        case "auth/captcha-check-failed":
          // Error de reCAPTCHA
          setTextError("Error de reCAPTCHA");
          break;
        default:
          // Otros errores
          console.error("Error al verificar el número de teléfono:", error);
          setTextError("Error al verificar el número de teléfono");
          break;
      }
    } finally {
      // Asegúrate de manejar la carga y la desactivación de los botones en la sección finally
      setIsLoading(false);
      setIsDisabledB(false);
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    // Verifica si el valor ingresado es un número o una cadena vacía y está en el rango de 0 a 9
    if (/^[0-9]$|^$/.test(value) && index >= 0 && index <= 5) {
      const newVerificationCodes = [...verificationCodes];
      newVerificationCodes[index] = value;
      setVerificationCodes(newVerificationCodes);

      // Mueve el foco al siguiente input solo si se ha ingresado un dígito
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const authenticateUser = async (enteredCode) => {
    const credential = PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      enteredCode
    );
    const userCredential = await signInWithCredential(auth, credential);
    const user = userCredential.user;
    console.log("Usuario autenticado:", user);
  };

  const sendData = async (phone, name) => {
    try {
      const result = await post("/users/create_user", { name, email, phone });
      console.log("Solicitud POST exitosa:", result);
      setAuthenticated(true);
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error.message);
    }
  };

  const handleError = (error) => {
    switch (error.code) {
      case "auth/invalid-verification-code":
        setTextError("El código de verificación es inválido");
        break;
      case "auth/code-expired":
        setTextError("El código de verificación ha caducado");
        break;
      case "auth/credential-already-in-use":
        setTextError("La credencial ya está en uso por otro usuario");
        break;
      default:
        console.error("Error al verificar el código de verificación:", error);
        setTextError("Error al verificar el código.");
        break;
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setIsDisabledB(true);
    setIsLoading(true);

    const enteredCode = verificationCodes.join("");

    try {
      await authenticateUser(enteredCode);
      if (!loginAlert) {
        await sendData(`+549${countryCode}${phoneNumber}`, name);
      }
      await enviarDatosAuto(
        selectedOptions.brand.name,
        selectedOptions.brand.id,
        selectedOptions.model.name,
        selectedOptions.model.id,
        selectedOptions.version.name,
        selectedOptions.version.id,
        selectedOptions.year.name,
        isGnc,
        number,
        `+549${countryCode}${phoneNumber}`,
        name
      );
      handleStartClick();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setIsDisabledB(false);
    }
  };
  const goToHome = () => {
    navigate("/home");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value)); // Verificar el formato del correo electrónico
  };
  const handleSubmitWithUser = async (e) => {
    e.preventDefault();
    setIsDisabledB(true);
    setIsLoading(true);
    try {
      await enviarDatosAuto(
        selectedOptions.brand.name,
        selectedOptions.brand.id,
        selectedOptions.model.name,
        selectedOptions.model.id,
        selectedOptions.version.name,
        selectedOptions.version.id,
        selectedOptions.year.name,
        isGnc,
        number,
        user?.phone,
        user?.name
      );
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
      setIsDisabledB(false);
      setCurrentView("step3");
      setIsTitleShow(false);
    }
  };

  return (
    <>
      <>
        {currentView !== "step3" ? (
          <div className="mt-4">
            <TitleHomeWithIcon
              title={"¡Cargá tu auto!"}
              fontSize={"22px"}
              colorText={colors.purple.purple}
              colorIcon={colors.purple.purple}
            />
          </div>
        ) : null}
        {isTitleShow ? (
          <ButtonGroupComponent
            selectedButton={selectedButton}
            selectedOptions={selectedOptions}
            handleButtonClick={handleButtonClick}
            number={number}
            isGnc={isGnc}
          />
        ) : null}
        <div className="mobile-component-display">
          <CarrouselComponent
            selectedOptions={selectedOptions}
            selectedButton={selectedButton}
            isMobile={isMobile}
            handleOptionSelect={handleOptionSelect}
            handleItemClick={handleItemClick}
            setSelectedOptions={setSelectedOptions}
            handleButtonClick={handleButtonClick}
            selectedItem={selectedItem}
            dataBrandsSearch={dataBrandsSearch}
            dataBrands={dataBrands}
            dataModelsSearch={dataModelsSearch}
            dataModels={dataModels}
            dataVersionsSearch={dataVersionsSearch}
            dataVersions={dataVersions}
            setDataBrands={setDataBrands}
            setDataModels={setDataModels}
            setDataVersions={setDataVersions}
            handleInputChange={handleInputChange}
            isGnc={isGnc}
            setIsGnc={setIsGnc}
            number={number}
            setNumber={setNumber}
            currentView={currentView}
            isAuthenticated={isAuthenticated}
            handleSubmitWithUser={handleSubmitWithUser}
            textError={textError}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
            showloginView={showloginView}
            setName={setName}
            email={email}
            handleEmailChange={handleEmailChange}
            isEmailValid={isEmailValid}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            loginAlert={loginAlert}
            setShowLoginView={setShowLoginView}
            setTextError={setTextError}
            handleVerification={handleVerification}
            isDisabledB={isDisabledB}
            name={name}
            inputRefs={inputRefs}
            verificationCodes={verificationCodes}
            handleStartClick={goToHome}
            handleChange={handleChange}
            setShowDropdownKms={setShowDropdownKms}
          />
        </div>
      </>
      {selectedButton === 6 || selectedButton === 5 ? null : (
        <FooterStepperComponent />
      )}
      <BaseModalComponet
        isOpen={isRenewModalOpen}
        onClose={toggleRenewModal}
        size={modalSize}
      >
        <ManualQuoteModalComponent />
      </BaseModalComponet>
      {showModal && (
        <ModalMinKmsComponent
          show={showModal}
          kms={number}
          setShow={setShowModal}
          setIsContinuing={setIsContinuing}
          setSelectedButton={setSelectedButton}
          isMobile={isMobile}
        />
      )}
    </>
  );
}
