import React from "react";
import { useNavigate } from "react-router-dom";

export const ModalsTableDealsComponent = ({
  show,
  setShow,
  type,
  placeholder,
  setType,
}) => {
  const navigate = useNavigate();

  if (!show) return null;

  const handleClose = () => {
    setShow(false);
  };

  const handleModalClick = (e) => {
    if (e.target.closest(".modal-success")) {
      return;
    }

    handleClose();
  };

  return (
    <>
      <div className="modal-overlay" onClick={handleModalClick}>
        <div className="modal-success">
          {type === "addcar" ? (
            <>
              <p className="text-title-modal-table-deals mb-3">Agregar auto</p>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <button
                  className="btn-contained-modal mb-2"
                  onClick={() => setType("dealid")}
                >
                  Con Deal ID
                </button>
                <button
                  className="btn-outlined-modal"
                  onClick={() => navigate("/deal/123")}
                >
                  Ingresar manualmente
                </button>
              </div>
            </>
          ) : type === "dealid" ? (
            <>
              <p className="text-title-modal-table-deals mb-3">
                Ingresar Deal ID
              </p>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <input
                  type="text"
                  placeholder={placeholder}
                  className="input-modal mb-5"
                />
                <button className="btn-contained-modal">Agregar auto</button>
              </div>
            </>
          ) : type === "typechange" ? (
            <>
              <p className="text-title-modal-table-deals mb-3">
                Tipo de cambio
              </p>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <input
                  type="text"
                  placeholder={placeholder}
                  className="input-modal mb-5"
                />
                <button className="btn-contained-modal">Guardar cambios</button>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
