import React from "react";
import { Modal } from "react-bootstrap";
import "../../styles/modalStopper.css";

const ModalStopperComponent = ({
  showModalStopper,
  setShowModalStopper,
  setSelectedOptions,
  setNumber,
  setIsGnc,
  setShowDropdownKms,
  isMobile,
  handleButtonClick,
}) => {
  const handleClose = () => setShowModalStopper(false);

  const handleCloseError = () => {
    setSelectedOptions({
      year: null,
      brand: null,
      model: null,
      version: null,
      kms: null,
    });
    setNumber("");
    setIsGnc("");
    setShowDropdownKms(false);
    if (isMobile) {
      handleButtonClick(1);
    }
    setShowModalStopper(false);
  };

  return (
    <Modal show={showModalStopper} onHide={handleClose} size="md" centered>
      <Modal.Body className="modalStopper">
        <p className="title-modal-stopper mt-2 mb-2">¡Ups!</p>
        <p className="description-modal-stopper mb-5">
          Lamentablemente no podemos hacer una oferta de compra por tu auto.
          Agradecemos tu interés y quedamos a disposición para cualquier
          consulta futura.
        </p>
        <div className="d-flex align-items-center justify-content-center flex-column">
          <p className="text-footer-modal-stopper mb-3">
            ¡Que tengas un buen dia!
          </p>
          <button
            className="btn-modal-stopper mt-2 mb-2"
            onClick={handleCloseError}
          >
            Volver al inicio
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalStopperComponent;
