import React from "react";
import { colors } from "../../../styles/colors";

export const PeriodTimeLineVerticalComponent = ({
  isRight,
  textOne,
  textTwo,
  completed,
}) => {
  return (
    <>
      {isRight ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <div
            className="circle-timeline-vertical"
            style={{
              backgroundColor: completed
                ? colors.purple.darkPurple
                : colors.gray.disabled,
            }}
          ></div>
          <div
            className="line-vertical"
            style={{
              backgroundColor: completed
                ? colors.purple.darkPurple
                : colors.gray.disabled,
            }}
          ></div>
          <div
            className="container-text-timeline"
            style={{ width: "106px", paddingTop: "2px", paddingBottom: "2px" }}
          >
            <p
              className="text-timeline"
              style={{
                color: completed ? colors.purple.purple : colors.gray.disabled,
              }}
            >
              {textOne} <br /> {textTwo}
            </p>
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ marginRight: "-12px" }}
        >
          <div
            className="container-text-timeline"
            style={{ width: "106px", paddingTop: "2px", paddingBottom: "2px" }}
          >
            <p
              className="text-timeline"
              style={{
                color: completed ? colors.purple.purple : colors.gray.disabled,
              }}
            >
              {textOne} <br /> {textTwo}
            </p>
          </div>
          <div
            className="line-vertical"
            style={{
              backgroundColor: completed
                ? colors.purple.darkPurple
                : colors.gray.disabled,
            }}
          ></div>
          <div
            className="circle-timeline-vertical"
            style={{
              backgroundColor: completed
                ? colors.purple.darkPurple
                : colors.gray.disabled,
            }}
          ></div>
        </div>
      )}
    </>
  );
};
