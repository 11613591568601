import React from "react";
import { useState } from "react";
import { MdClose } from "react-icons/md";

const CardInfoCarComponent = ({ carData }) => {

  const [seeMore, setSeeMore] = useState(false);

  const showMore = () => {
    setSeeMore(!seeMore);
  };

  return (
    <div className="card-info-car mt-3 mb-2">
      <p className="text-title-info-car">Datos técnicos</p>
      {seeMore
        ? carData?.map((data, index) => (
          <React.Fragment key={index}>
            <div
              className="mt-2 d-flex align-items-center justify-content-between animate__fadeInDown"
              style={{ animationDuration: "1s" }}
            >
              <p className="text-name-value-info-car">{data.description}</p>
              <p className="text-name-value-info-car">{data.value_description ? data.value_description : <MdClose style={{ fontSize: '18px' }} />}</p>
            </div>
            <div className="divider-info-car mt-2"></div>
          </React.Fragment>
        ))
        : carData?.slice(0, 5).map((data, index) => (
          <React.Fragment key={index}>
            <div
              className="mt-2 d-flex align-items-center justify-content-between animate__fadeInUp"
              style={{ animationDuration: "1s" }}
            >
              <p className="text-name-value-info-car">{data.description}</p>
              <p className="text-name-value-info-car">{data.value_description ? data.value_description : <MdClose style={{ fontSize: '18px' }} />}</p>
            </div>
            <div className="divider-info-car mt-2"></div>
          </React.Fragment>
        ))}
      <button className="mt-3 button-see-more-car-info" onClick={showMore}>
        {seeMore ? "Ver menos" : "Ver más"}
      </button>
    </div>
  );
};

export default CardInfoCarComponent;
