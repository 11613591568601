import React from 'react';
import '../styles/components/baseWhiteButton.css'

export default function BaseWhiteButtonComponent({ buttonText, buttonDisabled, onClick, size }) {

  return (
    <button className={`white-button ${size === 'small' ? 'small' : ''} `} disabled={buttonDisabled} onClick={onClick}>
      {buttonText}
    </button>
  )
}
