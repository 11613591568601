import React from "react";
import ImageGallery from "react-image-gallery";
import CarModalComponent from "./CarModalComponent";
import { useState } from "react";

const CarrouselCarComponent = ({ isPageWide, images }) => {
  const [showModal, setShowModal] = useState(false);
  const [fullScreenActive, setfullScreenActive] = useState(false);

  const customRenderThumbInner = (item) => {
    const isLastThumbnail = item === images[images.length - 1];

    return (
      <div className="custom-thumbnail-inner d-flex flex-row flex-lg-column ">
        <img
          src={item.thumbnail}
          alt={item.original}
          className="img-thumbnail"
        />
        {isLastThumbnail && !fullScreenActive && (
          <div className="ver-mas-button ms-2 ms-md-2 ms-lg-0  mt-lg-3 d-flex align-items-center justify-content-center">
            <button
              className="button-img  fullscreen-button"
              onClick={() => setShowModal(true)}
            ></button>
          </div>
        )}
      </div>
    );
  };

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <div className="mt-2 mt-md-3 mb-1" style={{ width: "100%" }}>
      <ImageGallery
        items={images}
        // thumbnailPosition={isPageWide ? "right" : "bottom"}
        // renderThumbInner={customRenderThumbInner}
        showPlayButton={false}
        showBullets={true}
        showFullscreenButton={false}
        // additionalClass="custom-thumbnail-position"
        onClick={handleClick}
        showThumbnails={false}
      />
      <CarModalComponent
        show={showModal}
        setShowModal={setShowModal}
        imageGallery={
          <ImageGallery
            items={images}
            showPlayButton={false}
            showBullets={true}
            showFullscreenButton={false}
            showThumbnails={false}
          />
        }
      />
    </div>
  );
};

export default CarrouselCarComponent;
