import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CardAboutUsComponent } from "./CardAboutUsComponent";
import { TitleHomeWithIcon } from "./TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import carPhoto from "../images/car-about-us-auta-mobile.png";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const AboutUsComponent = ({ isPageWide }) => {
  let breakPoint = useMediaQuery("(min-width: 990px)");
  let breakPointMobile = useMediaQuery("(min-width: 430px)");
  let breakPointLg = useMediaQuery("(min-width: 1200px)");

  return (
    <div className="containerAboutUs mt-3 mt-md-5 mb-5">
      <div className="containerCarBackgroundAboutUs">
        <Container className="d-flex flex-column align-items-center justify-content-center p-all">
          <div style={{ marginTop: breakPointMobile ? "" : "-30px" }}>
            <TitleHomeWithIcon
              title={"Conocé más sobre nosotros."}
              fontSize={isPageWide ? "24px" : "14px"}
              colorText={colors.white.white}
              colorIcon={colors.white.white}
            />
          </div>
          <Container className="">
            <Row className="mb-5 mt-5">
              <Col lg={6}>
                <p className="subtitleAboutUs">
                  ¡Hola! <br /> Somos Auta.
                </p>
                <p className="descriptionAboutUs mt-5">
                  Nos gusta vernos como una empresa que <br /> busca siempre
                  innovar y que tiene como <br /> objetivo principal hacer tu
                  experiencia de <br /> compra, venta o renovación lo más
                  simple,
                  <br /> práctica y sencilla.
                  <br />
                  <br />
                  Pero en serio.
                  <br />
                  <br />
                  Porque estuvimos y nos ponemos en tu lugar, <br /> buscamos
                  que tus transacciones sean lo más <br /> transparentes y
                  seguras posibles, por eso <br /> contamos con el respaldo de
                  ND Group, una <br /> empresa líder con más de 30 años de{" "}
                  <br /> experiencia en el mercado automotriz.
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
        {breakPoint ? (
          <div style={{ height: "180px", width: "100%" }} />
        ) : (
          <img src={carPhoto} alt="client" className="card-photo" />
        )}
        <Container className="d-flex flex-column align-items-center justify-content-center p-all">
          <div className="mb-4">
            <TitleHomeWithIcon
              title={
                isPageWide ? (
                  <>Creemos en estos valores.</>
                ) : (
                  <p className="subtitleAboutUs">
                    Creemos en <br /> estos valores.
                  </p>
                )
              }
              fontSize={"24px"}
              colorText={colors.white.white}
              colorIcon={colors.white.white}
            />
          </div>
          <Row>
            <Col lg={6}>
              <CardAboutUsComponent
                number={"1"}
                title={"Confianza"}
                description={
                  <>
                    Es la que vas a tener siempre durante todo el proceso{" "}
                    {!breakPointLg && <br />} de
                    {breakPointLg && <br />}
                    compra o venta de tu vehículo. {!breakPointLg && (
                      <br />
                    )}{" "}
                    Realizá todas tus operaciones u obtené financiación con la
                    garantía y seguridad que necesitás.
                  </>
                }
              />
            </Col>
            <Col lg={6}>
              <CardAboutUsComponent
                number={"2"}
                title={"Transparencia"}
                description={
                  <>
                    Ya sea en la búsqueda de tu próximo auto como obteniendo tu{" "}
                    {breakPointLg && <br />}
                    cotización para vender tu auto vas a tener la tranquilidad
                    de {breakPointLg && <br />} contar siempre con el mejor
                    respaldo en cada momento.
                  </>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <CardAboutUsComponent
                number={"3"}
                title={"Respaldo"}
                description={
                  <>
                    Contamos con un equipo multidisciplinario dinámico y{" "}
                    {breakPointLg && <br />}
                    profesional que va a saber responder todas tus consultas en
                    {breakPointLg && <br />}
                    tiempo y forma. Y como si fuera poco vas a tener el respaldo
                    {breakPointLg && <br />}
                    financiero de MG Group.
                  </>
                }
              />
            </Col>
            <Col lg={6}>
              <CardAboutUsComponent
                number={"4"}
                title={"Innovación"}
                description={
                  <>
                    Nos mueve innovar en cada instancia de los procesos,{" "}
                    {breakPointLg && <br />}
                    permitiendo minimizar los riesgos que existen en el mercado,
                    {breakPointLg && <br />}
                    ofreciéndote una compra garantizada e inmediata con los más
                    altos estándares de calidad.
                  </>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
