import React, { useEffect, useState } from "react";
import { TitleHomeWithIcon } from "../../../Home/components/TitleHomeWithIcon";
import { colors } from "../../../../styles/colors";
import { Container, Spinner } from "react-bootstrap";
import BaseDropDownComponent from "../../../../components/BaseDropDownComponent";
import BaseDropDownItemsComponent from "../../../../components/BaseDropDownItemsComponent";
import {
  getBrands,
  getModels,
  getVersion,
  years,
} from "../../../Home/services/fetchDataCar";
import BaseSearchInputComponent from "../../../../components/BaseSearchInputComponent";
import BaseRadioButtonGroup from "../../../../components/BaseRadioButtonGroup";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import BaseModalComponet from "../../../../components/BaseModalComponet";
import ManualQuoteModalComponent from "../../../../components/ManualQuoteModalComponent";
import ModalStopperComponent from "../Modals/ModalStopperComponent";

export const StepperDesktopComponent = ({
  selectedOptions,
  setSelectedOptions,
  number,
  setNumber,
  isGnc,
  setIsGnc,
  handleStartClick,
  toggleRenewModal,
  isRenewManualModalOpen,
}) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [dataBrands, setDataBrands] = useState();
  const [dataBrandsSearch, setDataBrandsSearch] = useState();
  const [dataModels, setDataModels] = useState();
  const [dataModelsSearch, setDataModelsSearch] = useState();
  const [dataVersions, setDataVersions] = useState();
  const [dataVersionsSearch, setDataVersionsSearch] = useState();
  const [showDropdownYear, setShowDropdownYear] = useState(false);
  const [showDropdownBrand, setShowDropdownBrand] = useState(false);
  const [showDropdownModel, setShowDropdownModel] = useState(false);
  const [showDropdownVersion, setShowDropdownVersion] = useState(false);
  const [showDropdownKms, setShowDropdownKms] = useState(false);
  const [showModalStopper, setShowModalStopper] = useState(false);

  useEffect(() => {
    getBrands(selectedOptions?.year?.name, setDataBrands, setDataBrandsSearch);
  }, [selectedOptions?.year]);

  useEffect(() => {
    getModels(
      selectedOptions?.brand?.name,
      selectedOptions?.year?.name,
      setDataModels,
      setDataModelsSearch
    );
  }, [selectedOptions?.brand]);

  useEffect(() => {
    getVersion(
      selectedOptions?.year?.name,
      selectedOptions?.brand?.name,
      selectedOptions?.model?.name,
      setDataVersions,
      setDataVersionsSearch
    );
  }, [selectedOptions?.model]);

  const handleInputChange = (e) => {
    setIsGnc("");
    const inputValue = e.target.value.replace(/\D/g, ""); // Elimina caracteres no numéricos
    if (inputValue === "") {
      setNumber(""); // Si inputValue está vacío, establece el estado como una cadena vacía
    } else {
      const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      setNumber(formattedNumber);
    }
  };

  const handleOptionSelect = (option, value, id) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [option]: { id: id, name: value },
    }));
  };

  const handleItemClick = (selectedValue) => {
    setSelectedItem(selectedValue);
  };
  const isFormValid = isGnc !== "" && number !== "";

  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-between  mt-4"
        style={{ height: "100%" }}
      >
        <div style={{ width: "100%" }}>
          <TitleHomeWithIcon
            title={"¡Cargá tu auto!"}
            fontSize={"28px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
          />
          <Container
            className="d-flex align-items-start justify-content-around mt-2"
            style={{ height: "450px" }}
          >
            <BaseDropDownComponent
              type={"year"}
              textButton={"¿De qué año es tu auto?"}
              onItemClick={(selectedItem, id) => {
                handleOptionSelect("year", selectedItem, id);
                handleItemClick(selectedItem, id);
                setShowDropdownBrand(true);
                setShowDropdownYear(false);
              }}
              isDisabled={false} // Siempre habilitado en el primer paso
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions?.year?.name}
              dataSelectedOptions={selectedOptions}
              setShowDropdown={setShowDropdownYear}
              showDropdown={showDropdownYear}
              setShowDropdownBrand={setShowDropdownBrand}
              setShowDropdownModel={setShowDropdownModel}
              setShowDropdownVersion={setShowDropdownVersion}
              setShowDropdownKms={setShowDropdownKms}
              setShowDropdownYear={setShowDropdownYear}
            >
              {!years ? (
                <Spinner
                  animation="border"
                  style={{
                    margin: "10px",
                    color: " #7340d8",
                  }}
                />
              ) : (
                <BaseDropDownItemsComponent
                  items={years}
                  onItemClick={handleItemClick}
                  selectedItem={selectedItem}
                  isYear={true}
                />
              )}
            </BaseDropDownComponent>
            <BaseDropDownComponent
              type={"brand"}
              textButton={"¿Qué marca es tu auto?"}
              onItemClick={(selectedItem, id) => {
                handleOptionSelect("brand", selectedItem, id);
                handleItemClick(selectedItem, id);
                setShowDropdownModel(true);
                setShowDropdownBrand(false);
              }}
              isDisabled={!selectedOptions.year} // Deshabilitado si no se ha seleccionado el año
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions?.brand?.name}
              dataSelectedOptions={selectedOptions}
              setShowDropdown={setShowDropdownBrand}
              showDropdown={showDropdownBrand}
              setShowDropdownBrand={setShowDropdownBrand}
              setShowDropdownModel={setShowDropdownModel}
              setShowDropdownVersion={setShowDropdownVersion}
              setShowDropdownKms={setShowDropdownKms}
              setShowDropdownYear={setShowDropdownYear}
            >
              <BaseSearchInputComponent
                searchText={"Buscá la marca"}
                dataSearch={dataBrandsSearch}
                setDataSearch={setDataBrands}
              />
              {!dataBrands ? (
                <Spinner
                  animation="border"
                  style={{
                    margin: "40%",
                    color: " #7340d8",
                  }}
                />
              ) : (
                <BaseDropDownItemsComponent
                  items={dataBrands}
                  onItemClick={handleItemClick}
                />
              )}
            </BaseDropDownComponent>
            <BaseDropDownComponent
              type={"model"}
              textButton={"¿Qué modelo es tu auto?"}
              onItemClick={(selectedItem, id) => {
                handleOptionSelect("model", selectedItem, id);
                handleItemClick(selectedItem, id);
                setShowDropdownVersion(true);
                setShowDropdownModel(false);
              }}
              isDisabled={!selectedOptions.brand}
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions?.model?.name}
              dataSelectedOptions={selectedOptions}
              setShowDropdown={setShowDropdownModel}
              showDropdown={showDropdownModel}
              setShowDropdownBrand={setShowDropdownBrand}
              setShowDropdownModel={setShowDropdownModel}
              setShowDropdownVersion={setShowDropdownVersion}
              setShowDropdownKms={setShowDropdownKms}
              setShowDropdownYear={setShowDropdownYear}
            >
              <BaseSearchInputComponent
                searchText={"Buscá el modelo"}
                dataSearch={dataModelsSearch}
                setDataSearch={setDataModels}
              />
              {!dataModels ? (
                <Spinner
                  animation="border"
                  style={{
                    margin: "40%",
                    color: " #7340d8",
                  }}
                />
              ) : (
                <BaseDropDownItemsComponent
                  items={dataModels}
                  onItemClick={handleItemClick}
                />
              )}
            </BaseDropDownComponent>
            <BaseDropDownComponent
              type={"version"}
              textButton={"¿Qué versión es tu auto?"}
              onItemClick={(selectedItem, id) => {
                handleOptionSelect("version", selectedItem, id);
                handleItemClick(selectedItem, id);
                setShowDropdownKms(true);
                setShowDropdownVersion(false);
              }}
              isDisabled={!selectedOptions.model} // Deshabilitado si no se ha seleccionado el modelo
              setSelectedOptions={setSelectedOptions}
              selectedOptions={selectedOptions?.version?.name}
              dataSelectedOptions={selectedOptions}
              setShowDropdown={setShowDropdownVersion}
              showDropdown={showDropdownVersion}
              setShowDropdownBrand={setShowDropdownBrand}
              setShowDropdownModel={setShowDropdownModel}
              setShowDropdownVersion={setShowDropdownVersion}
              setShowDropdownKms={setShowDropdownKms}
              setShowDropdownYear={setShowDropdownYear}
            >
              <BaseSearchInputComponent
                searchText={"Buscá la version"}
                dataSearch={dataVersionsSearch}
                setDataSearch={setDataVersions}
              />
              {!dataVersions ? (
                <Spinner
                  animation="border"
                  style={{
                    margin: "40%",
                    color: " #7340d8",
                  }}
                />
              ) : (
                <BaseDropDownItemsComponent
                  items={dataVersions}
                  onItemClick={handleItemClick}
                />
              )}
            </BaseDropDownComponent>
            <BaseDropDownComponent
              textButton={"¿Cuántos kms tiene tu auto?"}
              onItemClick={(selectedItem) => {
                handleOptionSelect("kms", selectedItem);
                handleItemClick(selectedItem);
              }}
              isDisabled={!selectedOptions.version}
              setShowDropdown={setShowDropdownKms}
              showDropdown={showDropdownKms}
              setShowDropdownBrand={setShowDropdownBrand}
              setShowDropdownModel={setShowDropdownModel}
              setShowDropdownVersion={setShowDropdownVersion}
              setShowDropdownKms={setShowDropdownKms}
              setShowDropdownYear={setShowDropdownYear}
              type={"kms"}
            >
              <div
                style={{ height: "100%" }}
                className="d-flex flex-column align-items-center justify-content-between"
              >
                <div style={{ height: "100%" }}>
                  <input
                    type="text"
                    className="base-input"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    placeholder="Ej: 24.000"
                    value={number}
                    onChange={handleInputChange}
                  />
                  <div className="dropdown-item-divider" />
                  <div
                    style={{
                      marginTop: "18px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <BaseRadioButtonGroup
                      isGnc={isGnc}
                      setIsGnc={setIsGnc}
                      kms={number}
                      version_id={selectedOptions?.version?.id}
                      setShowModalStopper={setShowModalStopper}
                    />
                  </div>
                </div>
                <div
                  style={{ height: "100%" }}
                  className="d-flex align-items-center justify-content-end"
                >
                  <BasePurpleButtonComponent
                    buttonText={"Continuar"}
                    onClick={handleStartClick}
                    buttonDisabled={!isFormValid}
                  />
                </div>
              </div>
            </BaseDropDownComponent>
          </Container>
        </div>
        <div
          className="d-flex w-100 justify-content-center"
          style={{ cursor: "pointer" }}
        >
          <a
            href="https://wa.me/+5491138876132"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <p className="modal-footer-custom">
              ¿No lográs cotizar tu vehículo?
              <br />
              <strong>Consulta con un asesor y recibí tu cotzación.</strong>
            </p>
          </a>
        </div>
        <BaseModalComponet
          isOpen={isRenewManualModalOpen}
          onClose={toggleRenewModal}
          size={"xs"}
        >
          <ManualQuoteModalComponent />
        </BaseModalComponet>
      </div>
      <ModalStopperComponent
        showModalStopper={showModalStopper}
        setShowModalStopper={setShowModalStopper}
        setSelectedOptions={setSelectedOptions}
        setNumber={setNumber}
        setIsGnc={setIsGnc}
        setShowDropdownKms={setShowDropdownKms}
      />
    </>
  );
};
