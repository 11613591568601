export const colors = {
  purple: {
    blackPurple: "#180E31",
    strokeDarkPurple: "#321670",
    darkPurple: "#482481",
    strokePurple: "#7340D8",
    purple: "#864BFA",
    lightPurple: "#D7C9EE",
    utraLightPurple: "#E8E1EF",
  },
  gray: {
    darkGrey: "#D7D7D7",
    lightGrey: "#EEEEEE",
    bone: "#EEECEA",
    strokeGray: "#CECECE",
    disabled: "#E2E2E2",
    coolGray: "#C8C7CC",
  },
  white: {
    white: "#FFFFFF",
  },
  red: {
    darkRed: "#E04022",
    red: "#FF5900",
  },
  green: {
    darkGreen: "#6ACC3C",
    lightGreen: "#76E543",
  },
};
