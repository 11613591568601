import React from 'react';
import '../styles/components/headerSessionButton.css'

export default function HeaderSessionButtonComponent({ buttonText, buttonDisabled, onClick }) {
  return (
    <button className="session-button" disabled={buttonDisabled} onClick={onClick}>
      {buttonText}
    </button>
  )
}
