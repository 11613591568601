import React, { useEffect } from "react";
import "../TechnicalData/style/technicalData.css";
import "animate.css";
import TechnicalDataComponent from "./components/TechnicalDataComponent";
import { useSpinner } from "../../context/spinnerContext";
import SpinnerComponent from "../../components/SpinnerComponent";

const TechnicalDataPage = () => {
  const { showLoading, hideLoading, showSpinner } = useSpinner();
  useEffect(() => {
    showLoading()
    setTimeout(() => {
      hideLoading()
    }, 1000);

  }, [])
  return (
    <>
      {showSpinner ? (
        <SpinnerComponent />
      ) : (
        <TechnicalDataComponent />)}
    </>
  );
};

export default TechnicalDataPage;
