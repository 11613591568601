import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { MdOutlineClose } from "react-icons/md";
import { colors } from "../../../styles/colors";
import { RangeSlider } from "./MultiRangeSliderKm";
import Switch from "react-switch";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import GridTypeCarCatalogComponent from "./GridTypeCarCatalogComponent";
import { useCurrency } from "../../../context/CurrencyContext";

export const FiltersComponent = ({ isSettings }) => {
  const [checked, setChecked] = useState(false);
  const [checkedPublicados, setCheckedPublicados] = useState(false);
  const [valueKm, setValueKm] = React.useState({ min: 0, max: 600000 });
  const [valuePrice, setValuePrice] = React.useState({ min: 0, max: 2000000 });
  let isPageWide = useMediaQuery("(min-width: 768px)");
  const [selectedCar, setSelectedCar] = useState([]);
  const { isDollars, toggleCurrency } = useCurrency();

  const handleChange = (newChecked) => {
    setChecked(newChecked);
  };

  const handleChangePublicados = (newChecked) => {
    setCheckedPublicados(newChecked);
  };

  const changeValueTypeMoney = () => {
    toggleCurrency();
  };

  const deleteTypeCar = (data) => {
    setSelectedCar((state) => state.filter((item) => item.id !== data.id));
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <p className="textTitleFilter mb-1">Filtrar por:</p>
        <p className="textCleanFilter">Limpiar filtros</p>
      </div>
      <div className="dividerFilter"></div>
      <Accordion defaultActiveKey="0" className="mt-3">
        {!isPageWide && !isSettings ? (
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="d-flex flex-column">
                <p className="textNameFilter">Ordenar por</p>
              </div>
            </Accordion.Header>
            <div
              className="d-flex align-items-center flex-wrap mt-2"
              style={{ width: "85%" }}
            >
              <div className="d-flex align-items-center justify-content-between filterSelected mb-2">
                <p className="textFilterSelected ms-1">Más relevantes</p>
                <MdOutlineClose
                  fontSize="14px"
                  color={colors.purple.purple}
                  className="ms-2"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <Accordion.Body>
              <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                <p className="optionFilter ms-1">Opcion</p>
                <p className="quantity ms-1">(12)</p>
              </div>
              <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                <p className="optionFilter ms-1">Opcion</p>
                <p className="quantity ms-1">(12)</p>
              </div>
              <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                <p className="optionFilter ms-1">Opcion</p>
                <p className="quantity ms-1">(12)</p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ) : null}
        {!isSettings ? (
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="d-flex flex-column">
                <p className="textNameFilter">Tipo de auto</p>
              </div>
            </Accordion.Header>
            <div
              className="d-flex align-items-center flex-wrap mt-2"
              style={{ width: "100%" }}
            >
              {selectedCar?.map((car, i) => (
                <div
                  className="d-flex align-items-center justify-content-between filterSelected mb-2 me-1"
                  key={i}
                >
                  <p className="textFilterSelected ms-1">{car.text}</p>
                  <MdOutlineClose
                    fontSize="14px"
                    color={colors.purple.purple}
                    className="ms-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTypeCar(car)}
                  />
                </div>
              ))}
            </div>
            <Accordion.Body>
              <GridTypeCarCatalogComponent
                setSelectedCar={setSelectedCar}
                selectedCar={selectedCar}
              />
            </Accordion.Body>
          </Accordion.Item>
        ) : null}
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <div className="d-flex flex-column">
              <p className="textNameFilter">Año</p>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <div className="d-flex flex-column">
              <p className="textNameFilter">Marca</p>
            </div>
          </Accordion.Header>
          <div
            className="d-flex align-items-center flex-wrap mt-2"
            style={{ width: "85%" }}
          >
            <div className="d-flex align-items-center justify-content-between filterSelected mb-2">
              <p className="textFilterSelected ms-1">Fiat</p>
              <MdOutlineClose
                fontSize="14px"
                color={colors.purple.purple}
                className="ms-2"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <Accordion.Body>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            <div className="d-flex flex-column">
              <p className="textNameFilter">Modelo</p>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <p className="textNameFilter mt-2">Kilometraje</p>
      <RangeSlider
        min={0}
        max={600000}
        step={1}
        value={valueKm}
        onChange={setValueKm}
      />
      <div className="dividerOptionsFilter mt-1"></div>
      <div className="d-flex align-items-center justify-content-between">
        <p className="textNameFilter mt-2">Precio</p>
        <div className="mt-2 d-flex align-items-center justify-content-between container-switch-type-money">
          {!isDollars ? (
            <>
              <div
                className="container-active-switch-type-money d-flex align-items-center justify-content-center"
                onClick={changeValueTypeMoney}
              >
                <p className="text-active-type-money">ARS$</p>
              </div>
              <div onClick={changeValueTypeMoney} style={{ cursor: "pointer" }}>
                <p className="text-disabled-type-money me-2">U$D</p>
              </div>
            </>
          ) : (
            <>
              <div onClick={changeValueTypeMoney} style={{ cursor: "pointer" }}>
                <p className="text-disabled-type-money ms-2">ARS$</p>
              </div>
              <div
                className="container-active-switch-type-money d-flex align-items-center justify-content-center"
                onClick={changeValueTypeMoney}
              >
                <p className="text-active-type-money">U$D</p>
              </div>
            </>
          )}
        </div>
      </div>
      <RangeSlider
        min={0}
        max={20000000}
        step={1}
        value={valuePrice}
        onChange={setValuePrice}
      />
      {!isSettings ? (
        <>
          <div className="dividerOptionsFilter mt-1"></div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <p className="textNameFilter">Con financiamiento</p>
            <label htmlFor="material-switch">
              <Switch
                checked={checked}
                onChange={handleChange}
                offColor={colors.purple.utraLightPurple}
                offHandleColor={colors.purple.lightPurple}
                onColor={colors.purple.utraLightPurple}
                onHandleColor={colors.purple.purple}
                handleDiameter={14}
                uncheckedIcon={false}
                checkedIcon={false}
                height={10}
                width={24}
                boxShadow="0px 0px 0px"
                activeBoxShadow="0px 0px 0px 0px"
                className="react-switch"
                id="material-switch"
              />
            </label>
          </div>
          <div className="dividerOptionsFilter mt-1"></div>
          <div className="d-flex align-items-center justify-content-between mt-2">
            <p className="textNameFilter">Publicados hoy</p>
            <label htmlFor="material-switch">
              <Switch
                checked={checkedPublicados}
                onChange={handleChangePublicados}
                offColor={colors.purple.utraLightPurple}
                onColor={colors.purple.utraLightPurple}
                offHandleColor={colors.purple.lightPurple}
                onHandleColor={colors.purple.purple}
                handleDiameter={14}
                uncheckedIcon={false}
                checkedIcon={false}
                height={10}
                width={24}
                boxShadow="0px 0px 0px"
                activeBoxShadow="0px 0px 0px 0px"
                className="react-switch"
                id="material-switch"
              />
            </label>
          </div>
        </>
      ) : null}
      <div className="dividerOptionsFilter mt-1"></div>{" "}
      <Accordion defaultActiveKey="0" className="mt-3">
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <div className="d-flex flex-column">
              <p className="textNameFilter">Color</p>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
            <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
              <p className="optionFilter ms-1">Opcion</p>
              <p className="quantity ms-1">(12)</p>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        {!isSettings ? (
          <>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <div className="d-flex flex-column">
                  <p className="textNameFilter">Transmisión</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <div className="d-flex flex-column">
                  <p className="textNameFilter">Plazas</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <div className="d-flex flex-column">
                  <p className="textNameFilter">Combustible</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4 ">
              <Accordion.Header>
                <div className="d-flex flex-column">
                  <p className="textNameFilter">Ubicación</p>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
                <div className="d-flex mb-2" style={{ cursor: "pointer" }}>
                  <p className="optionFilter ms-1">Opcion</p>
                  <p className="quantity ms-1">(12)</p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </>
        ) : null}
      </Accordion>
    </div>
  );
};
