import React from "react";
import { colors } from "../../../styles/colors";

export const TimeLineLeftComponent = ({ children, incompleted }) => {
  return (
    <div style={{ width: "250px" }} className="d-flex">
      <div
        style={{
          width: "100%",
          borderRight: `6px solid ${
            incompleted ? colors.gray.lightGrey : colors.purple.purple
          }`,
          marginLeft: "-41px",
        }}
        className="d-flex align-items-center justify-content-end pb-4"
      >
        {children}
      </div>
      <div style={{ width: "100%" }}></div>
    </div>
  );
};
