import React from "react";
import IconAttach from "../images/icon-attach.svg";
import CarExample from "../images/car-example.png";
import IconDelete from "../images/icon-delete.svg";
import { ProgressBar } from "react-bootstrap";

export const AttachFilesComponent = () => {
  return (
    <div className="mt-3">
      <p className="label-input">Fotografías del vehiculo</p>
      <div className="container-files">
        <div className="d-flex align-items-center">
          <img src={IconAttach} alt="icon-attach" className="icon-attach" />
          <p className="text-attach-files">Adjuntar fotografias</p>
        </div>
        <div className="divider-technical-data mt-4 mb-2"></div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <img src={CarExample} alt="car-auta" className="car-table" />
              <p className="ms-2 text-name-file">Nombre del archivo.png</p>
            </div>
            <div className="d-flex">
              <ProgressBar variant="success" now={40} />
              <img src={IconDelete} alt="icon-delete" className="icon-delete" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex align-items-center">
              <img src={CarExample} alt="car-auta" className="car-table" />
              <p className="ms-2 text-name-file">Nombre del archivo.png</p>
            </div>
            <div className="d-flex">
              <ProgressBar variant="success" now={40} />
              <img src={IconDelete} alt="icon-delete" className="icon-delete" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img src={CarExample} alt="car-auta" className="car-table" />
              <p className="ms-2 text-name-file">Nombre del archivo.png</p>
            </div>
            <div className="d-flex">
              <ProgressBar variant="success" now={40} />
              <img src={IconDelete} alt="icon-delete" className="icon-delete" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
