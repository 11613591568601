import React from "react";
import { AiFillStar } from "react-icons/ai";
import { colors } from "../../../styles/colors";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const StarsComponent = () => {
  let isPageWide = useMediaQuery("(min-width: 768px)");

  return (
    <>
      <div
        className="containerStar d-flex align-items-center justify-content-center me-2"
        style={{
          width: isPageWide ? "40px" : "24px",
          height: isPageWide ? "40px" : "24px",
        }}
      >
        <AiFillStar
          fontSize={isPageWide ? "26px" : "16px"}
          color={colors.purple.purple}
        />
      </div>
    </>
  );
};
