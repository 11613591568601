import React from "react";
import { colors } from "../styles/colors";

const ButtonWithIconComponent = ({
  backgroundColor,
  borderColor,
  width,
  height,
  text,
  icon,
  sizeContainerIcon,
  colorText,
  fontSize,
}) => {
  const styleButton = {
    backgroundColor: backgroundColor,
    borderRadius: "200px",
    border: "3px solid",
    borderColor: borderColor,
    width: width,
    height: height,
    cursor: "pointer",
  };

  const styleContainerIcon = {
    backgroundColor: borderColor,
    width: "46px",
    height: sizeContainerIcon,
    borderRadius: "50%",
  };

  const styleText = {
    color: colorText,
    fontSize: fontSize ? "13px" : "14px",
    fontWeight: "700",
    textAlign: "center",
    width: "100%",
  };

  return (
    <div
      style={styleButton}
      className="d-flex align-items-center justify-content-between"
    >
      <p style={styleText}>{text}</p>
      <div
        style={styleContainerIcon}
        className="d-flex align-items-center justify-content-center"
      >
        <img src={icon} className="ms-1" />
      </div>
    </div>
  );
};

export default ButtonWithIconComponent;
