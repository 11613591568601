import React, { useEffect, useState } from "react";
import CardDataTechnicalComponent from "./CardDataTechnicalComponent";
import CarrouselCarComponent from "./CarrouselCarComponent";
import CardInfoCarComponent from "./CardInfoCarComponent";
import "animate.css";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import ArrowLeftBack from "../images/arrow-left-back.svg";
import { HeaderComponent } from "../../../components/HeaderComponent";
import { Container } from "react-bootstrap";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SpinnerComponent from "../../../components/SpinnerComponent";
import { useAuth } from "../../../context/AuthContext";
import { get } from "react-hook-form";

const TechnicalDataComponent = () => {
  const { isAuthenticated } = useAuth();
  const [expanded, setExpanded] = useState(false);
  const [carData, setCarData] = useState(null);
  const [isCarLiked, setIsCarLiked] = useState(false);
  const [dataHalf1, setDataHalf1] = useState();
  const [dataHalf2, setDataHalf2] = useState();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  let isPageWide = useMediaQuery("(min-width: 992px)");
  let isPageWideTwo = useMediaQuery("(min-width: 1200px)");
  const navigate = useNavigate();
  let location = useLocation();
  const { car_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `https://backend-web-dot-admin-dev-355313.rj.r.appspot.com/cars/get_car?car_id=${car_id}`
        );
        setCarData(result?.data?.car);
        setDataHalf1(
          result?.data?.car?.car_data?.slice(
            0,
            Math.ceil(result?.data?.car?.car_data.length / 2)
          )
        );
        setDataHalf2(
          result?.data?.car?.car_data?.slice(
            Math.ceil(result?.data?.car?.car_data.length / 2)
          )
        );
        setImages(
          result?.data?.car?.photos.map((url, index) => ({
            original: url,
            thumbnail: url,
            description: `Image ${index + 1}`,
          }))
        );
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [car_id]);

  useEffect(() => {
    if (carData) {
      checkCarLiked();
    }
  }, [carData]);

  const checkCarLiked = async () => {
    if (isAuthenticated) {
      try {
        const result = await get("/users/get_user_likes", null);
        const isLiked = result?.likes?.some(
          (like) => like.car_id === carData?.car_id
        );
        setIsCarLiked(isLiked);
      } catch (error) {
        console.error("Error al obtener datos:", error);
      } finally {
        setLoading(false); // Esto se ejecutará independientemente del resultado de la llamada
      }
    }
  };
  const navigateBack = () => {
    if (location?.state?.from === "/wishlist") {
      navigate("/wishlist");
    } else {
      navigate("/cars", {
        state: { typeLayout: location?.state?.typeLayout },
      });
    }
  };

  return (
    <>
      {loading ? (
        <SpinnerComponent />
      ) : (
        <>
          <HeaderComponent expanded={expanded} setExpanded={setExpanded} />
          <Container className={`${expanded ? "blur-background" : ""}`}>
            <div className="d-flex align-items-center justify-content-center">
              <div
                className="container-technical-data-layout mt-2 mt-md-4"
                style={{ width: "90%" }}
              >
                <div
                  className="d-flex align-items-center justify-content-center flex-column"
                  style={{ width: "100%" }}
                >
                  <div
                    className="d-flex align-items-start justify-content-start me-4 mb-lg-0"
                    style={{
                      width: "100%",
                    }}
                  >
                    <img
                      src={ArrowLeftBack}
                      alt="arrow-left-back"
                      className="arrow-left-back"
                      onClick={navigateBack}
                    />
                    <p
                      className="me-5 mt-2 text-back-catalog"
                      onClick={navigateBack}
                    >
                      {location?.state?.from === "/wishlist"
                        ? "Volver atrás"
                        : "Catálogo"}
                    </p>
                  </div>
                  <div className="d-flex align-items-start justify-content-between container-technical-data-layout ">
                    <CarrouselCarComponent
                      isPageWide={isPageWide}
                      images={images}
                    />
                    <CardDataTechnicalComponent
                      isPageWide={isPageWideTwo}
                      carData={carData}
                      isCarLiked={isCarLiked}
                      setIsCarLiked={setIsCarLiked}
                    />
                  </div>
                  <div
                    className="d-flex align-items-start justify-content-between  flex-wrap"
                    style={{ width: "100%" }}
                  >
                    <CardInfoCarComponent carData={dataHalf1} />
                    <CardInfoCarComponent carData={dataHalf2} />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};

export default TechnicalDataComponent;
