import React, { useState, useRef, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../components/BasePurpleButtonComponent";
import { TitleHomeWithIcon } from "../../Home/components/TitleHomeWithIcon";
import { colors } from "../../../styles/colors";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  signInWithCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../../firebase/firebase";
import "../style/RegistrationAndLoginModal.css";
import BaseWhiteButtonComponent from "../../../components/BaseWhiteButtonComponent";
import { post } from "../../../utils/apiService";
import fetchDataPhoneNumberExists from "../../../utils/validatePhoneNumberExists";
import { useAuth } from "../../../context/AuthContext";

export default function RegistrationModalComponent({
  setShowLogin,
  toggleLoginModal,
}) {
  const [isLoading, setIsLoading] = useState();
  const [currentView, setCurrentView] = useState("step1");
  const [isDisabledB, setIsDisabledB] = useState(false);
  const [countryCode, setCountryCode] = useState(""); // Estado para el código de país
  const [phoneNumber, setPhoneNumber] = useState(""); // Estado para el número de teléfono
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null); // Estado para el resultado de la confirmación
  const [verificationCodes, setVerificationCodes] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]); // Estado para el código de verificación
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]; // Referencias a los inputs
  const [textError, setTextError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const { isAuthenticated, setAuthenticated, setUser } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      const verifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible",
      });
      setRecaptchaVerifier(verifier);
    }
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    setIsDisabledB(true);
    e.preventDefault();

    try {
      const fullPhoneNumber = `+549${countryCode}${phoneNumber}`;
      // Llamada a la función fetchDataPhoneNumberExists para verificar si el número es válido
      const isPhoneNumberValid = await fetchDataPhoneNumberExists(
        fullPhoneNumber
      );

      if (isPhoneNumberValid) {
        // Si el número es válido, muestra una alerta y detén el flujo
        setTextError("Número de teléfono ya pertenece a una cuenta existente");
        return;
      }

      // Continuar con el proceso de autenticación si el número es válido
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        fullPhoneNumber,
        recaptchaVerifier
      );
      setConfirmationResult(confirmationResult);
      handleStartClick();
    } catch (error) {
      // Manejar errores como en tu implementación original
      switch (error.code) {
        case "auth/invalid-phone-number":
          // El número de teléfono es inválido
          setTextError("El número de teléfono es inválido");
          break;
        case "auth/quota-exceeded":
        case "auth/too-many-requests":
          // Se ha superado la cuota de SMS para este proyecto
          setTextError("Se ha superado la cuota de SMS");
          break;
        case "auth/captcha-check-failed":
          // Error de reCAPTCHA
          setTextError("Error de reCAPTCHA");
          break;
        default:
          // Otros errores
          console.error("Error al verificar el número de teléfono:", error);
          setTextError("Error al verificar el número de teléfono");
          break;
      }
    } finally {
      // Asegúrate de manejar la carga y la desactivación de los botones en la sección finally
      setIsLoading(false);
      setIsDisabledB(false);
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    // Verifica si el valor ingresado es un número o una cadena vacía y está en el rango de 0 a 9
    if (/^[0-9]$|^$/.test(value) && index >= 0 && index <= 5) {
      const newVerificationCodes = [...verificationCodes];
      newVerificationCodes[index] = value;
      setVerificationCodes(newVerificationCodes);

      // Mueve el foco al siguiente input solo si se ha ingresado un dígito
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    }
  };
  const authenticateUser = async () => {
    const enteredCode = verificationCodes.join("");
    const credential = PhoneAuthProvider.credential(
      confirmationResult.verificationId,
      enteredCode
    );
    await signInWithCredential(auth, credential);
    return auth.currentUser;
  };

  const handlePostRequest = async (user) => {
    const phone = user.phoneNumber;
    try {
      const result = await post("/users/create_user", { name, email, phone });
      setUser(result);
      setAuthenticated(true);
      handleStartClick();
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error.message);
    }
  };

  const handleVerification = async (e) => {
    e.preventDefault();
    setIsDisabledB(true);
    setIsLoading(true);
    try {
      const user = await authenticateUser();
      await handlePostRequest(user);
    } catch (error) {
      handleVerificationErrors(error);
    } finally {
      setIsLoading(false);
      setIsDisabledB(false);
    }
  };

  const handleVerificationErrors = (error) => {
    // Manejo de errores específicos
    switch (error.code) {
      case "auth/invalid-verification-code":
        setTextError("El código de verificación es inválido");
        break;
      case "auth/code-expired":
        setTextError("El código de verificación ha caducado");
        break;
      case "auth/credential-already-in-use":
        setTextError("La credencial ya está en uso por otro usuario");
        break;
      default:
        // Otros errores
        console.error("Error al verificar el código de verificación:", error);
        setTextError("Error al verificar el código.");
        break;
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(value)); // Verificar el formato del correo electrónico
  };
  const isFormValid =
    name !== "" &&
    email !== "" &&
    countryCode !== "" &&
    phoneNumber !== "" &&
    isEmailValid;

  const handleStartClick = () => {
    if (currentView === "step1") {
      setCurrentView("step2");
    } else if (currentView === "step2") {
      setCurrentView("step3");
    }
  };

  const handleCloseModals = () => {
    toggleLoginModal();
  };

  return (
    <>
      {currentView === "step1" ? (
        <>
          <TitleHomeWithIcon
            title={"Crea tu cuenta"}
            fontSize={"28px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
            isModal={true}
          />
          <div className="card-contact-data">
            <div className="card-contact-header">
              <p className="contact-header-title">Completa todos los campos</p>
            </div>
            <div style={{ padding: "12px", height: "364px" }}>
              <form
                onSubmit={handleSubmit}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="input-labels">Nombre completo</label>
                    <input
                      type="text"
                      className="base-input"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Ingresar tu nombre"
                    />
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="input-labels">Email</label>
                    <input
                      type="text"
                      className="base-input"
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Ingresar tu email"
                    />
                    {!isEmailValid ? (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        Formato de email invalido
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <label className="input-labels">Teléfono celular</label>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="phone-number">+549</p>
                      <input
                        type="text"
                        className="base-input"
                        placeholder="Ej: 11"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        maxLength={3}
                        style={{ width: "65px", marginRight: "10px" }}
                      />
                      <input
                        type="text"
                        className="base-input"
                        placeholder="Sin 15"
                        value={phoneNumber}
                        maxLength={8}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        textAlign: "center",
                      }}
                    >
                      {textError}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="security-code-text mt-5">
                    Recibirás un código de seguridad
                  </p>
                  {isLoading ? (
                    <Spinner
                      animation="border"
                      style={{
                        margin: "10px",
                        color: " #7340d8",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div id="recaptcha-container"></div>
                  <BasePurpleButtonComponent
                    buttonDisabled={!isFormValid || isLoading}
                    buttonText={isLoading ? "Enviando..." : "Continuar"}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <p
              className="bottom-text-onclick"
              onClick={() => setShowLogin(true)}
            >
              Ingresar con cuenta existente
            </p>
          </div>
        </>
      ) : currentView === "step2" ? (
        <>
          <TitleHomeWithIcon
            title={`¡Bienvenido ${name}!`}
            fontSize={"28px"}
            colorText={colors.purple.purple}
            colorIcon={colors.purple.purple}
          />
          <div className="card-contact-data">
            <div className="card-contact-header">
              <p className="contact-header-title">Verificación telefónica.</p>
            </div>
            <div
              style={{
                padding: "12px",
                height: "364px",
              }}
            >
              <form
                onSubmit={handleVerification}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: " space-between",
                  height: "100%",
                }}
              >
                <div style={{ marginTop: "10px" }}>
                  <p className="phone-number-two">
                    Ingresá el código que te enviamos por SMS al{" "}
                    <span className="phone-number-title">
                      +54{countryCode}
                      {phoneNumber}
                    </span>
                  </p>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {inputRefs.map((inputRef, index) => (
                      <input
                        key={index}
                        ref={inputRef}
                        type="text"
                        value={verificationCodes[index]}
                        className="code-input"
                        onChange={(e) => handleChange(e, index)}
                        maxLength="1"
                      />
                    ))}
                  </div>
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    {textError}
                  </p>
                </div>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    style={{
                      margin: "auto",
                      color: " #7340d8",
                      marginTop: "40px",
                    }}
                  />
                ) : (
                  ""
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <BasePurpleButtonComponent
                    buttonDisabled={isDisabledB}
                    buttonText={"Continuar"}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "80%",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p className="title mt-4">¡Bienvenido a Auta!</p>
            <p className="text mt-4">
              ¡Gracias por unirte a nuestra comunidad!
              <br />
              Esperamos que tu visita sea productiva y satisfactoria.
            </p>
          </div>
          <div className="d-flex  justify-content-center">
            <BaseWhiteButtonComponent
              buttonText={"Continuar"}
              onClick={handleCloseModals}
            />
          </div>
        </div>
      )}
    </>
  );
}
