import React from "react";

export const CardMyQuotesComponent = ({ isRenovar }) => {
  return (
    <div className="mb-2 container-card-my-quote">
      <div className="me-4">
        <p className="text-vehicle-quote">
          {isRenovar ? "Vehículo interesado" : "Mi vehículo cotizado"}
        </p>
        <p className="text-brand-model-version-year-km">
          Marca - Modelo - Versión
        </p>
        <div className="d-flex align-items-center justify-content-between">
          <p className="me-5 mt-3 text-brand-model-version-year-km">2018</p>
          <p className="me-5 mt-3 text-brand-model-version-year-km">68.000km</p>
          <div className="d-flex align-items-center justify-content-center mt-3 d-none d-md-block">
            <p className="text-gnc">
              ¿Tiene o tuvo GNC?{" "}
              <strong className="text-brand-model-version-year-km">NO</strong>
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start mt-3  d-md-none">
          <p className="text-gnc">¿Tiene o tuvo GNC?</p>
          <p className="text-brand-model-version-year-km ms-1">NO</p>
        </div>
      </div>
      <div className="divider-card-quote me-4"></div>
      <div className="d-flex flex-column align-items-start justify-content-center">
        <p className="text-vehicle-quote">
          {isRenovar ? "Precio" : "Cotización"}
        </p>
        <p className="price-quote mb-2">U$D 45.000</p>
        {isRenovar ? (
          <button className="btn-see-technical-data">Ver ficha técnica</button>
        ) : (
          <p className="text-quote-expired">Cotización válida hasta 01/03/24</p>
        )}
      </div>
    </div>
  );
};
