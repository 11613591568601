import React, { useState } from "react";
import { Carousel, Spinner } from "react-bootstrap";
import DropDownComponent from "../DropDownComponent";
import { years } from "../../../Home/services/fetchDataCar";
import BaseDropDownItemsComponent from "../../../../components/BaseDropDownItemsComponent";
import BaseSearchInputComponent from "../../../../components/BaseSearchInputComponent";
import BaseRadioButtonGroup from "../../../../components/BaseRadioButtonGroup";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { ViewStepOneComponent } from "./ViewStepOneComponent";
import { ViewStepTwoComponent } from "./ViewStepTwoComponent";
import { ViewStepFourComponent } from "./ViewStepFourComponent";
import { useAuth } from "../../../../context/AuthContext";
import SuccessComponent from "../../SuccessComponent";
import ModalStopperComponent from "../Modals/ModalStopperComponent";

export const CarrouselComponent = ({
  selectedOptions,
  selectedButton,
  isMobile,
  handleOptionSelect,
  handleItemClick,
  setSelectedOptions,
  handleButtonClick,
  selectedItem,
  dataBrandsSearch,
  dataBrands,
  dataModelsSearch,
  dataModels,
  dataVersionsSearch,
  dataVersions,
  setDataBrands,
  setDataModels,
  setDataVersions,
  handleInputChange,
  isGnc,
  setIsGnc,
  number,
  setNumber,
  currentView,
  textError,
  isLoading,
  handleSubmit,
  showloginView,
  setName,
  email,
  handleEmailChange,
  isEmailValid,
  countryCode,
  setCountryCode,
  phoneNumber,
  setPhoneNumber,
  loginAlert,
  setShowLoginView,
  setTextError,
  handleVerification,
  isDisabledB,
  name,
  inputRefs,
  verificationCodes,
  handleStartClick,
  handleChange,
  handleSubmitWithUser,
  setShowDropdownKms,
}) => {
  const { isAuthenticated, user } = useAuth();
  const [showModalStopper, setShowModalStopper] = useState(false);
  const isFormValidated =
    name !== "" &&
    email !== "" &&
    countryCode !== "" &&
    phoneNumber !== "" &&
    isEmailValid;
  const isFormValid = isGnc !== "" && number !== "";
  return (
    <>
      <Carousel
        controls={false}
        indicators={false}
        interval={null}
        activeIndex={selectedButton - 1}
      >
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿De qué año es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("year", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(2);
            }}
            isDisabled={false} // Siempre habilitado en el primer paso
            selectedOptions={selectedOptions?.year?.name}
          >
            {!years ? (
              <Spinner
                animation="border"
                style={{
                  margin: "10px",
                  color: " #7340d8",
                }}
              />
            ) : (
              <BaseDropDownItemsComponent
                items={years}
                onItemClick={handleItemClick}
                selectedItem={selectedItem}
                isYear={true}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"year"}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué marca es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("brand", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(3);
            }}
            isDisabled={!selectedOptions?.year}
            selectedOptions={selectedOptions?.brand?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá la marca"}
              dataSearch={dataBrandsSearch}
              setDataSearch={setDataBrands}
            />
            {!dataBrands ? (
              <Spinner
                animation="border"
                style={{
                  margin: "40%",
                  color: " #7340d8",
                }}
              />
            ) : (
              <BaseDropDownItemsComponent
                items={dataBrands}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"brand"}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué modelo es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("model", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(4);
            }}
            isDisabled={!selectedOptions?.brand}
            selectedOptions={selectedOptions?.model?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá el modelo"}
              dataSearch={dataModelsSearch}
              setDataSearch={setDataModels}
            />
            {!dataModels ? (
              <Spinner
                animation="border"
                style={{
                  margin: "40%",
                  color: " #7340d8",
                }}
              />
            ) : (
              <BaseDropDownItemsComponent
                items={dataModels}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"model"}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            isMobile={isMobile}
            textButton={"¿Qué versión es tu auto?"}
            onItemClick={(selectedItem, id) => {
              handleOptionSelect("version", selectedItem, id);
              handleItemClick(selectedItem, id);
              handleButtonClick(5);
            }}
            isDisabled={!selectedOptions?.model}
            selectedOptions={selectedOptions?.version?.name}
          >
            <BaseSearchInputComponent
              searchText={"Buscá la version"}
              dataSearch={dataVersionsSearch}
              setDataSearch={setDataVersions}
            />
            {!dataVersions ? (
              <Spinner
                animation="border"
                style={{
                  margin: "40%",
                  color: " #7340d8",
                }}
              />
            ) : (
              <BaseDropDownItemsComponent
                items={dataVersions}
                onItemClick={handleItemClick}
                isMobile={isMobile}
                setSelectedOptions={setSelectedOptions}
                dataSelectedOptions={selectedOptions}
                type={"version"}
              />
            )}
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          <DropDownComponent
            textButton={"¿Cuántos kms tiene tu auto?"}
            onItemClick={(selectedItem) => {
              handleOptionSelect("kms", selectedItem);
              handleItemClick(selectedItem);
              handleButtonClick(6);
            }}
            isDisabled={!selectedOptions?.version}
            isKms={true}
          >
            <input
              type="text"
              className="base-input mt-3 mb-3 text-center"
              value={number}
              onChange={handleInputChange}
              placeholder="Ej: 24.000"
            />

            <div
              style={{
                display: "flex",
                height: "290px",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: " space-between",
              }}
            >
              <BaseRadioButtonGroup
                isGnc={isGnc}
                setIsGnc={setIsGnc}
                kms={number}
                version_id={selectedOptions?.version?.id}
                setShowModalStopper={setShowModalStopper}
              />
              <div>
                <BasePurpleButtonComponent
                  buttonText={"Continuar"}
                  onClick={() => handleButtonClick(6)}
                  buttonDisabled={!isFormValid}
                />
              </div>
            </div>
          </DropDownComponent>
        </Carousel.Item>
        <Carousel.Item>
          {currentView === "step1" ? (
            <ViewStepOneComponent
              isAuthenticated={isAuthenticated}
              handleSubmitWithUser={handleSubmitWithUser}
              user={user}
              textError={textError}
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              showloginView={showloginView}
              setName={setName}
              email={email}
              handleEmailChange={handleEmailChange}
              isEmailValid={isEmailValid}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              setShowLoginView={setShowLoginView}
              setTextError={setTextError}
              isFormValidated={isFormValidated}
              loginAlert={loginAlert}
              name={name}
            />
          ) : currentView === "step2" ? (
            <ViewStepTwoComponent
              handleVerification={handleVerification}
              phoneNumber={phoneNumber}
              inputRefs={inputRefs}
              textError={textError}
              countryCode={countryCode}
              verificationCodes={verificationCodes}
              isLoading={isLoading}
              isDisabledB={isDisabledB}
              handleChange={handleChange}
            />
          ) : currentView === "step3" ? (
            <div className="mt-4" style={{ height: "100%" }}>
              <SuccessComponent
                handleClick={handleStartClick}
                isMobile={true}
                isRenewal={true}
              />
            </div>
          ) : currentView === "step4" ? (
            <ViewStepFourComponent />
          ) : null}
        </Carousel.Item>
      </Carousel>
      <ModalStopperComponent
        showModalStopper={showModalStopper}
        setShowModalStopper={setShowModalStopper}
        setSelectedOptions={setSelectedOptions}
        setNumber={setNumber}
        setIsGnc={setIsGnc}
        isMobile={true}
        handleButtonClick={handleButtonClick}
        setShowDropdownKms={setShowDropdownKms}
      />
    </>
  );
};
