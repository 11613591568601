import React from "react";
import { Spinner } from "react-bootstrap";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";

export const ViewStepTwoComponent = ({
  handleVerification,
  phoneNumber,
  inputRefs,
  textError,
  countryCode,
  verificationCodes,
  isLoading,
  isDisabledB,
  handleChange,
}) => {
  return (
    <div className="card-contact-data">
      <div className="card-contact-header">
        <p className="contact-header-title">Verificación telefónica.</p>
      </div>
      <div
        style={{
          padding: "12px",
          height: "364px",
        }}
      >
        <form
          onSubmit={handleVerification}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: " space-between",
            height: "100%",
          }}
        >
          <div style={{ marginTop: "10px" }}>
            <p className="phone-number-two">
              Ingresá el código que te enviamos por SMS al{" "}
              <span className="phone-number-title">
                +54{countryCode}
                {phoneNumber}
              </span>
            </p>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {inputRefs.map((inputRef, index) => (
                <input
                  key={index}
                  ref={inputRef}
                  type="text"
                  value={verificationCodes[index]}
                  className="code-input"
                  onChange={(e) => handleChange(e, index)}
                  maxLength="1"
                />
              ))}
            </div>
            <p
              style={{
                color: "red",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {textError}
            </p>
          </div>
          {isLoading ? (
            <Spinner
              animation="border"
              style={{
                margin: "auto",
                color: " #7340d8",
                marginTop: "40px",
              }}
            />
          ) : (
            ""
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BasePurpleButtonComponent
              buttonDisabled={isDisabledB}
              buttonText={"Continuar"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
