import React, { useState } from "react";
import Whatsapp from "../modules/CarCatalog/images/whatsapp-button.svg";
import WhatsappDiscard from "../modules/Dislikes/images/icon-whatsapp-discard.svg";
import IconTrash from "../images/icon-trash.svg";
import { colors } from "../styles/colors";
import { PriceTypeMoneyComponent } from "./PriceTypeMoneyComponent";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../utils/apiService";
import AlertDeleteModalComponent from "../modules/Wishlist/components/AlertDeleteModalComponent";
import { useFavorites } from "../context/FavoritesContext";

export const CarsCardsComponentLikeDiscard = ({
  car,
  isEditing,
  isDiscard,
  mark,
  onDelete
}) => {
  const { addFavorite } = useFavorites()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let location = useLocation();

  const borderColorCardCars = {
    border: `2px solid ${isDiscard ? colors.gray.darkGrey : colors.purple.lightPurple
      }`,
  };

  const colorContainerValueMoney = {
    border: `2px solid ${isDiscard ? colors.gray.bone : colors.purple.purple}`,
  };
  const colorValueMoney = {
    color: isDiscard ? colors.gray.strokeGray : colors.purple.purple,
  };
  const colorTypeMoney = {
    color: isDiscard ? colors.gray.darkGrey : colors.purple.lightPurple,
  };
  const colorContainerPriceCar = {
    backgroundColor: isDiscard ? colors.gray.darkGrey : colors.purple.purple,
    border: `2px solid ${isDiscard ? colors.gray.strokeGray : colors.purple.strokePurple
      }`,
  };

  const navigateTechnicalData = () => {
    navigate(`/car/${car?.car_id}`, { state: { from: location.pathname } });
  };

  const handleDeleteConfirmation = (e) => {
    e.stopPropagation();
    setShowConfirmationModal(true);
  };

  const handleModalClose = () => {
    setShowConfirmationModal(false);
  };

  const confirmDelete = async (e) => {
    setLoading(true)
    try {
      const result = await post('/cars/unmark_car', { car_id: car.car_id, unmark_as: mark });
      console.log('Solicitud POST exitosa:', result);

      onDelete();
      setLoading(false)
      if (mark === "like") {
        addFavorite()
      }
    } catch (error) {
      console.error('Error al realizar la solicitud POST:', error.message);
    } finally {
      // Cierra el modal después de la ejecución
      handleModalClose();
    }
  };

  return (
    <>
      <div
        className="card-cars me-md-3 mt-4"
        onClick={navigateTechnicalData}
        style={borderColorCardCars}
      >
        <div className="d-flex align-items-center justify-content-end margins">
          {isEditing ? (
            <img src={IconTrash} alt="icon-trash" className="icon-trash"
              onClick={(e) => handleDeleteConfirmation(e)}
            />
          ) : null}
        </div>
        <div
          className={
            isDiscard
              ? "background-card-cars-discard"
              : "background-card-cars-like"
          }
        >
          <p className="text-brand-model">{car?.brand} {car?.model}</p>
          <p className="text-version mb-3">{car?.version}</p>
          <div className="d-flex">
            <p className="text-year-km">{car?.year}</p>
            <p className="ms-1 me-1 text-year-km ">|</p>
            <p className="text-year-km mb-3  mb-md-0">{car?.kms} km</p>
          </div>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ width: "100%" }}
          >
            <img
              src={car?.photos[0]}
              alt="car-catalog"
              className="car-catalog mt-3 mb-3"
            />
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <PriceTypeMoneyComponent
            car={car}
            colorContainerValueMoney={colorContainerValueMoney}
            colorValueMoney={colorValueMoney}
            colorContainerPriceCar={colorContainerPriceCar}
            colorTypeMoney={colorTypeMoney}
          />
          <img
            src={isDiscard ? WhatsappDiscard : Whatsapp}
            alt="whatsapp-button"
            className="buttons-card-cars ms-2"
          />
        </div>
      </div>
      <AlertDeleteModalComponent
        isOpen={showConfirmationModal}
        onClose={handleModalClose}
        onConfirm={confirmDelete}
        loading={loading}
      />
    </>
  );
};
