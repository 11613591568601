import React, { useState } from "react";
import { Form, Table } from "react-bootstrap";
import CarExample from "../images/car-example.png";
import { dealsArray } from "../services/deals";
import IconArrowDown from "../images/icon-arrow-down.svg";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

export const TableDealsComponent = ({
  selectedItems,
  setSelectedItems,
  selectedItemTable,
  setSelectedItemTable,
  dealsMap,
}) => {
  const [typeMoney, setTypeMoney] = useState(true);
  let isPageWide = useMediaQuery("(min-width: 990px)");

  const navigate = useNavigate();

  const changeValueTypeMoney = () => {
    setTypeMoney(!typeMoney);
  };

  const toggleSelectItem = (index, data) => {
    const updatedSelectedItems = [...selectedItems];
    let updatedSelectedItemsTable = [...selectedItemTable]; // Cambiar const a let

    const isSelected = updatedSelectedItems.includes(index);
    const isTableItemSelected = updatedSelectedItemsTable.includes(data);

    if (isSelected || isTableItemSelected) {
      // Desmarcar y eliminar el elemento si está seleccionado
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(index), 1);
      updatedSelectedItemsTable = updatedSelectedItemsTable.filter(
        (item) => item !== data
      );
    } else {
      // Marcar el elemento si no está seleccionado
      updatedSelectedItems.push(index);
      updatedSelectedItemsTable.push(data);
    }

    setSelectedItems(updatedSelectedItems);
    setSelectedItemTable(updatedSelectedItemsTable);
  };

  const handleSelectAll = () => {
    if (selectedItems.length === dealsArray.length) {
      // Si todas las casillas están marcadas, desmarcar todas
      setSelectedItems([]);
      setSelectedItemTable([]);
    } else {
      // Si no todas las casillas están marcadas, marcar todas
      const allIndexes = dealsArray.map((_, index) => index);
      const allData = dealsArray.map((data, index) => data);
      setSelectedItems(allIndexes);
      setSelectedItemTable(allData);
    }
  };

  const navigateToDeal = () => {
    navigate("/deal/123");
  };

  return (
    <Table className="mt-3 deals-table-style" hover responsive>
      <thead>
        <tr>
          {isPageWide ? <th className="text-head-table"></th> : null}
          <th className="text-head-table" colSpan={isPageWide ? 1 : 2}>
            Car ID
          </th>
          <th className="text-head-table">Marca</th>
          <th className="text-head-table">Modelo</th>
          <th className="text-head-table">Versión</th>
          <th className="text-head-table">Año</th>
          <th className="text-head-table">Kilometraje</th>
          <th className="text-head-table">Color</th>
          <th className="text-head-table">
            <div className="d-flex align-items-center">
              <p>Precio</p>
              <div className="ms-2 d-flex align-items-center justify-content-between container-switch-type-money">
                {typeMoney ? (
                  <>
                    <div
                      className="container-active-switch-type-money d-flex align-items-center justify-content-center"
                      onClick={changeValueTypeMoney}
                    >
                      <p className="text-active-type-money">ARS$</p>
                    </div>
                    <div
                      onClick={changeValueTypeMoney}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="text-disabled-type-money me-2">U$D</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      onClick={changeValueTypeMoney}
                      style={{ cursor: "pointer" }}
                    >
                      <p className="text-disabled-type-money ms-2">ARS$</p>
                    </div>
                    <div
                      className="container-active-switch-type-money d-flex align-items-center justify-content-center"
                      onClick={changeValueTypeMoney}
                    >
                      <p className="text-active-type-money">U$D</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </th>
          <th className="text-head-table">
            <Form.Check
              type="checkbox"
              checked={selectedItems.length === dealsArray.length}
              onChange={handleSelectAll}
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {dealsMap.map((data, index) => (
          <tr key={index} style={{ cursor: "pointer" }}>
            <td onClick={navigateToDeal}>
              <img src={CarExample} alt="car-auta" className="car-table" />
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.car_id}</p>
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.brand}</p>
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.model}</p>
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.version}</p>
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.year}</p>
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.kms}</p>
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.color}</p>
            </td>
            <td onClick={navigateToDeal}>
              <p className="text-body-table">{data.price}</p>
            </td>
            <td>
              <Form.Check
                type="checkbox"
                checked={selectedItems.includes(index)}
                onChange={() => toggleSelectItem(index, data)}
              />
            </td>
          </tr>
        ))}
        <tr style={{ cursor: "pointer" }}>
          <td colSpan="11">
            <div className="d-flex align-items-center justify-content-start justify-content-md-center">
              <p className="text-see-more-cars">Mostrar más autos</p>
              <img
                src={IconArrowDown}
                alt="icon-arrow-down"
                className="icon-arrow-down"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
