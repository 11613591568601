import React from "react";
import BasePurpleButtonComponent from "../../../../components/BasePurpleButtonComponent";
import { Spinner } from "react-bootstrap";

export const ModalNoAuthenticated = ({
  handleSubmit,
  name,
  email,
  handleEmailChange,
  isEmailValid,
  countryCode,
  phoneNumber,
  textError,
  loginAlert,
  showloginView,
  isFormValid,
  isLoading,
  setName,
  setCountryCode,
  setPhoneNumber,
  setShowLoginView,
  setTextError,
}) => {
  return (
    <div className="card-contact-data">
      <div className="card-contact-header">
        <p className="contact-header-title">¡Solo nos faltan tus datos!</p>
      </div>
      <div style={{ padding: "12px", height: "364px" }}>
        <form
          onSubmit={handleSubmit}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            {showloginView ? null : (
              <>
                <div style={{ marginBottom: "12px" }}>
                  <label className="input-labels">Nombre completo</label>
                  <input
                    type="text"
                    className="base-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Ingresar tu nombre"
                  />
                </div>
                <div style={{ marginBottom: "12px" }}>
                  <label className="input-labels">Email</label>
                  <input
                    type="text"
                    className="base-input"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Ingresar tu email"
                  />
                  {!isEmailValid ? (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Formato de email invalido
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
            <div style={{ marginBottom: "12px" }}>
              <label className="input-labels">Teléfono celular</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="phone-number">+549</p>
                <input
                  type="text"
                  className="base-input"
                  placeholder="Ej: 11"
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  maxLength={3}
                  style={{ width: "65px", marginRight: "10px" }}
                />
                <input
                  type="text"
                  className="base-input"
                  placeholder="Sin 15"
                  value={phoneNumber}
                  maxLength={8}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <p
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                {textError}
              </p>
            </div>
          </div>
          {loginAlert && !showloginView ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <BasePurpleButtonComponent
                buttonText={"Inicia sesion"}
                onClick={() => {
                  setShowLoginView(true);
                  setTextError("");
                }}
              />
            </div>
          ) : (
            <>
              <div>
                <p className="security-code-text mt-5">
                  Recibirás un código de seguridad
                </p>
                {isLoading ? (
                  <Spinner
                    animation="border"
                    style={{
                      margin: "10px",
                      color: " #7340d8",
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <BasePurpleButtonComponent
                  buttonDisabled={!isFormValid || isLoading}
                  buttonText={"Continuar"}
                  type="submit"
                />
              </div>
            </>
          )}
          <div id="recaptcha-container"></div>
        </form>
      </div>
    </div>
  );
};
